import React from 'react';
import PropTypes from 'prop-types';
import style from './Flag.module.css';
import blank from './blank.gif';
// import flags from './flags.png';

/**
 * @name Flag
 * @render react
 * @description World flag image
 * @example
 * <Flag code={'ru'} />
 */
const Flag = ({code}) => (
    <img src={blank} className={`${style.Flag} ${code ? style['Flag-' + code.toLowerCase()] : ''}`} alt={'Flag'}/>
);

Flag.propTypes = {
    /**
     * @property {string} The country code is in the format ISO 3166-1 alpha-2
     */
    code: PropTypes.string,
};

Flag.defaultProps = {
    code: 'ru',
};

export default Flag;
