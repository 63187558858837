import styled from 'styled-components';

const TableData = styled.tbody`
	text-transform: uppercase;
	a {
			text-decoration: none;
			color: #0069c4;
			&:hover {
					color: #c5003c;
			}
	}
	& td {
			border-bottom: 1px solid #dadada;
			border-right: 1px solid #f4f4f4;
			padding: 8px 8px;
			vertical-align: top;
			transition: 0.2s ease;
			&.focused {
					background-color: #f7f7f7;
					border-right-color: #ebebeb;
			}
	}	
	
	& td hr {
	    height: 1px;
    	margin: 5px 0;
    	color: #e8e8e8;
    	background-color: #e8e8e8;
    	border-color: #e8e8e8;
    	border-width: 0;
	}
`;
TableData.displayName = 'Styled.TableData';

export default TableData;
