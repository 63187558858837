import React, {Component} from 'react';
import PropTypes from 'prop-types';
import drawDiagram from './claimer-licensors';
import styles from './index.css';

class ClaimerLicensors extends Component {

    constructor() {
        super();
        this.container = React.createRef();
    }

    componentDidMount() {
        drawDiagram(this.container.current, this.props.data, this.props.fields, this.props.messages, this.props.urls)
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (<div ref={this.container} className={styles.ClaimerLicensorsDiagram}/>);
    }
}

ClaimerLicensors.propTypes = {
    data: PropTypes.object,
    fields: PropTypes.object,
    messages: PropTypes.object,
    urls: PropTypes.object,
};


export default ClaimerLicensors;


