import * as d3 from 'd3';

function getParams(fields) {
    var _fields = {
        tracks: {
            single: [],
            stacked: [],
        },
        compositions: {
            single: [],
            stacked: [],
        }
    };
    var url_string = window.location.href;
    var url = new URL(url_string);

    function update(arr) {
        return arr.map(function (item) {
            var val = url.searchParams.get(item.value);
            if (val) {
                return Object.assign(item, {show: !(val === 'false')})
            } else {
                return Object.assign(item, {show: true})
            }
        });
    }

    _fields.tracks.single = update(fields.tracks.single);
    _fields.tracks.stacked = update(fields.tracks.stacked);
    _fields.compositions.single = update(fields.compositions.single);
    _fields.compositions.stacked = update(fields.compositions.stacked);

    var mode = url.searchParams.get('mode');
    var showAll = url.searchParams.get('showAll');
    var selectedFields = url.searchParams.get('selected');
    var selectedFieldsArray = selectedFields && selectedFields !== '' ? selectedFields.split(',') : [];
    var selected = {};
    selectedFieldsArray.forEach(function (key) {
        selected[key] = true;
    });

    var ret = {
        fields: _fields,
        mode: mode === 'tracks' ? 'tracks' : 'compositions',
        selected,
        showAll: selectedFieldsArray.length > 0 ? showAll === 'true' : true
    };
    return ret;
}


function getState(fields, mode, selected, showAll) {
    var state = {};
    var _fields = [].concat(fields.tracks.single, fields.tracks.stacked, fields.compositions.single, fields.compositions.stacked);

    _fields.forEach(function (item) {
        state[item.value] = item.show;
    });

    state.mode = mode;
    state.showAll = showAll ? true : false;
    state.selected = Object.keys(selected).filter(function (key) {
        return selected[key] === true;
    }).join(',');
    return state
}

function appendToURL(origin, params) {
    var url = new URL(new URL(window.location.href).origin + origin);
    Object.keys(params).forEach(function (key) {
        url.searchParams.append(key, params[key]);
    });
    return url.toString();
}

var state = {};

function setParams(fields, mode, selected, showAll) {
    state = getState(fields, mode, selected, showAll);
    var url = appendToURL(window.location.pathname, state);
    window.history.replaceState('Diagram', 'Heaven 11', url)
}

export default function drawClaimerLicensorsDiagram(selector, licensorsData, _fields, messages, urls) {

    var data = licensorsData;
    var colors = [];
    var fields = _fields;

    var params = getParams(fields);
    fields = params.fields;

    var config = {
        bars: 4,
        barHeight: 8,
        textHeight: 8,
        accountHeight: 65,
        width: 760,
        legendWidth: 220,
        legendHeight: 220,
        showAll: params.showAll !== undefined ? params.showAll : true,
        mode: params.mode || 'compositions',
    };
    var selectedFields = {};
    var maxValue = 0;
    var filterMin = 0;
    var filterStep = 1;
    var filterMax = 0;
    var lastRenderData = {};
    var selected = params.selected || {};
    var isDisableFilters = getFiltered().length === 0;

    // setParams(fields, config.mode, selected, config.showAll);

    function getValue(datum) {
        return datum.value
    };

    function getMax(a) {
        return Math.max(d3.max(a.single, getValue) || 0, d3.sum(a.stacked, getValue))
    };

    function parseData(data, fields) {
        var perf = Date.now();
        var ret = [];

        fields.single = fields.single.map(function (field) {
            return Object.assign(field, {className: field.value.replace(/compositions_|tracks_|_count/g, '')});
        });
        fields.stacked = fields.stacked.map(function (field) {
            return Object.assign(field, {className: field.value.replace(/compositions_|tracks_|_count/g, '')});
        });
        selectedFields = Object.assign([], fields);
        data.licensors
            .forEach(function (licensor) {
                var licensorValue = 0;
                var offset = 0;

                fields.single = fields.single.map(function (k) {
                    var sum = k.sum || 0;
                    licensorValue += k.show ? licensor[k.value] : 0;
                    return Object.assign(k, {sum: sum + licensor[k.value]})
                });

                fields.stacked = fields.stacked.map(function (k) {
                    var sum = k.sum || 0;
                    licensorValue += k.show ? licensor[k.value] : 0;
                    return Object.assign(k, {sum: sum + licensor[k.value]})
                });

                if (licensorValue === 0) {
                    return;
                }
                if (!config.showAll) {
                    if (!selected[licensor.id]) {
                        return;
                    }
                }
                ret.push({
                    id: licensor.id,
                    title: licensor.title,
                    single: fields.single.map(function (k) {
                        return {key: k, value: k.show ? licensor[k.value] : 0, offset: 0, licensor: licensor}
                    }),
                    stacked: fields.stacked.map(function (k) {
                        var off = offset += k.show ? licensor[k.value] : 0;
                        return {key: k, value: k.show ? licensor[k.value] : 0, offset: off, licensor: licensor}
                    }),
                });
            });

        ret = ret.map(function (item) {
            return Object.assign(item, {
                single: item.single.filter(function (s, i) {
                    return fields.single[i].sum > 0 && fields.single[i].show
                })
            });
        });

        ret = ret.sort(function (a, b) {
            return getMax(b) - getMax(a);
        });

        if (!ret[0]) {
            return [];
        }
        maxValue = getMax(ret[0]);

        if (filterMax === 0) {
            filterMax = maxValue;
        }

        // if (maxValue > 5000) {
        //     filterStep = 100;
        // } else if (maxValue > 1000) {
        //     filterStep = 25;
        // } else {
        //     filterStep = 1;
        // }
        //

        // ret = ret.filter(function (item) {
        //     var val = getMax(item);
        //     return (val >= filterMin) && (val <= filterMax);
        // });

        // console.log('Time:', Date.now() - perf);
        return ret;
    }

    function handleTracksClick() {
        config.bars = 3;
        config.mode = 'tracks';
        draw(parseData(licensorsData, fields.tracks));
        drawLegend(fields.tracks);
        drawControls();
        setParams(fields, config.mode, selected, config.showAll);
    }

    function getFiltered() {
        return Object.keys(selected).filter(function (key) {
            return selected[key] === true;
        })
    }

    function handleSelect(id) {
        if (selected.hasOwnProperty(id)) {
            selected[id] = !selected[id];
        } else {
            selected[id] = true;
        }
        isDisableFilters = getFiltered().length === 0;
        drawControls();
        setParams(fields, config.mode, selected, config.showAll);
    }

    function handleFilter(val) {
        config.showAll = val;
        if (config.mode === 'compositions') {
            draw(parseData(licensorsData, fields.compositions));
            drawLegend(fields.compositions);
            drawControls();
        } else {
            draw(parseData(licensorsData, fields.tracks));
            drawLegend(fields.tracks);
            drawControls();
        }
        setParams(fields, config.mode, selected, config.showAll);
    }

    function handleCompositionsClick() {
        config.bars = 4;
        config.mode = 'compositions';
        draw(parseData(licensorsData, fields.compositions));
        drawLegend(fields.compositions);
        drawControls();
        setParams(fields, config.mode, selected, config.showAll);

    }

    function getBarHref(key, id) {
        switch (key) {
            case 'licensor_date_view':
            case 'compositions_count':
                return urls[key].replace("[ACCOUNT_ID]", id);
            default:
                if (~urls[key].indexOf('?')) {
                    return urls[key] + '&licensor_id=' + id;
                }
                return urls[key] + '?licensor_id=' + id;
        }
    }

    // function rangeSelect(data) {
    //     filterMin = data.from;
    //     filterMax = data.to;
    //
    //     if (config.mode === 'compositions') {
    //         handleCompositionsClick();
    //     } else {
    //         handleTracksClick();
    //     }
    // }

    function handleLegendClick(d) {
        if (config.mode === 'compositions') {
            d.show = !d.show;
            handleCompositionsClick();
        } else {
            d.show = !d.show;
            handleTracksClick();
        }


    }

    function drawControls() {

        d3.select('.claimer-licensors__controls').remove();
        d3.select('.claimer-licensors__filters').remove();

        var controls = d3.select('.claimer-licensors')
            .append('foreignObject')
            .attr('x', config.width - config.legendWidth + 48)
            .attr('y', 5)
            .attr('width', 200)
            .attr('height', 80)
            .attr('class', 'claimer-licensors__controls');

        var div = controls.append('xhtml:div')
        // .style('display', 'flex')
        // .style('flex-direction', 'column');

        var modeHeader = div
            .append('div')
            // .style('text-transform', 'uppercase')
            .attr('class', 'claimer-licensors__controls__header')
            .html(messages['licensors.diagram.legend.mode.header'])

        var compositionsSection = div
            .append('label')
            .attr('class', 'claimer-licensors__controls-field')
        // .style('margin', '0')
        // .style("cursor", 'pointer');

        var tracksSection = div
            .append('label')
            .attr('class', 'claimer-licensors__controls-field')
        // .style('margin', '0')
        // .style("cursor", 'pointer');

        // var focusHeader = div
        //     .append('span')
        //     .style('margin-top', '20px')
        //     .style('text-transform', 'uppercase')
        //     .html(messages['licensors.diagram.legend.focus.header']);

        compositionsSection.append('input')
            .attr('type', 'radio')
            .attr('checked', config.mode === 'compositions' ? 'true' : null)
            // .style('margin', '7px 7px 0 1px')
            // .style('vertical-align', 'baseline')
            .on('change', function () {
                filterMax = 0;
                handleCompositionsClick()
            });
        compositionsSection.append('span').html(messages['licensors.diagram.legend.compositions']);

        tracksSection.append('input')
            .attr('type', 'radio')
            .attr('checked', config.mode !== 'compositions' ? 'true' : null)
            // .style('margin', '7px 7px 0 1px')
            // .style('vertical-align', 'baseline')
            .on('change', function () {
                filterMax = 0;
                handleTracksClick()
            });
        tracksSection.append('span').html(messages['licensors.diagram.legend.tracks'])


        var filtres = d3.select('.claimer-licensors')
            .append('foreignObject')
            .attr('x', config.width - config.legendWidth + 48)
            .attr('y', config.legendHeight + 75)
            .attr('width', 200)
            .attr('height', 80)
            .attr('class', 'claimer-licensors__filters');

        var filteresDiv = filtres.append('xhtml:div')

        var filteresHeader = filteresDiv
            .append('div')
            .attr('class', 'claimer-licensors__filters__header')
            // .style('text-transform', 'uppercase')
            .html(messages['licensors.diagram.legend.filter.header']);

        var selectedLicensorsSection = filteresDiv
            .append('label')
            .attr('class', 'claimer-licensors__controls-field')

        selectedLicensorsSection.append('input')
            .attr('type', 'checkbox')
            .attr('checked', !config.showAll ? 'true' : null)
            .on('change', function () {
                handleFilter(!this.checked);
            });
        selectedLicensorsSection.append('span').html(messages['licensors.diagram.legend.filter.selected'].toUpperCase());
        if (isDisableFilters) {
            // allLicensorsSection.selectAll('input').attr('disabled', 'true');
            selectedLicensorsSection.selectAll('input').attr('disabled', 'true');
        }

    }

    function drawLegend(fields) {
        var allFields = fields.single.concat(fields.stacked);
        var data = allFields.filter(function (field) {
            return field.sum > 0
        });

        var groups = [];
        data.forEach(function (item) {
            if (!groups[item.group]) {
                groups[item.group] = [item];
            } else {
                groups[item.group].push(item);
            }
        });

        groups = groups.filter(function (item) {
            return item !== undefined;
        });

        var offset = 20;

        var legend = d3.select(selector).select('.claimer-licensors')
            .append('g')
            .attr('class', 'claimer-licensors__legend')
            .attr("transform", "translate(" + (config.width - config.legendWidth + 48) + ",75)");

        legend.append('text')
            .attr("x", 1)
            .attr("dy", "10px")
            .style("text-anchor", "start")
            .text(messages['licensors.diagram.legend.focus.header'].toUpperCase())

        var group = legend.selectAll('.claimer-licensors__legend')
            .data(groups)
            .enter()
            .append('g')
            .attr('class', 'claimer-licensors__legend__group')
            .attr("transform", function (d, i) {
                var y = offset;
                offset += groups[i].length * 20 + 10;
                return 'translate(0, ' + y + ')'
            });

        group.append('line')
            .attr('class', 'claimer-licensors__legend__group__line')
            .attr('x1', 2)
            .attr('x2', function (d, i) {
                return i === 0 ? 0 : 30
            })
            .attr('y1', -10)
            .attr('y2', -10)
            .attr('stroke', '#e6e6e6');

        var item = group.selectAll('.claimer-licensors__legend__group')
            .data(function (d) {
                return d
            })
            .enter()
            .append('g')
            .attr('class', function (d) {
                return 'claimer-licensors__legend__item ' + (d.show ? 'claimer-licensors__item_visible' : 'claimer-licensors__item_hided');
            })
            .on('click', handleLegendClick)


        item.append('rect')
            .attr("x", 2.5)
            .attr("y", function (d, i) {
                return 20 * i
            })
            .attr("width", 10)
            .attr("height", 10)
            .attr('class', function (d) {
                return 'claimer-licensors__legend__rect claimer-licensors__bar__' + d.className;
            })
            // .style('fill', function (d) {
            //     return d.show ? d.color : 'transparent';
            // })
            .style('fill-opacity', function (d) {
                return d.show ? 1 : 0;
            })
            .style("cursor", 'pointer')
            .style("stroke", function (d) {
                return d.show ? 'none' : 'black';
            });

        item.append('text')
            .attr("x", 20)
            .attr("y", function (d, i) {
                return (20 * i) - 1;
            })
            .attr("dy", "10px")
            .attr("class", "claimer-licensors__legend__text")
            .style("text-anchor", "start")
            .style("cursor", 'pointer')
            // .style("font-size", 10)
            .text(function (d) {
                return messages['licensors.diagram.legend.' + d.value]
            })

        config.legendHeight = offset;
    }

    function singleBar(select, xScale) {
        var rect = select
            .selectAll('.single')
            .data(function (d) {
                return d.single
            })
            .enter()
            .append('g')
            .attr('transform', 'translate(22,0)')
            .append('a')

            .attr('xlink:href', function (d, i) {
                return getBarHref(d.key.value, d.licensor.id)
            })
            .append("rect")
            .attr('y', function (d, i) {
                return i * (config.barHeight + 1) + config.textHeight
            })
            .attr('class', function (d) {
                return 'single bar claimer-licensors__bar claimer-licensors__bar__' + d.key.className
            })
            .on('mouseover', function (d) {
                d3.selectAll('.claimer-licensors__bar:not(.claimer-licensors__bar__' + d.key.className + ')')
                    .transition()
                    .duration(300)
                    .style('opacity', 0.3)
            })
            .on('mouseleave', function (d) {
                d3.selectAll('.claimer-licensors__bar')
                    .transition()
                    .duration(300)
                    .style('opacity', 1)
            });

        var needAnimate = false;
        rect.attr('width', function (d, i) {
            if (lastRenderData[d.licensor.id]) {
                var item = lastRenderData[d.licensor.id].d.single[i];
                if (item) {
                    needAnimate = true;
                    return xScale(item.value);
                }
            }
            return xScale(d.value);
        });

        if (needAnimate) {
            needAnimate = false;
            rect.transition()
                .duration(400)
                // .delay(300)
                .attr('width', function (d) {
                    return xScale(d.value)
                })

        }
        rect.attr('x', 0)
            .attr('height', config.barHeight)
            .attr('fill', function (d, i) {
                return d.key.color;
            });
        rect.append("title")
            .text(function (d) {
                return messages['licensors.diagram.legend.' + d.key.value] + ': ' + d.value;
            });

    }

    function highlightBar(selecton) {
        return selecton
    }

    function stackedBar(select, xScale, barsCounter) {
        var group = select
            .append('g')
            .attr('class', 'stacked')
            .attr('transform', function () {
                return "translate(22," + ((barsCounter - 1) * (config.barHeight + 1) + config.textHeight) + ')';
            })
            .selectAll('.stacked');

        var rect = group
            .data(function (d) {
                return d.stacked
            })
            .enter()
            .append('a')
            .attr('xlink:href', function (d, i) {
                return getBarHref(d.key.value, d.licensor.id)

            })
            .append("rect")
            .style("cursor", 'pointer')
            .attr('class', function (d, i) {
                return 'claimer-licensors__bar claimer-licensors__bar__' + d.key.className;
            })
            .on('mouseover', function (d) {
                d3.selectAll('.claimer-licensors__bar:not(.claimer-licensors__bar__' + d.key.className + ')')
                    .transition()
                    .duration(300)
                    .style('opacity', 0.3)
            })
            .on('mouseleave', function (d) {
                d3.selectAll('.claimer-licensors__bar')
                    .transition()
                    .duration(300)
                    .style('opacity', 1)
            });

        var needAnimate = false;
        rect.attr('width', function (d, i) {
            if (lastRenderData[d.licensor.id]) {
                var item = lastRenderData[d.licensor.id].d.stacked[i];
                if (item) {
                    needAnimate = true;
                    return xScale(item.value);
                }
            }
            return xScale(d.value);
        })
            .attr('x', function (d, i) {
                if (lastRenderData[d.licensor.id]) {
                    var item = lastRenderData[d.licensor.id].d.stacked[i];
                    if (item) {
                        return xScale(item.offset - item.value);
                    }
                }
                return xScale(d.offset - d.value);
            });

        if (needAnimate) {
            rect.transition()
                .duration(400)
                // .delay(300)
                .attr('width', function (d) {
                    return xScale(d.value)
                })
                .attr('x', function (d) {
                    return xScale(d.offset - d.value)
                })
        }
        rect.attr('height', config.barHeight)
        rect.append("title")
            .text(function (d, i) {
                return messages['licensors.diagram.legend.' + d.key.value] + ': ' + d.value;
            });
        return group;
    }

    function title(select) {
        return select
            .append('a')
            .attr('xlink:href', function (d) {
                return getBarHref('licensor_date_view', d.id)
            })
            .on('click', function (d) {
                var el = d3.select(this);
                var newState = Object.assign({}, state);
                delete newState.selected;
                delete newState.showAll;
                el.attr('xlink:href', appendToURL(getBarHref('licensor_date_view', d.id), newState));
                //d3.event.preventDefault()
            })
            .append("text")
            .attr("dy", ".35em")
            .attr("x", "22")
            .attr("height", config.textHeight)
            .attr('class', 'claimer-licensors__title')
            .text(function (d) {
                return d.title;
            });

    }

    function drawNoData() {
        d3.select(selector)
            .selectAll('.claimer-licensors')
            .remove();

        d3.select(selector)
            .append('svg')
            .attr('class', 'claimer-licensors')
            .attr('width', config.width)
            .attr('height', 300)
            .append('text')
            .attr("dy", ".35em")
            .attr("font-size", "32px")
            .attr("text-anchor", "middle")
            .attr("x", (config.width - config.legendWidth) / 2)
            .attr("y", 150)
            .attr("height", 100)
            .attr('class', 'claimer-licensors__no-data')
            .attr('fill', '#ccc')
            .text('NOTHING TO SHOW')

    }

    function draw(source) {
        if (source.length === 0) {
            drawNoData();
            return
        }
        var max = getMax(source[0]);
        var xScale = d3.scaleLinear()
            .range([0, config.width - config.legendWidth])
            .domain([0, max]).interpolate(d3.interpolateRound);

        d3.select(selector)
            .selectAll('.claimer-licensors')
            .remove();

        var svg = d3.select(selector)
            .append('svg')
            .attr('class', 'claimer-licensors')
            .attr('width', config.width)
            .attr('height', 500);
        svg.append('g').attr('class', 'x axis');

        var account = svg.selectAll('.claimer-licensors__account')
            .data(source)
            .enter()
            .append('g')
            .attr('class', 'claimer-licensors__account');


        var barsCounter = source[0].single.length + 1;
        account.call(singleBar, xScale);
        account.call(stackedBar, xScale, barsCounter);
        account.call(title);
        account
            .attr('transform', function (d, i) {
                var y = ((config.barHeight * barsCounter + config.textHeight + 22) * i) + 40;
                if (lastRenderData[d.id]) {
                    var transform = "translate(5," + lastRenderData[d.id].y + ")";
                    lastRenderData[d.id] = {y: y, d: d};
                    return transform;
                }
                lastRenderData[d.id] = {y: y, d: d};
                return "translate(5," + y + ")";
            })
            .transition()
            .delay(400)
            .ease(d3.easeQuadOut)
            .duration(400)
            .attr("transform", function (d, i) {
                return "translate(5," + (((config.barHeight * barsCounter + config.textHeight + 22) * i) + 40) + ")";
            });


        account.append('svg:foreignObject')
            .attr('width', 20)
            .attr('height', 20)
            .attr('y', 8)
            .attr('x', 2)
            .append('xhtml:div').append('xhtml:input')
            .attr('type', 'checkbox')
            .attr('class', 'claimer-licensors__account__checkbox')
            .attr('checked', function (d) {
                if (selected.hasOwnProperty(d.id)) {
                    return selected[d.id] ? 'true' : null;
                }
                return null;

            })
            .on('change', function (d) {
                handleSelect(d.id);
            });


        var svgHeight = Math.max(((config.barHeight * barsCounter + config.textHeight + 22) * source.length) + 40, 500);
        var xAxis = d3.axisTop(xScale).ticks(max > 6 ? 6 : 1).tickSize(svgHeight - 17).tickFormat(d3.format("d"));

        svg.attr('height', svgHeight);
        svg.select('.x')
            .attr('transform', 'translate(27,' + svgHeight + ')')
            .call(xAxis);
        svg.selectAll('.tick line').attr('stroke', '#ccc');
        svg.select('.domain').attr('stroke', 'transparent');
        svg.append('rect')
            .attr('height', svgHeight)
            .attr('width', config.legendWidth)
            .attr('fill', '#FFF')
            .attr('x', config.width - config.legendWidth + 36)
    }

    function update() {
        config.showAll = params.showAll !== undefined ? params.showAll : true;
        config.mode = params.mode || 'compositions';
        selected = params.selected || {};
        // setParams(fields, config.mode, selected, config.showAll);

        var fieldsToDraw = config.mode === 'compositions' ? fields.compositions : fields.tracks;
        draw(parseData(licensorsData, fieldsToDraw));
        drawLegend(fieldsToDraw);
        drawControls();
    }

    update();

    window.addEventListener('popstate', function (event) {
        params = getParams(fields);

        fields = params.fields;
        update();
    }, false);
}

