import React, {Component} from 'react';
// import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import drawDiagram from './claimer-licensors-period';
import styles from './index.css';


class ClaimerLicensorsPeriodDiagram extends Component {

    constructor() {
        super();
        this.container = React.createRef();

    }

    componentDidMount() {
        drawDiagram(this.container.current, this.props.data, this.props.fields, this.props.messages, this.props.urls, this.props.byMonths);
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (<div ref={this.container} className={styles.ClaimerLicensorsPeriodDiagram}/>);

    }
}

ClaimerLicensorsPeriodDiagram.propTypes = {
    data: PropTypes.object,
    fields: PropTypes.object,
    messages: PropTypes.object,
    urls: PropTypes.object,
    byMonths: PropTypes.bool,
};

export default ClaimerLicensorsPeriodDiagram;
