import 'isomorphic-fetch';
import md5 from 'md5';

export const BACKEND_URL = (window.API_URL ? window.API_URL : '') + '/backend';

export function getQueryString(query) {
    return Object.keys({...query})
        .map((key) => `query[${key}]=${query[key]}`)
        .join('&');
}

export function getQueryStringParams(query) {
    return Object.keys({...query})
        .map((key) => `${key}=${query[key]}`)
        .join('&');
}

export class Request {
    static checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        const error = new Error(`HTTP Error ${response.statusText}`);
        error.status = response.statusText;
        error.response = response;
        console.log(error); // eslint-disable-line no-console
        throw error;
    }

    static parseJSON(response) {
        return response.json();
    }

    get(query, options = {}) {
        return fetch(options.devMode ? query : `${BACKEND_URL}/${query}`, {
            cache: 'force-cache',
            credentials: "same-origin"
        }).then(Request.checkStatus).then(Request.parseJSON)
    }

    post(query, data, options = {}) {
		let body = data;
		let headers = {};
		if (!(data instanceof FormData)) {
			body = new URLSearchParams();
			for (let property in data) {
				body.append(property, data[property]);
			}
			headers = {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			};
		}

        return fetch(options.devMode ? query : `${BACKEND_URL}/${query}`, {
            cache: 'force-cache',
            credentials: 'same-origin',
            method: 'POST',
			headers,
            body,
        }).then(Request.checkStatus).then(Request.parseJSON)
    }
}

export class CachedRequest extends Request {
    constructor({ttl = 20000} = {}) {
        super();
        this.cache = {};
        this.options = {ttl};
    }

    get(query, options = {}) {
        const queryChecksum = md5(query);
        const cachedResponce = this.cache[queryChecksum];
        if (cachedResponce) {
            if (Date.now() - cachedResponce.time < options.ttl || this.options.ttl) {
                return cachedResponce.promise;
            }
        }
        this.cache[queryChecksum] = {promise: super.get(query, options), time: Date.now()};
        return this.cache[queryChecksum].promise
    }
}

const Api = new CachedRequest();

export default Api;
