import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 95vh;
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;