import React, {Component} from 'react';
import {Observable, Subject} from 'rxjs';
import {mapActionCreators, rxConnect} from "rx-connect";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import GlobalSearchForm from './GlobalSearchForm';
import GlobalSearchResults from './GlobalSearchResults';
import styles from './index.css';

import {Search} from '../../services';

class GlobalSearch extends Component {

    onCategoryClick = (category) => {
        this.doSearch({category});
    };

    clearQuery = () => {
        this.setState({showResults: false})
    };

    onSearch = (query) => {
        this.category = '';
        if (query === '') {
            this.clearQuery();
        } else {
            this.doSearch({query});
        }
    };

    constructor() {
        super();
        this.state = {
            showResults: false,
            query: '',
        };
    }

    doSearch({query = this.query, category = this.category}) {
        if (query.length > 2) {
            this.props.search({query, category});
            this.category = category;
            this.query = query;
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.groups && nextProps.groups.length > 0) {
            this.state.showResults = true;
        }
        return true;
    }

    render() {
        const {groups} = this.props;
        const {showResults} = this.state;
        return (
            <ReactCSSTransitionGroup
                transitionName="GlobalSearchForm"
                transitionAppear={true}
                transitionAppearTimeout={300}
                transitionEnter={false}
                transitionLeave={false}>
                <div className={styles.GlobalSearch}>
                    <div className={styles['search-container']}>
                        <GlobalSearchForm onChange={this.onSearch} onClear={this.clearQuery}/>
                        {showResults && <GlobalSearchResults key={this.query} groups={groups} show={showResults}
                                                             onCategoryClick={this.onCategoryClick}/>}
                    </div>
                </div>
            </ReactCSSTransitionGroup>
        );
    }
}

const searchRequest = (searchParams) => Observable.from(Search.globalSearch({
    query: searchParams.query,
    category: searchParams.category
}));

export default rxConnect(() => {
    const actions = {
        search$: new Subject()
    };

    const groups$ = actions.search$

    // .filter(([searchParams]) => searchParams.query !== '' && searchParams.query.length > 2)
        .debounceTime(500)
        .pluck(0)
        .switchMap((searchParams) => searchRequest(searchParams))


    return Observable.merge(
        mapActionCreators(actions),
        groups$.map(groups => ({groups}))
    )
})(GlobalSearch);
