import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';

class GlobalSearchGroupItem extends PureComponent {
    render() {
        const {title, subtitle, link, onMouseOver, onMouseOut} = this.props;
        return (
            <li className={'_selectable'} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                <a href={link}>
                    <div className={styles['search-result-item-title']}>{title}</div>
                    <div className={styles['search-result-item-subtitle']}
                         dangerouslySetInnerHTML={{__html: subtitle}}/>
                </a>
            </li>
        );
    }
}

GlobalSearchGroupItem.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    link: PropTypes.string,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
};
GlobalSearchGroupItem.defaultProps = {
    title: '',
    subtitle: '',
    link: '',
    onMouseOver: () => {
    },
    onMouseOut: () => {
    },
};

export default GlobalSearchGroupItem;
