import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Observable, Subject} from 'rxjs';
import {mapActionCreators, rxConnect} from 'rx-connect';
import DiffSection from './DiffSection';
import DiffMergedSection from './DiffMergedSection';
import styles from './index.css';
import * as API from "../../services";

import {FormattedMessage} from 'react-intl';

class DiffViewer extends Component {
    state = {
        hover: {
            index: -1,
            role: null,
        }
    };

    onRightholderRemove = () => {

    };

    onHighligh = (index, role = null) => {
        this.setState({hover: {index, role}})
    };
    onEditShare = () => {

    };
    onEditField = () => {

    };

    componentDidMount() {
        this.props.getDiff({
            resourceId: this.props.resourceId,
            catalogId: this.props.catalogId,
            catalogResourceId: this.props.catalogResourceId,
        });
    }

    render() {
        const {data} = this.props;
        const actions = {
            onHighligh: this.onHighligh,
            onRightholderRemove: this.onRightholderRemove,
            onEditShare: this.onEditShare,
            onEditField: this.onEditField,
        }
        if (data) {
            return (
                <div className={styles.DiffViewer}>
                    <DiffSection title={'Existing'}
                                 url={data.url.local}
                                 data={data.local}
                                 hover={this.state.hover}
                                 actions={actions}
                    />

                    <DiffMergedSection title={'Merged'}
                                       data={data.merged}
                                       hover={this.state.hover}
                                       actions={actions}
                    />

                    <DiffSection title={'Imported'} url={data.url.remote}
                                 data={data.remote}
                                 hover={this.state.hover}
                                 actions={actions}
                    />
                    <FormattedMessage id="test"/>
                    <FormattedMessage id="diff"/>
                </div>
            )
        }
        return (<div className={styles.DiffViewer}/>)
    }
}

DiffViewer.propTypes = {
    getDiff: PropTypes.func,
    diffData: PropTypes.object,
    context: PropTypes.object,
};

DiffViewer.defaultProps = {};

const getDiffRequest = (params) => Observable.from(API.DiffViewer.getDiffData(params));

export default rxConnect((props$) => {
    const actions = {
        getDiff$: new Subject(),
        removeRightholder: new Subject(),
        editShare: new Subject(),
        editField: new Subject(),
    };

    const diffData$ = actions.getDiff$
        .debounceTime(500)
        .pluck(0)
        .switchMap((params) => getDiffRequest(params));

    return Observable.merge(
        mapActionCreators(actions),
        props$,
        diffData$.map(data => {
            const {merged, local, remote, patches} = data;
            // console.log('log', local, patches);
            const localRightholders = [];
            const remoteRightholders = [];
            const mergedRightholders = [];

            function applyPathRH(rh) {
                const appliedPatches = patches.filter((p) => p.hash === rh.hash);

                const roles = rh.roles.map((role) => {
                    const rolesPatches = patches.filter((p) => p.hash === `${rh.hash}/roles/${role.code}`);
                    return {...role, patch: rolesPatches[rolesPatches.length - 1]};
                });
                return {...rh, roles, patch: appliedPatches[appliedPatches.length - 1]}
            }

            merged.rightholders
                .map(applyPathRH)
                .forEach((mrhItem) => {

                    const localRH = local.rightholders.find((rh) => rh.hash === mrhItem.hash);
                    const remoteRH = remote.rightholders.find((rh) => rh.hash === mrhItem.hash);
                    localRightholders.push(localRH);
                    remoteRightholders.push(remoteRH);


                    const rightHolder = {
                        ...mrhItem,
                    };
                    mergedRightholders.push(rightHolder);


                });

            const newData = {
                ...data,
                local: {...local, rightholders: localRightholders},
                remote: {...remote, rightholders: remoteRightholders},
                merged: {...merged, rightholders: mergedRightholders},
            };

            return {data: newData}
        })
    )
})(DiffViewer);
