import React from 'react';
import PropTypes from 'prop-types';
import style from './styles.css';

/**
 * @name TokenGrid
 * @render react
 * @description Grid layout for Tokens
 * @example
 * <TokenGrid text="Im a Hero!" primary />
 */
const TokenGrid = ({children, width, className}) => (
    <div className={`${style.TokenGrid} ${className ? className : ''}`} data-width={width}>
        {children}
    </div>
);

TokenGrid.propTypes = {
    /**
     * @property {text} width container maximum width (sm, md, lg)
     */
    width: PropTypes.string,
};

TokenGrid.defaultProps = {
    width: '',

};

export default TokenGrid;
