import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import bowser from 'bowser';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import styles from './index.css';

class Modal extends Component {
    onKeyDown = (event) => {
        if (event.keyCode === 27) {
            this.props.onClose();
        }
    };

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.modalContainer = React.createRef();
    }

    componentDidMount() {
        window.H11App.root.modalRoot.appendChild(this.el);
        window.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        window.H11App.root.modalRoot.removeChild(this.el);
        window.removeEventListener('keydown', this.onKeyDown);
    }

    render() {

        const modal = (
            <div className="Modal" ref={this.modalContainer}>
                <div className={styles['modal-overlay']} onClick={this.props.onClose}/>
                {this.props.children}
            </div>
        );

        const animation = (
            <ReactCSSTransitionGroup
                transitionName="Modal"
                transitionAppear={true}
                transitionAppearTimeout={300}
                transitionEnter={false}
                transitionLeave={false}>
                {modal}
            </ReactCSSTransitionGroup>

        );

        return ReactDOM.createPortal(bowser.msie ? modal : animation, this.el);
    }
}

Modal.propTypes = {
    onClose: PropTypes.func,
};

Modal.defaultProps = {
    onClose: () => {
    },
};

export default Modal;
