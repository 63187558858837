import React, {Component} from 'react';
import PropTypes from 'prop-types';

import styles from './index.css';

class DiffSection extends Component {

    onRowOver = (index, role) => (event) => {
        this.props.actions.onHighligh(index, role);
        event.stopPropagation();
    };

    render() {
        const {title, url, data, hover} = this.props;
        let blockTitle = (<h2 className={styles.Title}>{title}</h2>);

        if (url) {
            blockTitle = (<h2 className={styles.Title}><a href={url}>{title}</a></h2>);
        }

        let blockInfo = (
            <div className={styles.BlockInfo}>
                <dl>
                    <dt>Title</dt>
                    <dd>{data.title ? data.title : '-'}</dd>
                </dl>
                <dl>
                    <dt>ISWC</dt>
                    <dd>{data.code ? data.code : '-'}</dd>
                </dl>
                <dl>
                    <dt>Date</dt>
                    <dd>{data.date ? data.date : '-'}</dd>
                </dl>
            </div>
        );

        let blockTable = (
            <table className={styles.Table} cellSpacing="0">
                <colgroup>
                    <col/>
                    <col/>
                </colgroup>
                <thead>
                <tr>
                    <th className="rightholder">rightholder</th>
                    <th className="share">share</th>
                </tr>
                </thead>
                <tbody onMouseOut={this.onRowOver(-1)}>
                {data.rightholders.map((rh, index) => {
                    if (!rh) return (
                        <tr className={hover.index === index ? 'hovered' : ''} onMouseOver={this.onRowOver(index)}>
                            <td/>
                            <td/>
                        </tr>);

                    return (
                        <tr className={`${rh.patch ? rh.patch.mode : ''}${hover.index === index ? ' hovered' : ''}`}
                            onMouseOver={this.onRowOver(index)}>
                            <td>
                                <div style={{float: 'left'}}>
                                    <span className="title">{rh.title}</span><br/>
                                    <span className={styles.Role}>{rh.roles.map(role => {
                                        return (
                                            <span>
                                            <span
                                                className={hover.role === role.code && hover.index === index ? 'hovered' : ''}
                                                onMouseOverCapture={this.onRowOver(index, role.code)}
                                            >
                                                {role.title}
                                                </span>
                                                <span className="comma">,</span>
                                            </span>
                                        )
                                    })}</span>
                                </div>
                            </td>
                            <td className="share">
                                <div style={{float: 'left'}}>{parseInt(rh.share)}%</div>
                            </td>
                        </tr>)
                })}
                </tbody>
            </table>
        );
        return (
            <div className={styles.DiffSection}>
                {blockTitle}
                {blockInfo}
                {blockTable}
            </div>
        )
    }
}

DiffSection.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    data: PropTypes.object,
    hover: PropTypes.object,
    actions: PropTypes.object.isRequired,
};

DiffSection.defaultProps = {
    title: 'Section',
};

export default DiffSection;
