import {camelizeKeys} from "humps";
import Api from "./Api";

export default class Refs {
    static getAccountTypes(params) {
        const {account} = params;
        let req = `lookup/${account.id}/account_contributor_roles?scope=recording`;
        if (window.devMode) {
            return new Promise((res) => res({
                roles: [{
                    "id": "73",
                    "title": "VS Артист",
                    "right_groups": ["performance"]
                }, {"id": "99", "title": "Агент", "right_groups": ["producing", "performance"]}, {
                    "id": "101",
                    "title": "Администратор Смежных Прав",
                    "right_groups": ["producing", "performance"]
                }, {"id": "92", "title": "Аккордеон", "right_groups": ["performance"]}, {
                    "id": "19",
                    "title": "Актёр",
                    "right_groups": null
                }, {"id": "96", "title": "Альт", "right_groups": ["performance"]}, {
                    "id": "90",
                    "title": "Альт-саксофон",
                    "right_groups": ["performance"]
                }, {"id": "33", "title": "Ансамбль", "right_groups": ["performance"]}, {
                    "id": "21",
                    "title": "Артист",
                    "right_groups": ["performance"]
                }, {"id": "87", "title": "Арфа", "right_groups": ["performance"]}, {
                    "id": "20",
                    "title": "Архитектор",
                    "right_groups": null
                }, {"id": "22", "title": "Ассоциируемый Исполнитель", "right_groups": ["performance"]}, {
                    "id": "64",
                    "title": "Барабанщик",
                    "right_groups": ["performance"]
                }, {"id": "91", "title": "Баритон-саксофон", "right_groups": ["performance"]}, {
                    "id": "67",
                    "title": "Бас Гитарист",
                    "right_groups": ["performance"]
                }, {"id": "94", "title": "Бас-кларнет", "right_groups": ["performance"]}, {
                    "id": "72",
                    "title": "Бэк Вокалист",
                    "right_groups": ["performance"]
                }, {"id": "84", "title": "Виолончель", "right_groups": ["performance"]}, {
                    "id": "34",
                    "title": "Включенный Артист",
                    "right_groups": ["performance"]
                }, {"id": "65", "title": "Гитарист", "right_groups": ["performance"]}, {
                    "id": "43",
                    "title": "Главный Исполнитель",
                    "right_groups": ["performance"]
                }, {"id": "50", "title": "Главный Музыкант", "right_groups": ["performance"]}, {
                    "id": "41",
                    "title": "График Дизайнер",
                    "right_groups": null
                }, {"id": "74", "title": "Гример, Визажист", "right_groups": null}, {
                    "id": "23",
                    "title": "Группа \/ Оркестр",
                    "right_groups": null
                }, {"id": "32", "title": "Дизайнер", "right_groups": null}, {
                    "id": "31",
                    "title": "Дизайнер Костюмов",
                    "right_groups": null
                }, {"id": "29", "title": "Дирижер", "right_groups": ["performance"]}, {
                    "id": "36",
                    "title": "Дистрибьютор Фильмов",
                    "right_groups": null
                }, {"id": "77", "title": "Звукорежиссер", "right_groups": null}, {
                    "id": "39",
                    "title": "Звукорежиссер Фильма",
                    "right_groups": null
                }, {"id": "47", "title": "Издатель Периодики", "right_groups": null}, {
                    "id": "66",
                    "title": "Клавишник",
                    "right_groups": ["performance"]
                }, {"id": "85", "title": "Кларнет", "right_groups": ["performance"]}, {
                    "id": "28",
                    "title": "Компьютерный График",
                    "right_groups": null
                }, {"id": "82", "title": "Контрабас", "right_groups": ["performance"]}, {
                    "id": "30",
                    "title": "Контрибьютор",
                    "right_groups": ["performance"]
                }, {"id": "71", "title": "Мастеринг Инженер", "right_groups": null}, {
                    "id": "79",
                    "title": "Микс инженер",
                    "right_groups": null
                }, {"id": "70", "title": "Миксинг Инженер", "right_groups": null}, {
                    "id": "37",
                    "title": "Монтажер Фильма",
                    "right_groups": null
                }, {"id": "25", "title": "Мультипликатор", "right_groups": null}, {
                    "id": "61",
                    "title": "Неизвестно",
                    "right_groups": null
                }, {"id": "95", "title": "Окарина", "right_groups": ["performance"]}, {
                    "id": "62",
                    "title": "Определяемая Пользователем",
                    "right_groups": null
                }, {"id": "45", "title": "Оркестр", "right_groups": ["performance"]}, {
                    "id": "60",
                    "title": "Переводчик Субтитров",
                    "right_groups": null
                }, {"id": "68", "title": "Перкуссионист", "right_groups": ["performance"]}, {
                    "id": "52",
                    "title": "Программист",
                    "right_groups": ["producing"]
                }, {"id": "51", "title": "Продюсер", "right_groups": ["producing"]}, {
                    "id": "75",
                    "title": "Продюсер фонограммы",
                    "right_groups": ["producing"]
                }, {"id": "59", "title": "Редактор Субтитров", "right_groups": null}, {
                    "id": "35",
                    "title": "Режиссер Фильма",
                    "right_groups": null
                }, {"id": "63", "title": "Рекорд Лейбл", "right_groups": ["producing"]}, {
                    "id": "86",
                    "title": "Саксофон",
                    "right_groups": ["performance"]
                }, {"id": "78", "title": "Саунд Дизайнер", "right_groups": ["producing"]}, {
                    "id": "76",
                    "title": "Саунд Продюсер",
                    "right_groups": ["producing"]
                }, {"id": "80", "title": "Скрипка", "right_groups": ["performance"]}, {
                    "id": "81",
                    "title": "Скрипка Альт",
                    "right_groups": ["performance"]
                }, {"id": "56", "title": "Солист", "right_groups": ["performance"]}, {
                    "id": "58",
                    "title": "Студийный Персонал",
                    "right_groups": null
                }, {"id": "54", "title": "Сценарист", "right_groups": null}, {
                    "id": "57",
                    "title": "Театральный Режиссер",
                    "right_groups": null
                }, {"id": "98", "title": "Тенор-саксофон", "right_groups": ["performance"]}, {
                    "id": "88",
                    "title": "Тромбон",
                    "right_groups": ["performance"]
                }, {"id": "83", "title": "Труба", "right_groups": ["performance"]}, {
                    "id": "53",
                    "title": "Управляющий Правами",
                    "right_groups": ["producing", "performance"]
                }, {"id": "38", "title": "Фильм Продюсер", "right_groups": null}, {
                    "id": "93",
                    "title": "Флюгельгорн",
                    "right_groups": ["performance"]
                }, {"id": "49", "title": "Фото Директор", "right_groups": null}, {
                    "id": "48",
                    "title": "Фотограф",
                    "right_groups": null
                }, {"id": "26", "title": "Хор", "right_groups": ["performance"]}, {
                    "id": "27",
                    "title": "Хореограф",
                    "right_groups": null
                }, {"id": "97", "title": "Художественный руководитель", "right_groups": null}, {
                    "id": "46",
                    "title": "Художник",
                    "right_groups": null
                }, {"id": "40", "title": "Художник График", "right_groups": null}, {
                    "id": "55",
                    "title": "Художник Постановщик",
                    "right_groups": null
                }],
                status: true,
            }))
        }
        return Api.get(req)
            .then((json) => {
                return camelizeKeys(json)
            })
    }

    static getRecordingAccountTypes(params) {
        let req = `lookup/recording_contributor_role?limit=999`;
        if (window.devMode) {
            return new Promise((res) => res({
                roles: [{
                    "id": "73",
                    "title": "VS Артист",
                    "right_groups": ["performance"]
                }, {"id": "99", "title": "Агент", "right_groups": ["producing", "performance"]}, {
                    "id": "101",
                    "title": "Администратор Смежных Прав",
                    "right_groups": ["producing", "performance"]
                }, {"id": "92", "title": "Аккордеон", "right_groups": ["performance"]}, {
                    "id": "19",
                    "title": "Актёр",
                    "right_groups": null
                }, {"id": "96", "title": "Альт", "right_groups": ["performance"]}, {
                    "id": "90",
                    "title": "Альт-саксофон",
                    "right_groups": ["performance"]
                }, {"id": "33", "title": "Ансамбль", "right_groups": ["performance"]}, {
                    "id": "21",
                    "title": "Артист",
                    "right_groups": ["performance"]
                }, {"id": "87", "title": "Арфа", "right_groups": ["performance"]}, {
                    "id": "20",
                    "title": "Архитектор",
                    "right_groups": null
                }, {"id": "22", "title": "Ассоциируемый Исполнитель", "right_groups": ["performance"]}, {
                    "id": "64",
                    "title": "Барабанщик",
                    "right_groups": ["performance"]
                }, {"id": "91", "title": "Баритон-саксофон", "right_groups": ["performance"]}, {
                    "id": "67",
                    "title": "Бас Гитарист",
                    "right_groups": ["performance"]
                }, {"id": "94", "title": "Бас-кларнет", "right_groups": ["performance"]}, {
                    "id": "72",
                    "title": "Бэк Вокалист",
                    "right_groups": ["performance"]
                }, {"id": "84", "title": "Виолончель", "right_groups": ["performance"]}, {
                    "id": "34",
                    "title": "Включенный Артист",
                    "right_groups": ["performance"]
                }, {"id": "65", "title": "Гитарист", "right_groups": ["performance"]}, {
                    "id": "43",
                    "title": "Главный Исполнитель",
                    "right_groups": ["performance"]
                }, {"id": "50", "title": "Главный Музыкант", "right_groups": ["performance"]}, {
                    "id": "41",
                    "title": "График Дизайнер",
                    "right_groups": null
                },],
                status: true,
            }))
        }
        return Api.get(req)
            .then((json) => {
                return camelizeKeys(json)
            })
    }

    static getCompositionAccountTypes(params) {
        const {account} = params;
        let req = `lookup/composition_contributor_role?limit=999`;
        if (window.devMode) {
            return new Promise((res) => res({
                roles: [{"id": "74", "title": "Гример, Визажист", "right_groups": null}, {
                    "id": "23",
                    "title": "Группа \/ Оркестр",
                    "right_groups": null
                }, {"id": "32", "title": "Дизайнер", "right_groups": null}, {
                    "id": "31",
                    "title": "Дизайнер Костюмов",
                    "right_groups": null
                }, {"id": "29", "title": "Дирижер", "right_groups": ["performance"]}, {
                    "id": "36",
                    "title": "Дистрибьютор Фильмов",
                    "right_groups": null
                }, {"id": "77", "title": "Звукорежиссер", "right_groups": null}, {
                    "id": "39",
                    "title": "Звукорежиссер Фильма",
                    "right_groups": null
                }, {"id": "47", "title": "Издатель Периодики", "right_groups": null}, {
                    "id": "66",
                    "title": "Клавишник",
                    "right_groups": ["performance"]
                }, {"id": "85", "title": "Кларнет", "right_groups": ["performance"]}, {
                    "id": "28",
                    "title": "Компьютерный График",
                    "right_groups": null
                }, {"id": "82", "title": "Контрабас", "right_groups": ["performance"]}, {
                    "id": "30",
                    "title": "Контрибьютор",
                    "right_groups": ["performance"]
                }, {"id": "71", "title": "Мастеринг Инженер", "right_groups": null}, {
                    "id": "79",
                    "title": "Микс инженер",
                    "right_groups": null
                }, {"id": "70", "title": "Миксинг Инженер", "right_groups": null}, {
                    "id": "37",
                    "title": "Монтажер Фильма",
                    "right_groups": null
                }, {"id": "25", "title": "Мультипликатор", "right_groups": null}, {
                    "id": "61",
                    "title": "Неизвестно",
                    "right_groups": null
                }, {"id": "95", "title": "Окарина", "right_groups": ["performance"]}, {
                    "id": "62",
                    "title": "Определяемая Пользователем",
                    "right_groups": null
                }, {"id": "45", "title": "Оркестр", "right_groups": ["performance"]}, {
                    "id": "60",
                    "title": "Переводчик Субтитров",
                    "right_groups": null
                }, {"id": "68", "title": "Перкуссионист", "right_groups": ["performance"]}, {
                    "id": "52",
                    "title": "Программист",
                    "right_groups": ["producing"]
                }, {"id": "51", "title": "Продюсер", "right_groups": ["producing"]}, {
                    "id": "75",
                    "title": "Продюсер фонограммы",
                    "right_groups": ["producing"]
                }, {"id": "59", "title": "Редактор Субтитров", "right_groups": null}, {
                    "id": "35",
                    "title": "Режиссер Фильма",
                    "right_groups": null
                }, {"id": "63", "title": "Рекорд Лейбл", "right_groups": ["producing"]}, {
                    "id": "86",
                    "title": "Саксофон",
                    "right_groups": ["performance"]
                }, {"id": "78", "title": "Саунд Дизайнер", "right_groups": ["producing"]}, {
                    "id": "76",
                    "title": "Саунд Продюсер",
                    "right_groups": ["producing"]
                }, {"id": "80", "title": "Скрипка", "right_groups": ["performance"]}, {
                    "id": "81",
                    "title": "Скрипка Альт",
                    "right_groups": ["performance"]
                }, {"id": "56", "title": "Солист", "right_groups": ["performance"]}, {
                    "id": "58",
                    "title": "Студийный Персонал",
                    "right_groups": null
                }, {"id": "54", "title": "Сценарист", "right_groups": null}, {
                    "id": "57",
                    "title": "Театральный Режиссер",
                    "right_groups": null
                }, {"id": "98", "title": "Тенор-саксофон", "right_groups": ["performance"]}, {
                    "id": "88",
                    "title": "Тромбон",
                    "right_groups": ["performance"]
                }, {"id": "83", "title": "Труба", "right_groups": ["performance"]}, {
                    "id": "53",
                    "title": "Управляющий Правами",
                    "right_groups": ["producing", "performance"]
                }, {"id": "38", "title": "Фильм Продюсер", "right_groups": null}, {
                    "id": "93",
                    "title": "Флюгельгорн",
                    "right_groups": ["performance"]
                }, {"id": "49", "title": "Фото Директор", "right_groups": null}, {
                    "id": "48",
                    "title": "Фотограф",
                    "right_groups": null
                }, {"id": "26", "title": "Хор", "right_groups": ["performance"]}, {
                    "id": "27",
                    "title": "Хореограф",
                    "right_groups": null
                }, {"id": "97", "title": "Художественный руководитель", "right_groups": null}, {
                    "id": "46",
                    "title": "Художник",
                    "right_groups": null
                }, {"id": "40", "title": "Художник График", "right_groups": null}, {
                    "id": "55",
                    "title": "Художник Постановщик",
                    "right_groups": null
                }],
                status: true,
            }))
        }
        return Api.get(req)
            .then((json) => {
                return camelizeKeys(json)
            })
    }
}
