import {defineMessages} from 'react-intl';

const messages = {
	'splitsheet.field.composition_type': 'Composition Type',
	'splitsheet.field.created_in_country': 'Created In Country',
	'splitsheet.field.catalogue_number': 'Catalogue Number',
	'splitsheet.field.genres': 'Genres',
	'splitsheet.field.creation_date': 'Creation Date',
	'splitsheet.field.iswc': 'ISWC',
	'splitsheet.field.rightholder': 'Rightholder',
	'splitsheet.field.role': 'Role',
	'splitsheet.field.ownership': 'OWN',
	'splitsheet.field.share': 'SHRE',
	'splitsheet.field.collection': 'COL',
	'splitsheet.field.revenue': 'REV',
	'splitsheet.field.ipi': 'IPI №',
	'splitsheet.field.licensees': 'Licensees',
	'splitsheet.field.societies': 'Societies',
	'splitsheet.field.original_rightholders': 'Original Rightholders',

	'splitsheet.filters.field.countryId.title': 'Country',
	'splitsheet.filters.field.exploitationTypeId.title': 'Exploitation Type',
	'splitsheet.filters.field.rightId.title': 'Right Type',
	'splitsheet.filters.field.useTypeId.title': 'Use Type',
	'splitsheet.filters.field.outletId.title': 'Outlet',
	'splitsheet.filters.field.productId.title': 'Product',
	'splitsheet.filters.field.commercialModelId.title': 'Commercial Model',
	'splitsheet.filters.field.distributionChannelId.title': 'Distribution Channel',
	'splitsheet.filters.field.marketingChannelId.title': 'Marketing Channel',
	'splitsheet.filters.field.userInterfaceTypeId.title': 'User Interface Type',
	'splitsheet.filters.field.begins.title': 'Begins',
	'splitsheet.filters.field.reportDate.title': 'Report Date',
	'splitsheet.filters.field.societyId.title': 'Societies',

	'splitsheet.filters.action.hide': 'Hide',
	'splitsheet.filters.action.showAll': 'Show All',

	'splitsheet.filters.msg.society_rule_applied': 'Society rule applied',

	'resources.contributors.recording.title': 'Master rights',
	'resources.contributors.composition.title': 'Author rights',
	'resources.contributors.action.recalculateShares': 'Recalculate',
	'resources.contributors.action.addContributor': 'Add',
	'resources.contributors.field.rightholder': 'Rightholder',
	'resources.contributors.field.role': 'Role',
	'resources.contributors.field.exploitation': 'Exploitation',
	'resources.contributors.field.exploitationShort': 'Expl.',

	'report.err.collection_properties_missing': 'Cannot find any pattern',
	'report.action.no-import': 'Do not import',
	'report.title.general_properties': 'General',
	'report.title.composition_properties': 'Composition',
	'report.title.recording_properties': 'Recording',
	'report.title.financial_properties': 'Financial',

	'catalogue.scheme.link.go_wizard': 'catalogue.scheme.link.go_wizard',

	'action_continue': 'action_continue',
	'action_cancel': 'action_cancel',
	'question_delete_catalogue': 'question_delete_catalogue',

};

const h11Messages = {};

Object.keys(messages).forEach((key) => {
	h11Messages[key] = {
		id: key,
		defaultMessage: messages[key],
	};
});

export function getMessage(lexeme) {
	return h11Messages[lexeme] ? h11Messages[lexeme] : {
		id: lexeme,
		defaultMessage: lexeme
	};
}

export default defineMessages(
	{
		...h11Messages,
	},
);
