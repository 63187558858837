import React from 'react';
import PropTypes from 'prop-types';
import style from './styles.css';

/**
 * @name Token
 * @render react
 * @description Simple token
 * @example
 * <Token text="Im a Hero!" onDelete={()=>{}} />
 */
const Token = ({text, primary, onDelete}) => (
    <div className={style.Token} data-primary={primary}>
        <span>{text}</span>
        {onDelete && <button className={style.Button} onClick={onDelete}><i className="fa fa-times"/></button>}
    </div>

);

Token.propTypes = {
    /**
     * @property {boolean} primary determines is a primary button (emphasized)
     */
    primary: PropTypes.bool,

    /**
     * @property {string} text label to be displayed within the button
     */
    text: PropTypes.string,

    /**
     * @property {func} Event handler for delete button's 'click' event
     */
    onDelete: PropTypes.func,
};

Token.defaultProps = {
    text: 'Token!',
    primary: false,
    onDelete: null,
};

export default Token;
