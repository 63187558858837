import PropTypes from 'prop-types';
import React, {Component} from "react";
import Flag from '../../../components/Flag'
import styled from 'styled-components';

const Div = styled.div`
    display: flex;
    align-items: center;
    
    & .title {
    		margin-left: 5px;
    }    
`;
Div.displayName = 'CountryFlag.Div';

export class CountryFlag extends Component {
    render() {
        const {title, code, size} = this.props;
        return (
            <Div>
                <div className="flag">
                    <Flag code={code}/>
                </div>
                <div className="title"> {title}</div>
            </Div>

        );
    }
}

CountryFlag.defaultProps = {
    title: '',
    code: '',
    size: 17,
};

CountryFlag.propTypes = {
    code: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.number,

};

export default CountryFlag;
