import React from 'react';
import PropTypes from 'prop-types';
import style from './styles.css';
import TokenGrid from "../TokenGrid/TokenGrid";
import Token from "../Token/Token";
import InputDropdown from "../InputDropdown/InputDropdown";

/**
 * @name TokenInput
 * @render react
 * @description Input with dropdown and tokens
 * @example
 * <TokenInput />
 */

export default class TokenInput extends React.Component {

    static propTypes = {
        /**
         * @property {array} array of values
         */
        tokens: PropTypes.array,

        /**
         * @property {func} Event handler for fetch event
         */
        onFetch: PropTypes.func,

        /**
         * @property {func} Event handler for add value event
         */
        onAdd: PropTypes.func,

        /**
         * @property {func} Event handler for delete value event
         */
        onDelete: PropTypes.func,

        /**
         * @property {array} Items for drop down select
         */
        fetchResult: PropTypes.array,

        /**
         * @property {bool} Initial open state
         */
        open: PropTypes.bool,

        /**
         * @property {bool} Initial open state
         */
        hideEmpty: PropTypes.bool,

        /**
         * @property {number} Limits max number of tokens in the field
         */
        limitTokens: PropTypes.number,
    };

    static defaultProps = {
        tokens: [],
        limitTokens: Infinity,
    };

    open = () => {
        this.fetch(this.input.current.value)
        this.resize();
        this.setState({
            open: true,
        });
        window.addEventListener('click', this.windowClick);
        window.addEventListener('scroll', this.windowScroll);
        window.addEventListener('keyup', this.windowKeypress, true);
    };
    windowScroll = (event) => {
        // this.close();
        this.resize();
    };
    resize = () => {
        if (this.input.current) {
            this.input.current.style.width = `${Math.min(300, Math.max(25, this.input.current.value.length * 6.5 + 30))}px`;
        }
        this.setState({
            dropdownPosition: this.getTop(),
            dropdownWidth: this.getWidth(),
        });
    };

    componentWillUnmount() {
        window.removeEventListener('click', this.windowClick, true);
        window.removeEventListener('keyup', this.windowKeypress, true);
        window.removeEventListener('scroll', this.windowScroll);
    }

    focus = () => {
        if (this.input.current) {
            this.input.current.focus()
        }
    };
    change = () => {
        this.resize();
        if (this.props.fetchResult) {
            let filteredItems = [].concat(this.props.fetchResult).filter((item) => {
                return ~item.title.toLowerCase().search(this.input.current.value.toLowerCase());
            });

            this.setState({
                text: this.input.current.value,
                filteredItems,
            });
        } else {
            this.setState({
                text: this.input.current.value,
            });
        }

        if (!this.state.open) {
            this.open();
        }
        this.fetch(this.input.current.value)
    };
    add = (val) => () => {
        this.setState({
            open: false,
            text: ''
        });

        if (typeof this.props.onAdd === 'function') {
            this.props.onAdd(val);
        }
    };
    remove = (val) => () => {
        if (typeof this.props.onDelete === 'function') {
            this.props.onDelete(val);
        }
    };

    fetch = (value) => {
        if (typeof this.props.onFetch === 'function') {
            this.props.onFetch(value);
        }
    };

    onFocus = (event) => {
        this.open()
    };
    getWidth = () => {
        const gridRect = this.grid.current.getBoundingClientRect();
        return gridRect.width + 12
    };
    close = () => {
        window.removeEventListener('click', this.windowClick);
        window.removeEventListener('scroll', this.windowScroll);
        window.removeEventListener('keyup', this.windowKeypress, true);
        this.setState({
            open: false,
        });
    };
    getTop = () => {
        const gridRect = this.grid.current.getBoundingClientRect();
        return gridRect.height + gridRect.top + 4 + window.scrollY;
    };

    windowKeypress = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (this.state.open) {
            switch (event.keyCode) {
                case 27:
                    this.close();
                    break;
                default:
                    break;
            }

        } else {
            this.close();
        }
    };
    windowClick = (event) => {
        if (this.grid.current && !this.grid.current.contains(event.target)) {
            event.stopPropagation();
            this.close();
        }
    };

    constructor() {
        super();
        this.input = React.createRef();
        this.grid = React.createRef();
        this.dropdown = React.createRef();
        this.state = {
            dropdownPosition: 0,
            dropdownWidth: 0,
            width: 0,
            open: false,
            text: '',
            filteredItems: [],
        };
    }

    componentDidMount() {
        const {fetchResult} = this.props;
        this.setState({
            filteredItems: fetchResult,
        });
        this.resize();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fetchResult && this.props.fetchResult) {
            if (prevProps.fetchResult.map((i) => i.id).join('') !== this.props.fetchResult.map((i) => i.id).join('')) {
                this.setState({
                    filteredItems: this.props.fetchResult,
                });
                this.resize();
            }
        } else if (this.props.fetchResult) {
            this.setState({
                filteredItems: this.props.fetchResult,
            });
            this.resize();
        }

    }

    renderItem = (item) => {
        return <li key={item.id} className={style.ListItem} onClick={this.add(item)}>{item.title}</li>
    };

    render() {
        const {tokens, hideEmpty} = this.props;
        const {open, text} = this.state;
        let isShowDropdown = open;

        if (hideEmpty) {
            isShowDropdown = open && text.length > 0
        }

        return (
            <div className={style.TokenInput} onClick={this.focus}>
                {this.renderGrid(tokens)}
                {isShowDropdown && this.renderDropdown()}
            </div>
        );
    }

    renderGrid(tokens) {
        const isShowInput = tokens.length < this.props.limitTokens;
        return (
            <div ref={this.grid} className={style.Grid} onClick={this.focus}>
                <TokenGrid>
                    {tokens.length > 0 && tokens.map((token) => (
                        <Token key={token} primary onDelete={this.remove(token)} text={token}/>
                    ))}
                    {isShowInput &&
                    <input className={style.Text} value={this.state.text} ref={this.input} onChange={this.change}
                           onFocus={this.onFocus}/>}
                </TokenGrid>
            </div>
        );
    }

    renderDropdown() {
        const {dropdownPosition, dropdownWidth, filteredItems, open} = this.state;
        return (
            <div className={style.Dropdown} style={{top: dropdownPosition, width: dropdownWidth}} ref={this.dropdown}>
                <InputDropdown items={filteredItems} renderItem={this.renderItem} open={open}/>
            </div>
        );
    }
}


/*
* проверить в ие, фф
* локализация
* */