import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Search from './Search';

const Div = styled.div`
		position: relative;
		margin-bottom: 10px;
    
    &>.title {
   		color: #bcbcbc;
    	text-transform: uppercase;
    	margin-bottom: 2px;
    	user-select: none;
    }
    
    & .value {
    	display: flex;
    	justify-content: space-between;
    	align-items: baseline;
    	.value_data {
    		//float: left;
    		user-select: none;
    		//max-width: calc(100% - 25px);
    	}
    	.value_error {
    		color:red;
    	}
    	
    	.value_edit {
    		//float: right;
    		border: 0;
    		cursor: pointer;
    		//margin-top: -1px;
    		&.delete{
    			color:red;
    		}
    	}
    	
    }
    
`;
Div.displayName = 'FilterField.Div';

const Popup = styled.div`
    position: absolute;
    z-index: 2;
    left: 0;
    top: 100%;
    width: 100%;
    margin-top: 10px;
    background: #fff;
    box-shadow: 0 0 30px 0 rgba(0,0,0, .25);
    &:before {
    	content: '';
    	position: absolute;
    	bottom: 100%;
    	left: 15%;
    	width: 0;
			height: 0;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 8px solid #fff;
    }
    
    & > .content {
    	overflow: auto;
    	max-height: 200px;
    	padding-top: 5px;
    	
    	ul {
    		list-style: none;
    		margin: 0;
    		padding: 0;
    	}
    	li {
    		cursor: pointer;
    		padding: 5px 10px;
    		//transition: background-color .1s;
    		&:hover,
    		&:focus,
    		&._focus {
    			background-color: #9DCEF7;
    			transition: none;
    		}
    	}
    }
    & button.show-more {
    		transition: background-color .1s;
        border: 0;
    		width: 100%;
    		background-color: #F5F5F5;
    		color: #0069c4;
    		border-radius: 0;
    		height: 25px;
    		cursor: pointer;
    		text-align: left;
    		padding-left: 10px;
    		&>i{
    			color: #bbb;
    			font-size: 10px;
    		}
    		
    		&:hover{
    		background-color: #E5E5E5;
    		transition: none;
    		} 
    }
`;

export class FilterField extends PureComponent {
    openPopup = () => {
        // if (this.state.isPopupOpen) {
        // 	this.close();
        // } else {
        this.open();
        // }
    };
    clearValue = () => {
        this.close();
        this.props.onChange(0);
    };
    onShowAll = () => {
        this.setState({isShowAll: !this.state.isShowAll});
    };
    open = () => {
        const items = this.getItems();
        const selectedIndex = items.findIndex((item) => +item.id === +this.props.value);
        this.setState({isPopupOpen: true, selectedIndex, isShowAll: this.props.recommended.length === 0});
        window.addEventListener('keydown', this.onWindowKeyPress);
        window.addEventListener('click', this.onWindowClick);
    };
    close = () => {
        this.setState({isPopupOpen: false, filter: ''});
        window.removeEventListener('keydown', this.onWindowKeyPress);
        window.removeEventListener('click', this.onWindowClick);
    };
    onWindowKeyPress = (event) => {
        event.stopPropagation();
        switch (event.keyCode) {
            case 27:
                return this.close();
            case 38: {
                const selectedIndex = Math.min(Math.max(0, this.state.selectedIndex - 1), this.props.items.length - 1);
                this.setState({selectedIndex});
                break;
            }
            case 40: {
                const selectedIndex = Math.min(Math.max(0, this.state.selectedIndex + 1), this.props.items.length - 1);
                this.setState({selectedIndex});
                break;
            }
            case 13:
                this.onChange(this.state.selectedIndex);
                this.close();
                break;

            default:
                return;
        }
    };
    onWindowClick = (event) => {
        if (!ReactDOM.findDOMNode(this).contains(event.target)) {
            this.close();
        }
    };
    onChange = (index) => {
        const items = this.getItems();
        const item = items[index];
        this.props.onChange(item.id)
    };
    onSelect = (id, index) => () => {
        this.setState({selectedIndex: index});
        this.onChange(index);
        this.close();
    };
    onSearchChange = (value) => {
        const index = this.props.items.findIndex((item) => {
            return item.title.toLowerCase().search(value.toLowerCase()) === 0;
        });

        this.setState({
            filter: value,
            selectedIndex: index !== -1 ? index : this.state.selectedIndex,
            isShowAll: value !== '' || this.state.isShowAll
        });
    };

    constructor() {
        super();
        this.items = [];
        this.recommended = [];
        this.state = {
            isPopupOpen: false,
            isShowAll: false,
            filter: '',
            selectedIndex: -1,
            selectedId: 0,
        }
    }

    componentWillUpdate(nextProps) {
        const value = this.props.items.find((i) => +i.id === +this.props.value);
        if (value) {
            this.lastTitle = value.title;
        }
    }

    componentWillUnmount() {
        this.close();
    }

    getItems() {
        return this.state.isShowAll ? this.props.items : this.props.recommended;
    }

    render() {
        const {title} = this.props;
        const {isPopupOpen, isShowAll, filter, selectedIndex} = this.state;
        const items = this.getItems();
        const valueIndex = this.props.items.findIndex((i) => +i.id === +this.props.value);
        const value = this.props.items[valueIndex];
        const error = this.props.value > 0 && valueIndex === -1;
        return (
            <Div
                onClick={(event) => {
                    if (isPopupOpen) {
                        event.stopPropagation();
                    }
                }}
            >
                <div className="title">{title}:</div>
                <div className="value">
                    {error ? <span className="value_error">
						{this.lastTitle || this.props.value}
					</span> : <span className="value_data">
						{value ? value.title : '-'}
					</span>}

                    {isPopupOpen || error ? <button className="value_edit delete" onClick={this.clearValue}>
                            <i className="fa fa-times" aria-hidden="true"/>
                        </button>
                        :
                        <button className="value_edit" onClick={this.openPopup}>
                            <i className="fa fa-ellipsis-h" aria-hidden="true"/>
                        </button>
                    }
                </div>
                {isPopupOpen && <Popup>
                    <Search onChange={this.onSearchChange} value={filter}/>
                    <div className="content">
                        <ul>
                            {items.map((item, index) => (
                                <li
                                    key={item.id}
                                    ref={node => node && index === selectedIndex ? node.scrollIntoViewIfNeeded() : null}
                                    className={`${index === selectedIndex ? '_focus' : ''}`}
                                    onClick={this.onSelect(item.id, index)}
                                >
                                    {item.title}
                                </li>))}
                        </ul>
                        {!isShowAll &&
                        <button className="show-more" onClick={this.onShowAll}>Show All <i
                            className="fa fa-chevron-right"/>
                        </button>}
                    </div>
                </Popup>}
            </Div>

        );
    }
}

FilterField.defaultProps = {
    title: "FilterField",
    items: [],
    recommended: [],
    onChange: () => {
    },
};

FilterField.propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
    items: PropTypes.array,
    recommended: PropTypes.array,
    onChange: PropTypes.func,
};

export default FilterField;
