import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from "react";
import styled from 'styled-components';
import messages from './../../messages';
import {CountryField, DateField, FilterField} from './Fields/index';
import {Row} from './Grid';

const Container = styled(Row)`
 		margin-left: 20px;
		display: flex;
        flex-direction: column;
        min-width: 180px;
    & .toggle {
    	border-radius: 0;
    	padding: 3px 10px;
    	margin: 9px auto;
    	color: #0069c4;
    	text-transform: uppercase;
    	cursor: pointer;
    	i {
    	margin-left: 5px;
    		color: #bcbcbc;
    	}
    }
`;

function sortByTitle(a, b) {
    if (!a.title || !b.title) {
        return 0;
    }
    const titleA = a.title.toUpperCase();
    const titleB = b.title.toUpperCase();
    if (titleA < titleB) {
        return -1;
    }
    if (titleA > titleB) {
        return 1;
    }
    // names must be equal
    return 0;
}

function mapRecommended(items, recommended) {
    return [...recommended.map((r) => ({...items.find((i) => +i.id === +r.id), ...r}))];
}

export class Filters extends Component {

    onChange = (key) => (value) => {
        this.props.onChange(key, value)
    };
    onToggle = () => {
        this.setState({showAll: !this.state.showAll})
    };

    constructor() {
        super();
        this.state = {
            showAll: false,
        };
    }

    getFilters(keyword) {
        if (this.props.tags[keyword]) {
            const {items, recommended} = this.props.tags[keyword];
            if (items && recommended) {
                return {
                    items: items.sort(sortByTitle),
                    recommended: mapRecommended(items, recommended).sort(sortByTitle),
                }
            }
        }
        return {
            items: [],
            recommended: [],
        }
    }

    render() {
        const {formatMessage} = this.context.intl;
        const filters = {
            'countryId': {
                value: this.props.data.countryId,
                ...this.getFilters('countries'),
                show: true,
                type: 'country',
            },
            'exploitationTypeId': {
                value: this.props.data.exploitationTypeId,
                ...this.getFilters('exploitationTypes'),
                show: true,
            },
            'rightId': {
                value: this.props.data.rightId,
                ...this.getFilters('rights'),
            },
            'useTypeId': {
                show: true,
                value: this.props.data.useTypeId,
                ...this.getFilters('useTypes'),
            },
            'outletId': {
                show: true,
                value: this.props.data.outletId,
                ...this.getFilters('outlets'),
            },
            'productId': {
                value: this.props.data.productId,
                ...this.getFilters('products'),
                show: true,
            },
            'commercialModelId': {
                value: this.props.data.commercialModelId,
                ...this.getFilters('commercialModels'),
            },
            'distributionChannelId': {
                value: this.props.data.distributionChannelId,
                ...this.getFilters('distributionChannels'),
            },
            'marketingChannelId': {
                value: this.props.data.marketingChannelId,
                ...this.getFilters('marketingChannels'),
            },
            'userInterfaceTypeId': {
                value: this.props.data.userInterfaceTypeId,
                ...this.getFilters('interfaceTypes'),
            },
            'societyId': {
                value: this.props.data.societyId,
                ...this.getFilters('societies'),
                show: true,
            },
            'begins': {
                show: true,
                value: moment(this.props.data.begins),
                type: 'date',
            },
            'reportDate': {
                value: moment(this.props.data.reportDate),
                type: 'date',
            }
        };

        let objects = Object.keys(filters);
        if (!this.state.showAll) {
            objects = Object.keys(filters).filter((key) => filters[key].show);
        }

        return (
            <Container style={{maxHeight: objects.length * 100}}>
                {objects.map((key) => {
                    switch (filters[key].type) {
                        case 'country':
                            return (
                                <CountryField
                                    key={`${key}_country`}
                                    value={filters[key].value}
                                    items={filters[key].items}
                                    recommended={filters[key].recommended}
                                    title={formatMessage(messages[`splitsheet.filters.field.${key}.title`])}
                                    onChange={this.onChange(key)}
                                />
                            );
                        case 'date':
                            return (
                                <DateField
                                    key={`${key}_date`}
                                    value={filters[key].value}
                                    title={formatMessage(messages[`splitsheet.filters.field.${key}.title`])}
                                    onChange={this.onChange(key)}
                                />
                            );
                        default:
                            return (
                                <FilterField
                                    key={`${key}_text`}
                                    value={filters[key].value}
                                    items={filters[key].items}
                                    recommended={filters[key].recommended}
                                    title={messages[`splitsheet.filters.field.${key}.title`] ? formatMessage(messages[`splitsheet.filters.field.${key}.title`]) : messages[`splitsheet.filters.field.${key}.title`]}
                                    onChange={this.onChange(key)}
                                />
                            );
                    }
                })}
                {this.state.showAll ? <button
                    className="toggle" onClick={this.onToggle}
                >
                    {formatMessage(messages[`splitsheet.filters.action.hide`])}
                    <i className="fa fa-chevron-up" aria-hidden="true"/>
                </button> : <button className="toggle" onClick={this.onToggle}>
                    {formatMessage(messages[`splitsheet.filters.action.showAll`])}
                    <i
                        className="fa fa-chevron-down" aria-hidden="true"
                    />
                </button>}
            </Container>
        );

    }
}

Filters.contextTypes = {
    intl: PropTypes.object,
};

Filters.defaultProps = {
    tags: {},
    data: {},
    onChange: () => {
    },
};

Filters.propTypes = {
    tags: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func,
};

export default Filters;
