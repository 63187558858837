import PropTypes from 'prop-types';
import React, {Component} from "react";
import styled from 'styled-components';
import messages from '../../messages';
import {LicenseesData} from './LicenseesData';
import {Table, TableHeader, TableSubHeader} from './Table';

const AppliedRule = styled.div`
    padding-top: 5px;
    font-size: 13px;
    
    & .gray {
      color: RGBA(0, 0, 0, .60);
    }
    & span:last-child .separator {
      display: none;
    }
    
    & .fa-exclamation-triangle {
      color: #ff8c39;
      margin-right: 5px;
    }
`;


export class RightHolders extends Component {
    render() {
        const {formatMessage} = this.context.intl;
        const accounts = {...this.props.licensees, ...this.props.original};
        const selectedAccount = {...accounts[this.props.selected]};
        let highLight = {};

        if (accounts[this.props.selected]) {
            if (selectedAccount.allLicensee.length === 0) {
                highLight[selectedAccount.accountId] = 'blue';
            } else {
                highLight[selectedAccount.accountId] = 'green';
                selectedAccount.allLicensee.forEach((id) => highLight[id] = 'blue');
            }
            selectedAccount.allLicensors.forEach((id) => highLight[id] = 'green');
        }

        let cols = [
            {className: '', name: formatMessage(messages['splitsheet.field.rightholder'])},
            {className: '', name: formatMessage(messages['splitsheet.field.role'])},
            {className: 'share', name: formatMessage(messages['splitsheet.field.ownership'])},
            {className: 'share', name: formatMessage(messages['splitsheet.field.collection'])},
            {className: 'share', name: formatMessage(messages['splitsheet.field.revenue'])},
            {className: '', name: formatMessage(messages['splitsheet.field.ipi'])},
        ];
        let appliedRuleText = '';
        if (this.props.applied) {
            cols = [
                {className: '', name: formatMessage(messages['splitsheet.field.rightholder'])},
                {className: '', name: formatMessage(messages['splitsheet.field.role'])},
                {className: 'share', name: formatMessage(messages['splitsheet.field.share'])},
                {className: 'share', name: formatMessage(messages['splitsheet.field.ownership'])},
                {className: 'share', name: formatMessage(messages['splitsheet.field.collection'])},
                {className: 'share', name: formatMessage(messages['splitsheet.field.revenue'])},
                {className: '', name: formatMessage(messages['splitsheet.field.ipi'])},
            ];
            appliedRuleText = Object.keys(this.props.applied)
                .map((key) => (<span key={key}><b>{this.props.applied[key].title}</b> <span
                    className="gray">({this.props.applied[key].share})</span> <span
                    className="separator"> / </span></span>));

        }


        return (
            <Table cellSpacing="0">
                <colgroup>
                    <col style={{width: '30%'}}/>
                    <col style={{width: '30%'}}/>
                    {this.props.applied && <col className={'share'}/>}
                    <col className={'share'}/>
                    <col className={'share'}/>
                    <col className={'share'}/>
                    <col/>
                </colgroup>

                <TableHeader
                    cols={cols}
                />

                {/*<TableSubHeader title={formatMessage(messages['splitsheet.field.societies'])} />*/}
                {/*<LicenseesData*/}
                {/*accounts={this.props.licensees} share={selectedAccount.share}*/}
                {/*highlight={highLight} onSelect={this.props.onSelect} showShare={!!this.props.selected}*/}
                {/*/>*/}

                <TableSubHeader title={formatMessage(messages['splitsheet.field.licensees'])}/>
                <LicenseesData
                    accounts={this.props.licensees} share={selectedAccount.share} tags={this.props.tags}
                    applied={this.props.applied}
                    highlight={highLight} onSelect={this.props.onSelect} showShare={!!this.props.selected}
                />

                <TableSubHeader title={formatMessage(messages['splitsheet.field.original_rightholders'])}/>
                <LicenseesData
                    accounts={this.props.original} share={selectedAccount.share} tags={this.props.tags}
                    applied={this.props.applied}
                    highlight={highLight} onSelect={this.props.onSelect} showShare={!!this.props.selected}
                />
                {this.props.applied && <tbody>
                <tr>
                    <td colSpan={7}>
                        <AppliedRule> <i
                            className="fa fa-exclamation-triangle"/>{formatMessage(messages['splitsheet.filters.msg.society_rule_applied'])}: {appliedRuleText}
                        </AppliedRule>
                    </td>
                </tr>
                </tbody>}
            </Table>
        );
    }
}

RightHolders.contextTypes = {
    intl: PropTypes.object,
};

RightHolders.defaultProps = {
    licensees: {},
    original: {},
    onSelect: () => {
    },
    share: {},
};

RightHolders.propTypes = {
    licensees: PropTypes.object,
    original: PropTypes.object,
    onSelect: PropTypes.func,
    selected: PropTypes.any,
    share: PropTypes.object,
    tags: PropTypes.object,
    applied: PropTypes.object,
};

export default RightHolders;
