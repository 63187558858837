import PropTypes from 'prop-types';
import React, {Component} from "react";
import styled from 'styled-components';

const Select = styled.select`
    width: 100%;
    margin: 5px 0;
    padding: 2px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
`;
// todo убрать font если нужны дефолтные селекты

Select.displayName = 'Styled.Select';

export class SelectField extends Component {
    render() {
        const {value, values} = this.props;
        return (
            <div>
                <Select value={value} onChange={this.props.onChange}>
                    {values.map(opt => <option key={opt.id} value={opt.id}>{opt.title}</option>)}
                </Select>
            </div>
        );
    }
}

SelectField.defaultProps = {
    value: "Select one",
    values: [],
    onSelect: () => {
    },
};

SelectField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    values: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
};

export default SelectField;
