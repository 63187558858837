import {camelizeKeys} from "humps";
import Api from "./Api";

export default class Translations {
	static getTranslations({locale, module, modules}) {
        let req = `translate/${locale}`;
		const fd = new FormData();
		if (modules) {
			modules.forEach((module) => {
				fd.append('keywords[]', module);
			});
		} else if (module) {
			fd.append('keywords[]', module);
		}
		return Api.post(req, fd)
            .then((json) => {
				return json;
            })
    }
}
