import React from 'react';
import PropTypes from 'prop-types';
import style from './styles.css';

/**
 * @name InputDropdown
 * @render react
 * @description Dropdown for an autocomplete inputs
 * @example
 * <InputDropdown items={[]} />
 */
export default class InputDropdown extends React.Component {
    static propTypes = {
        /**
         * @property {boolean} dropdown items
         */
        items: PropTypes.array,

        /**
         * @property {boolean} open state
         */
        open: PropTypes.bool,

        /**
         * @property {boolean} loading state
         */
        loading: PropTypes.bool,

        /**
         * @property {func} external render function for list items
         */
        renderItem: PropTypes.func,

    };

    static defaultProps = {
        items: [],
        open: false,
        renderItem: (item) => <li>{item.toString()}</li>,
    };
    renderItemThroughProps = (item) => {
        const {renderItem} = this.props;
        return renderItem(item)
    };

    constructor() {
        super();
        this.state = {};
    }

    render() {
        const {open, loading, items} = this.props;
        if (!open) return null;
        if (loading) return this.renderLoading();
        if (items.length === 0) return this.renderNoItems();
        return this.renderDropdown()
    }

    renderDropdown() {
        const {items, open} = this.props;
        return (
            <ul className={style.InputDropdown} data-open={open}>
                {items.map(this.renderItemThroughProps)}
            </ul>
        );
    }

    renderLoading() {
        return <div className={style.Message}>Loading...</div>
    }

    renderNoItems() {
        return <div className={style.Message}>No items</div>
    }
}
