import classnames from 'classnames';
import {LanguageContext} from 'modules/LanguageProvider';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {Cell, Row, StickyTable} from 'react-sticky-table';
import 'react-sticky-table/dist/react-sticky-table.css';
import {mapActionCreators, rxConnect} from 'rx-connect';
import {Observable, Subject} from 'rxjs';
import Loading from '../../components/Loading';
import * as API from '../../services';
import {default as messages, getMessage} from './../../messages';
import styles from './ReportTable.module.css';

//todo

class ReportTable extends Component {
	static propTypes = {
		data: PropTypes.object,
		id: PropTypes.string,
		reportType: PropTypes.string,
	};
	static defaultProps = {
		id: null,
		reportType: 'recording',
	};

	constructor(props) {
		super(props);
		this.table = React.createRef();
		this.state = {
			data: null,
			selected: 0,
			bindings: [],
			deleted: [],
			selectedBinding: null,
			patterns: [],
			trySubmit: false,
		};
	}

	onCellClick = (index) => () => {
		this.setState({
			selected: index,
			selectedBinding: this.state.data.cols[index].binding || null,
		});
	};
	setBinding = (id) => () => {
		const data = Object.assign({}, this.state.data);
		data.cols[this.state.selected].binding = data.fields.find((f) => f.id === id);
		data.cols[this.state.selected].bindingId = id;
		const bindings = data.cols.filter((c) => c.binding).map((c, index) => c.binding.keyword);
		Object.keys(data.groups).forEach((key) => {
			data.groups[key].forEach((f) => {
				if (data.cols.filter((c) => c.binding).find((b) => b.binding.keyword === f.keyword)) {
					f.used = true;
				} else {
					f.used = false;
				}
			});

		});
		const patterns = this.detectPatterns();
		this.disableUselessFields({
			...this.state,
			data
		});
		this.setState({
			patterns,
			bindings,
			data,
			selectedBinding: data.cols[this.state.selected].binding
		});
		this.frozenHeightFix();
	};

	clearBinding = () => {
		const data = Object.assign({}, this.state.data);
		Object.keys(data.groups).forEach((key) => {
			const field = data.groups[key].find((f) => f.id === data.cols[this.state.selected].binding.id);
			if (field) {
				field.used = false;
			}
		});
		data.cols[this.state.selected].binding = null;
		data.cols[this.state.selected].bindingId = null;
		const bindings = data.cols.filter((c) => c.binding).map((c, index) => c.binding.keyword);
		const patterns = this.detectPatterns();
		this.setState({
			patterns,
			bindings,
			data,
			selectedBinding: null
		});
		this.frozenHeightFix();

	};
	deleteRow = (rowIndex) => () => {
		const data = Object.assign({}, this.state.data);
		data.rows[rowIndex].ignored = !this.state.data.rows[rowIndex].ignored;
		this.props.ignoreRows({
			id: this.props.id,
			rows: this.state.data.rows.filter((r) => r.ignored).map((r, i) => i)
		});
		this.setState({data});
	};

	onSubmit = (e) => {
		const patterns = this.detectPatterns();

		if (patterns.length === 0) {
			e.preventDefault();
			e.stopPropagation();
		}
		this.setState({
			patterns,
			trySubmit: true
		});
	};

	componentDidMount() {
		this.findForm();
		this.props.context.getTranslations({module: 'report.*'});
		this.props.getData({id: this.props.id});
		this.addListeners();
	}

	componentDidUpdate() {
		// if (this.table.current) {
		// 	const left = this.table.current.querySelector('.sticky-table-y-wrapper .sticky-table-column').getBoundingClientRect().width;
		// 	const top = this.table.current.querySelector('.sticky-table-header-wrapper').getBoundingClientRect().height;
		// 	const scroll = this.table.current.querySelector('.y-scrollbar').getBoundingClientRect().width;
		// 	this.table.current.querySelector('.x-scrollbar').style.maxWidth = `calc(100% - ${left + scroll}px)`;
		// 	this.table.current.querySelector('.x-scrollbar').style.left = `${left}px`;
		// 	this.table.current.querySelector('.y-scrollbar').style.maxHeight = `calc(100% - ${top + scroll}px)`;
		// }
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.data) {
			this.setState({data: nextProps.data});
			this.disableUselessFields({
				...this.state,
				data: nextProps.data
			});
		}

	}

	getBindings() {

		return this.state.data.cols.map((c, index) => {
			if (c.binding) {
				return `${c.binding.id}=${index}`;
			}
		}).filter((b) => b).join(',');
	}

	componentWillUnmount() {
		this.removeListeners();
	}

	//FIXME убрать это отсюда
	findForm() {
		this.form = document.querySelector('form');
	}

	frozenHeightFix() {
		document.querySelectorAll(".sticky-table-corner .sticky-table-cell").forEach(function (item) {
			item.style.height = 0;
		});
	}

	addListeners() {
		if (this.form) {
			this.form.addEventListener('submit', this.onSubmit);
		}
		document.addEventListener('keydown', this.keyDownHandler);
	}

	removeListeners() {
		if (this.form) {
			this.form.removeEventListener('submit', this.onSubmit);
		}
		document.removeEventListener('keydown', this.keyDownHandler);
	}

	keyDownHandler = (event) => {
		if (!this.props.data || this.props.data.status === false || this.state.data.rows.length === 0) {
			return;
		}

		this.setState(prevState => {
			let {selected} = prevState;

			switch (event.key) {
				case 'ArrowUp':
					selected = 0;
					break;
				case 'ArrowDown':
					selected = prevState.data.header.length - 1;
					break;
				case 'ArrowLeft':
					selected--;
					break;
				case 'ArrowRight':
					selected++;
					break;
				default:
					return prevState;
			}

			if (selected < 0) {
				selected = 0;
			} else if (selected >= prevState.data.header.length) {
				selected = prevState.data.header.length - 1;
			}

			return {
				...prevState,
				selected,
				selectedBinding: prevState.data.cols[selected].binding || null,
			};
		},()=>{
			document.querySelector(`.${styles.selected}`).scrollIntoView({inline: "center"});
		});
	};

	disableUselessFields(state = this.state) {
		const data = {...state.data};
		if (data.cols) {
			const bindings = data.cols.filter((c) => c.binding).map((c) => c.binding.keyword);
			if (Object.values(bindings).find((field) => field === 'recording_collection_performance')) {
				//disable
				const field = data.fields.find((field) => field.keyword === 'recording_collection_producing');
				if (field) {
					field.used = true;
				}
			} else if (Object.values(bindings).find((field) => field === 'recording_collection_producing')) {
				//disable
				const field = data.fields.find((field) => field.keyword === 'recording_collection_performance');
				if (field) {
					field.used = true;
				}
			} else {
				//enable
				let field = data.fields.find((field) => field.keyword === 'recording_collection_performance');
				if (field) {
					field.used = false;
				}
				field = data.fields.find((field) => field.keyword === 'recording_collection_producing');
				if (field) {
					field.used = false;
				}
			}
		}
		this.setState({
			...state,
			data
		});

	}

	checkPattern(fields, bindings) {
		let res = true;
		fields.forEach((f) => {
			if (!bindings.find((b) => b === f)) {
				res = false;
			}
		});
		return res;
	}

	detectPatterns() {
		const data = Object.assign({}, this.state.data);
		const bindings = data.cols.filter((c) => c.binding).map((c) => c.binding.keyword);

		const patterns = [];
		if (this.props.reportType === 'recording') {
			// quantity price recording_collection_share recording_owner_share
			// quantity price recording_collection_share
			// recording_collection
			// collection
			// quantity price

			if (this.checkPattern([
				'quantity',
				'price',
				'recording_collection_share',
				'recording_owner_share'
			], bindings)) {
				patterns.push('pattern1');

			}
			if (this.checkPattern(['quantity', 'price', 'recording_collection_share'], bindings)) {
				patterns.push('pattern2');
			}

			if (this.checkPattern(['recording_collection'], bindings)) {
				patterns.push('pattern3');
			}
			if (this.checkPattern(['recording_collection_performance'], bindings)) {
				patterns.push('pattern3.1');
			}
			if (this.checkPattern(['recording_collection_producing'], bindings)) {
				patterns.push('pattern3.2');
			}

			if (this.checkPattern(['collection'], bindings)) {
				patterns.push('pattern 4');
			}

			if (this.checkPattern(['quantity', 'price'], bindings)) {
				patterns.push('pattern 5');
			}

		} else if (this.props.reportType === 'composition') {
			// quantity price composition_collection_share composition_owner_share
			// quantity price composition_collection_share
			// composition_collection
			// collection
			// quantity price

			if (this.checkPattern([
				'quantity',
				'price',
				'composition_collection_share',
				'composition_owner_share'
			], bindings)) {
				patterns.push('pattern 1');
			}
			if (this.checkPattern(['quantity', 'price', 'composition_collection_share'], bindings)) {
				patterns.push('pattern 2');
			}

			if (this.checkPattern(['composition_collection'], bindings)) {
				patterns.push('pattern 3');
			}
			if (this.checkPattern(['collection'], bindings)) {
				patterns.push('pattern 4');
			}

			if (this.checkPattern(['quantity', 'price'], bindings)) {
				patterns.push('pattern 5');
			}

		} else if (this.props.reportType === 'composition_recording') {
			// quantity price recording_collection_share recording_owner_share composition_collection_share composition_owner_share
			// quantity price recording_collection_share quantity price composition_collection_share
			// recording_collection composition_collection

			if (this.checkPattern([
				'quantity',
				'price',
				'recording_collection_share',
				'recording_owner_share',
				'composition_collection_share',
				'composition_owner_share'
			], bindings)) {
				patterns.push('pattern 1');
			}
			if (this.checkPattern([
				'quantity',
				'price',
				'recording_collection_share',
				'composition_collection_share'
			], bindings)) {
				patterns.push('pattern 2');
			}

			if (this.checkPattern(['recording_collection', 'composition_collection'], bindings)) {
				patterns.push('pattern 3');
			}
		}
		// console.log(bindings, patterns);
		return patterns;
	}

	//report.err.collection_properties_missing

	render() {
		const {selected, selectedBinding, data, patterns, trySubmit} = this.state;
		const {} = this.props;
		if (!data) {
			return <Loading/>;
		}

		if (this.props.data.status === false) {
			return (
				<div className={styles.error}>
					<i className="fa fa-exclamation-triangle"/>
					<FormattedMessage {...getMessage(this.props.data.message || this.props.data.error)}/>
				</div>
			);
		}

		if (data.rows.length === 0) {
			return (
				<div className={styles.error}><i className="fa fa-exclamation-triangle" aria-hidden="true"/>
					<FormattedMessage {...getMessage('report.msg.no_data_to_show')}/></div>
			);
		}

		const header = (
			<Row>
				<Cell className={styles.headerCell}/>
				{data.header.map((item, index) => (
					<Cell
						onClick={this.onCellClick(index)}
						className={classnames(styles.headerCell, {
							[styles.selected]: selected === index,
							[styles.binded]: data.cols[index].binding
						})}
					>
						{data.cols[index].binding ?
							<div className={styles.header}>{data.cols[index].binding.title}</div> :
							<div className={styles.headerNotLinked}>
								<FormattedMessage {...getMessage('report.column.undefined_column')}/></div>
						}
					</Cell>
				))}
			</Row>
		);

		const subHeader = (
			<Row>
				<Cell className={classnames(styles.left)}/>
				{data.subHeader.map((item, index) => (
					<Cell
						className={classnames(styles.subHeader, {[styles.subHeaderSelected]: selected === index})}
						onClick={this.onCellClick(index)}
					>
						<div className={styles.subHeaderContent}>
							<span>{item}</span>
						</div>
					</Cell>
				))}
			</Row>
		);

		const body = data.rows.map((row, rowIndex) => (
			<Row>
				<Cell className={classnames(styles.left, {[styles.deletedRow]: row.ignored})}>
					<div className={styles.leftColumn}>
						<a className={styles.removeButton} onClick={this.deleteRow(rowIndex)}>
							<i className="fa fa-ban" aria-hidden="true"/>
						</a>
						<div>{rowIndex + 1}</div>
					</div>
				</Cell>

				{row.data.map((item, index) => (
					<Cell
						onClick={this.onCellClick(index)}
						className={classnames(styles.cell, {
							[styles.selected]: selected === index,
							[styles.binded]: data.cols[index].binding,
							[styles.deletedRow]: row.ignored,
							[styles.right]: isFinite(item),
						})}
					>
						<div>{item}</div>
					</Cell>
				))}
			</Row>
		));

		return (
			<div ref={this.table} className={styles.container}>
				<div className={styles.scroll}>
					<StickyTable stickyColumnCount={1} stickyHeaderCount={2}>
						{header}
						{subHeader}
						{body}
					</StickyTable>
				</div>
				<div>
					<div className={classnames(styles.errors, {show: trySubmit && patterns.length === 0})}>
						<div className="icon">
							<i className="fa fa-exclamation-triangle" aria-hidden="true"/>
						</div>
						<div className="text">
							<FormattedMessage {...messages['report.err.collection_properties_missing']}/>
						</div>

					</div>
					<div className={styles.controls}>
						<div>
							<div className={styles.filter}>
								<div>
									<input type="radio"
										   id="dontimport"
										   disabled={!selectedBinding}
										   checked={!selectedBinding}
										   onChange={this.clearBinding}
									/>
								</div>
								<label htmlFor={'dontimport'}><FormattedMessage{...messages['report.action.no-import']}/></label>
							</div>
						</div>

						{data.sortedGroups.map((group) => (
							<React.Fragment>
								<h2><FormattedMessage{...messages[`report.title.${group}_properties`]}/></h2>
								<div className="items">
									{data.groups[group].map((item) => (
										<div className={styles.filter}>
											<div>
												<input type="radio"
													   disabled={!(!item.used)}
													   checked={selectedBinding && selectedBinding.id === item.id}
													   onChange={this.setBinding(item.id)}
													   id={`item_${item.id}`}
												/>
											</div>
											<label htmlFor={`item_${item.id}`}
												   className={`${styles.label} ${(!(!item.used)) ? styles.disabled : ''}`}
											>{item.title}</label>
										</div>
									))}
								</div>
							</React.Fragment>

						))}
					</div>
				</div>
				<input type="hidden" name="bindings" value={this.getBindings()}/>
			</div>
		);
	}
}

const getTableDataRequest = (params) => Observable.from(API.Reports.getReportsTableData(params));
const ignoreRows = (params) => Observable.from(API.Reports.ignoreRows(params));

const contextProvider = props => (
	<LanguageContext.Consumer>
		{context => <ReportTable {...props} context={context}/>}
	</LanguageContext.Consumer>
);

export default rxConnect((props$) => {
	const actions = {
		getData$: new Subject(),
		ignoreRows$: new Subject(),
	};

	const tableData$ = actions.getData$
		.debounceTime(500)
		.pluck(0)
		.switchMap((params) => getTableDataRequest(params));

	const ignoreResp$ = actions.ignoreRows$
		.debounceTime(500)
		.pluck(0)
		.switchMap((params) => ignoreRows(params));

	return Observable.merge(
		mapActionCreators(actions),
		props$,
		tableData$.map(data => {
			return {data};
		}),
		ignoreResp$.map(resp => {
			return {resp};
		})
	);
})(contextProvider);


