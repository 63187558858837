import {decamelizeKeys} from 'humps';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Observable, Subject} from 'rxjs';
import {mapActionCreators, rxConnect} from "rx-connect";
import React, {Component} from "react";
import styled from 'styled-components';
import {Filters} from './Filters';
import {Grid, Wrapper} from './Grid';
import Helmet from "./Helmet";
import {RightHolders} from './RightHolders';
import {getLicensee, getLicensors, separateAccounts} from './helpers';
import * as API from "../../services";

const StyledPage = styled.div`
		font-family: SegoeWPRegular, Arial, sans-serif;
		font-size: 11px;
		background-color: white;
	
		
		.error-msg {
			margin: 10px 0px;
			padding:12px;
		   	color: #D8000C;
    		background-color: #FFD2D2;
    		& > i {
    			margin:10px 22px;
    			font-size:2em;
    			vertical-align:middle;
    		}
		}
		
		*:focus {
				    outline: -webkit-focus-ring-color auto 0px;
			}
`;
StyledPage.displayName = 'Styled.Page';

class Splitsheet extends Component {

    state = {
        data: {
            countryId: 1,
            exploitationTypeId: 3,
            productId: 0,
            outletId: 0,
            rightId: 12,
            useTypeId: 0,
            userInterfaceTypeId: 0,
            commercialModelId: 0,
            distributionChannelId: 0,
            marketingChannelId: 0,
            societyId: 25555,
            reportDate: null,
            begins: moment(),
        },
        tags: {
            outlet: [],
            product: [],
            right: [],
            useType: [],
            userInterfaceType: [],
            commercialModel: [],
            distributionChannel: [],
            marketingChannel: [],
        },
        // id: 2112,
        share: {},
        selected: null,
        licensees: {},
        original: {},
        highlighted: {},
        error: null,
    };

    onFiltersChange = (field, value) => {
        this.setState({...this.state, data: {...this.state.data, [field]: value}});
        if (field === 'begins' || field === 'reportDate') {
            this.update({
                id: this.props.account,
                params: {...this.getFilters(), ...decamelizeKeys({[field]: moment(value).isValid() ? moment(value).format('DD.MM.YYYY') : ''})}
            });

        } else {
            this.update({
                id: this.props.account,
                params: {...this.getFilters(), ...decamelizeKeys({[field]: value})}
            });
        }
    };

    update = (data) => {
        if (!data) {
            this.props.getData({id: this.props.account, params: this.getFilters()});
            this.props.getTags({id: this.props.account, params: this.getFilters()});
        } else {
            this.props.getData(data);
            this.props.getTags(data);
        }
    };
    onSelectField = (id) => {
        // const {share} = this.state;
        this.setState({selected: id});
        if (id) {
            this.props.getRevenue({id: this.props.account, licensor: id, params: this.getFilters()});
        }
    };

    componentDidMount() {
        this.update();
    }

    // onReload = () =>{
    // 	this.update();
    // };

    getFilters() {
        const begins = moment(this.state.data.begins).isValid() ? moment(this.state.data.begins).format('DD.MM.YYYY') : '';
        const reportDate = this.state.data.reportDate && moment(this.state.data.reportDate).isValid() ? moment(this.state.data.reportDate).format('DD.MM.YYYY') : '';
        return decamelizeKeys({
            ...this.state.data,
            begins,
            reportDate,
        });
    }

    // getChildContext() {
    //     return {intl: this.props.intl};
    // }

    render() {
        const {selected, errorRes} = this.state;
        const {licensees, original, revenues, loaded = {}, error, societies, applied} = this.props;
        const tags = {...this.props.tags, societies: {items: societies, recommended: []}};
        return (
            <StyledPage>
                <Wrapper>
                    <Grid style={{maxWidth: 960}}>
                        <Helmet title="Page"/>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{minWidth: 780}}>
                                {error && <div className="error-msg">
                                    <i className="fa fa-times-circle"/>"{error.message}"
                                </div>}
                                {loaded.data && !error && <RightHolders
                                    licensees={licensees} original={original} tags={tags} applied={applied}
                                    onSelect={this.onSelectField} selected={selected} share={revenues}
                                />}
                            </div>
                            <div>
                                {loaded.refs &&
                                <Filters tags={tags} data={this.state.data}
                                         onChange={this.onFiltersChange}/>
                                }
                            </div>
                        </div>
                    </Grid>
                </Wrapper>
            </StyledPage>
        );

    }
}

Splitsheet.defaultProps = {
    account: 0,
};

Splitsheet.propTypes = {
    // intl: intlShape.isRequired,
    getTags: PropTypes.func,
    getData: PropTypes.func,
    getRevenue: PropTypes.func,
    // updateField: PropTypes.func,
    // selectField: PropTypes.func,
    state: PropTypes.object,
    account: PropTypes.string,
};

const fetchDataRequest = (params) => Observable.from(API.Splitsheet.fetchData({...params}, {ttl: 5 * 60 * 1000}));
const fetchRevenueRequest = (params) => Observable.from(API.Splitsheet.fetchRevenue({...params}, {ttl: 5 * 60 * 1000}));
const fetchTagsRequest = (params) => Observable.from(API.Splitsheet.fetchTags({...params}, {ttl: 5 * 60 * 1000}));

export default rxConnect((props$) => {
    const revenues = {};
    const loaded = {
        refs: false,
        data: false,
    };
    let accounts = {};
    let tags = {};


    const actions = {
        getData$: new Subject(),
        getRevenue$: new Subject(),
        getTags$: new Subject(),
    };

    const data$ = actions.getData$
        .debounceTime(500)
        .pluck(0)
        .switchMap((params) => fetchDataRequest(params));

    const revenue$ = actions.getRevenue$
    // .debounceTime(500)
        .pluck(0)
        .switchMap((params) => fetchRevenueRequest(params));

    const tags$ = actions.getTags$
        .debounceTime(500)
        .pluck(0)
        .switchMap((params) => fetchTagsRequest(params));


    return Observable.merge(
        mapActionCreators(actions),
        props$,
        data$.map(resp => {
            if (resp.error) {
                loaded.data = false;
                return {error: resp.error, loaded};
            } else {
                const data = resp.splitsheet;
                const societies = resp.societies;
                const applied = resp.applied;
                accounts = separateAccounts(Object.keys(data)
                    .map(key => {
                        const allLicensors = getLicensors(data, data[key]);
                        const allLicensee = getLicensee(data, data[key]);
                        const weight = allLicensee.reduce((acc, licensee) => {
                            return acc + getLicensors(data, data[licensee]).length;
                        }, 0);
                        return {
                            ...data[key],
                            allLicensors,
                            allLicensee,
                            weight,
                        }
                    }));
                loaded.data = true;
                return {...accounts, loaded, error: null, societies, applied};
            }
        }),
        revenue$.map(resp => {
            const share = {
                collection: resp.collection,
                ownership: resp.ownership,
                revenue: resp.revenue,
            };

            if (accounts.licensees.hasOwnProperty(resp.licensor)) {
                accounts.licensees[resp.licensor] = {
                    ...accounts.licensees[resp.licensor],
                    share,
                }
            }

            if (accounts.original.hasOwnProperty(resp.licensor)) {
                accounts.original[resp.licensor] = {
                    ...accounts.original[resp.licensor],
                    share,
                }
            }

            revenues[resp.licensor] = share;
            return {...accounts, revenues, loaded, error: null};
        }),
        tags$.map(resp => {

            if (resp.error) {
                loaded.data = false;
                return {tags, error: resp.error, loaded};
            } else {
                loaded.refs = true;
                tags = resp;
                return {tags: resp, loaded, error: null}
            }
        }),
    )
})(Splitsheet)
