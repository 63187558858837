import PropTypes from 'prop-types';
import React, {Component} from "react";
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Container = styled.div`
		position: relative;
		&>input{
			width: 100%;
			padding: 8px 25px 8px 10px;
			border-radius: 0;
			border: 0;
			border-bottom: 1px solid #eee;
			box-sizing: border-box;
		}
		& button {
			position: absolute;
			cursor: pointer;
			top: 0;
			right: 0;
			bottom: 0;
			border: 0;
			background-color: transparent;
			font-size: 16px;
			color: #666;
			transition: color .4s;
			&:hover {
				color: #000;
				transition: none;
			}
		}			
`;

export class Search extends Component {
    focusInput = (component) => {
        if (component) {
            ReactDOM.findDOMNode(component).focus();
        }
    };
    onChange = (event) => {
        this.props.onChange(event.currentTarget.value);
    };

    constructor() {
        super();
        this.state = {
            isPopupOpen: false,
        }
    }

    render() {
        const {value} = this.props;
        return (
            <Container>
                <input ref={this.focusInput} onChange={this.onChange} value={value}/>
                <button><i className="fa fa-search"/></button>
            </Container>
        );
    }
}

Search.defaultProps = {
    onChange: () => {
    },
    value: '',
};

Search.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
};

export default Search;
