import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';

import GlobalSearchGroup from '../GlobalSearchGroup';

class GlobalSearchResults extends PureComponent {
    state = {
        selected: -1,
    };
    onCategoryClick = (category) => () => {
        this.props.onCategoryClick(category);
    };
    onMouseOver = (event) => {
        [].forEach.call(this.items, (el) => {
            el.classList.remove('_active');
        });
        event.currentTarget.classList.add('_active');
        event.stopPropagation();
    };
    onMouseOut = (event) => {
        let {selected} = this.state;

        [].forEach.call(this.items, (el, index) => {
            if (el.classList.contains('_active')) {
                selected = index;
            }
        });

        [].forEach.call(this.items, (el) => {
            el.classList.remove('_active');
        });

        this.setState({selected});
        event.stopPropagation();
    };
    onKeyPress = (event) => {
        let selected = this.state.selected;
        switch (event.key) {
            case 'Enter':
                if (selected !== -1) {
                    [].forEach.call(this.items, (el) => {
                        if (el.classList.contains('_active')) {
                            const link = el.querySelector('a');
                            if (link) {
                                window.location.href = link.getAttribute('href');
                            } else {
                                this.props.onCategoryClick(el.getAttribute('data-category'))
                            }

                        }
                    });
                }
                break;

            case 'ArrowUp':
                if (this.items.length > 0) {
                    selected--;
                    if (selected < 0) {
                        selected = this.items.length - 1;
                    }
                    event.preventDefault();
                    this.selectItem(selected);
                }
                break;

            case 'ArrowDown':
                if (this.items.length > 0) {
                    selected++;
                    if (selected > this.items.length - 1) {
                        selected = 0;
                    }
                    event.preventDefault();
                    this.selectItem(selected);
                }
                break;
        }
    };

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyPress);
        this.updateSelection();
    }


    componentDidUpdate() {
        // if (this.props.groups.length > 0) {
        //     //this.refs.results.scrollTo(0, 0);
        // }
        this.updateSelection();
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyPress);
    }

    selectItem(selected) {
        this.setState({selected});
        if (this.state.selected !== -1) {
            this.items[this.state.selected].scrollIntoViewIfNeeded();
        }
        this.updateSelection();
    }

    updateSelection() {
        if (this.props.groups.length !== 0) {
            // this.refs.results.scrollTo(0, 0);
            this.items = this.refs.results.querySelectorAll('._selectable');

            ([]).forEach.call(this.items, function (el) {
                el.classList.remove('_active');
            });

            if (this.state.selected >= 0) {
                const item = this.items[this.state.selected];
                item && item.classList.add('_active');
            }
        }
    }

    render() {
        const {groups, show} = this.props;
        return (
            <div ref={'results'} className={styles['search-results']}>
                {groups.map((group) => <GlobalSearchGroup key={group.keyword} title={group.keyword}
                                                          keyword={group.keyword}
                                                          items={group.items}
                                                          total={+group.total}
                                                          moreUrl={group.url}
                                                          onCategoryClick={this.onCategoryClick(group.keyword)}
                                                          onMouseOver={this.onMouseOver}
                                                          onMouseOut={this.onMouseOut}
                />)}
            </div>
        );
    }
}

GlobalSearchResults.propTypes = {
    onCategoryClick: PropTypes.func,
    groups: PropTypes.array,
    show: PropTypes.bool,

};
GlobalSearchResults.defaultProps = {
    onCategoryClick: () => {
    },
    groups: [],
    show: false,
};

export default GlobalSearchResults;
