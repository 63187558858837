import PropTypes from 'prop-types';
import React, {PureComponent} from "react";
import CountUp from 'react-countup';

export class AnimatedCounter extends PureComponent {
    componentDidMount() {
        this.prevValue = 0;
    }

    componentWillUpdate() {
        this.prevValue = this.props.value;
    }

    render() {
        const {value, suffix} = this.props;
        if (value === 0 || isNaN(value)) {
            return null;
        }
        return (
            <CountUp
                className="count-animation"
                start={+this.prevValue || 0}
                end={+value || 0}
                duration={.5}
                useEasing={true}
                useGrouping={false}
                separator=" "
                decimals={value % 1 === 0 ? 0 : 2}
                decimal=","
                suffix={suffix}
            />
        );
    }
}

AnimatedCounter.defaultProps = {
    value: 0,
    suffix: '%'
};

AnimatedCounter.propTypes = {
    value: PropTypes.number,
    suffix: PropTypes.string,
};

export default AnimatedCounter;
