import {camelizeKeys} from "humps";
import Api from "./Api";

export default class Accounts {
    static findAccount(params) {
        const {searchText, exceptIds = ''} = params;
        let req = `lookup/supplier?except_ids=${exceptIds}&search=${searchText}`;
        if (window.devMode) {
            if (searchText) {
                return new Promise((res) => res(
                    [{
                        "id": "2259",
                        "title": "Sebastian Barac",
                        "aliases": [{"id": "19419", "title": "Barac Sebastian"}, {
                            "id": "209070",
                            "title": "BARAC SEBASTIAN ALEXANDRU"
                        }, {"id": "32556", "title": "BARAC, SEBASTIAN"}, {
                            "id": "31926",
                            "title": "BARAC, SEBASTIAN ALEXANDRU"
                        }, {"id": "203657", "title": "S.A. Barac"}],
                        "pseudonyms": [],
                        "scopes": [{"id": "1", "title": "продюсер"}, {"id": "5", "title": "автор слов"}, {
                            "id": "6",
                            "title": "автор музыки"
                        }, {"id": "8", "title": "вокалист"}]
                    }, {
                        "id": "38",
                        "title": "Arash Alex Rahim Labaf Zadeh",
                        "aliases": [{"id": "9126", "title": "ARASH"}, {
                            "id": "209352",
                            "title": "ARASH LABAF"
                        }, {"id": "215798", "title": "ARASH;"}, {
                            "id": "9088",
                            "title": "RAHIM LABAF ZADEH ALEX ARASH"
                        }],
                        "pseudonyms": [{"id": "207", "title": "Arash"}],
                        "scopes": [{"id": "1", "title": "продюсер"}, {"id": "5", "title": "автор слов"}, {
                            "id": "6",
                            "title": "автор музыки"
                        }, {"id": "8", "title": "вокалист"}]
                    }, {
                        "id": "502",
                        "title": "Antoine Clamaran",
                        "aliases": [{"id": "18159", "title": "A CLAMARAN"}, {
                            "id": "18150",
                            "title": "A. CLAMARAN"
                        }, {"id": "15903", "title": "CLAMARAN ANTOINE"}, {
                            "id": "16294",
                            "title": "CLAMARAN ANTOINE(AR)"
                        }],
                        "pseudonyms": [],
                        "scopes": [{"id": "5", "title": "автор слов"}, {"id": "6", "title": "автор музыки"}, {
                            "id": "8",
                            "title": "вокалист"
                        }]
                    }, {
                        "id": "19307",
                        "title": "Burt Freeman Bacharach",
                        "aliases": [{"id": "441722", "title": "B. Bacharach"}, {
                            "id": "36948",
                            "title": "Bacharach, Burt F."
                        }, {"id": "262425", "title": "BURT BACHARACH"}, {
                            "id": "276328",
                            "title": "BURT F BACHARACH"
                        }, {"id": "534429", "title": "BURT F. BACHARACH"}],
                        "pseudonyms": [],
                        "scopes": [{"id": "6", "title": "автор музыки"}]
                    }, {
                        "id": "53753",
                        "title": "Ilaiya Raaja",
                        "aliases": null,
                        "pseudonyms": [],
                        "scopes": [{"id": "5", "title": "автор слов"}, {"id": "6", "title": "автор музыки"}]
                    }, {
                        "id": "2356",
                        "title": "Miguel Lara",
                        "aliases": null,
                        "pseudonyms": [],
                        "scopes": [{"id": "9", "title": "инструменталист"}]
                    }, {
                        "id": "3342",
                        "title": "Sarah",
                        "aliases": null,
                        "pseudonyms": [],
                        "scopes": [{"id": "8", "title": "вокалист"}]
                    }]
                ))
            } else {
                return new Promise((res) => res([]));
            }
        }

        if (searchText) {
            return Api.get(req)
                .then((json) => {
                    return camelizeKeys(json)
                })
        } else {
            return new Promise((res) => res([]));
        }
    }
}
