import React, {Component} from 'react';
import PropTypes from 'prop-types';

import styles from './index.css';

class DiffMergedSection extends Component {

    onEditTitle = () => {
        this.setState({editTitle: true})
    };
    onEditCode = () => {
        this.setState({editCode: true})
    };
    onEditDate = () => {
        this.setState({editDate: true})
    };
    onEditShare = (editShareIndex) => () => {
        this.setState({editShareIndex})
    };
    changeTitle = () => {

    }
    onRowOver = (index, role) => (event) => {
        this.props.actions.onHighligh(index, role);
        event.stopPropagation();
    };

    constructor(props) {
        super();
        this.title = React.createRef();
        this.code = React.createRef();
        this.date = React.createRef();
        this.state = {
            editTitle: false,
            editCode: false,
            editDate: false,
            editShareIndex: -1,

        }
    }

    componentDidMount() {
        this.title.current.value = this.props.data.title;
        this.code.current.value = this.props.data.code;
        this.date.current.value = this.props.data.date;

    }

    render() {
        const {title, url, data, hover} = this.props;
        let blockTitle = (<h2 className={styles.Title}>{title}</h2>);

        if (url) {
            blockTitle = (<h2 className={styles.Title}><a href={url}>{title}</a></h2>);
        }

        let blockInfo = (
            <div className={styles.BlockInfo}>
                <dl>
                    <dt>Title</dt>
                    <dd className={`${styles.Field} ${this.state.editTitle ? 'edit' : ''}`}>
                        <input ref={this.title}/>
                        <span>{data.title ? data.title : '-'}</span>
                        <button className={styles.Button} onClick={this.onEditTitle}><i className={'fa fa-edit'}/>
                        </button>
                    </dd>
                </dl>
                <dl>
                    <dt>ISWC</dt>
                    <dd className={`${styles.Field} ${this.state.editCode ? 'edit' : ''}`}>
                        <input ref={this.code}/>
                        <span>{data.code ? data.code : '-'}</span>
                        <button className={styles.Button} onClick={this.onEditCode}><i className={'fa fa-edit'}/>
                        </button>
                    </dd>
                </dl>
                <dl>
                    <dt>Date</dt>
                    <dd className={`${styles.Field} ${this.state.editDate ? 'edit' : ''}`}>
                        <input ref={this.date}/>
                        <span>{data.date ? data.date : '-'}</span>
                        <button className={styles.Button} onClick={this.onEditDate}><i className={'fa fa-edit'}/>
                        </button>
                    </dd>
                </dl>
            </div>
        );

        let blockTable = (
            <table className={styles.Table} cellSpacing="0">
                <colgroup>
                    <col/>
                    <col/>
                </colgroup>
                <thead>
                <tr>
                    <th className="rightholder">rightholder</th>
                    <th className="share">share</th>
                </tr>
                </thead>
                <tbody onMouseOut={this.onRowOver(-1)} className={'merged'}>
                {data.rightholders.map((rh, index) => {
                    if (!rh) return (
                        <tr className={hover.index === index ? 'hovered' : ''} onMouseOver={this.onRowOver(index)}>
                            <td/>
                            <td/>
                        </tr>);

                    return (
                        <tr className={`${rh.patch ? rh.patch.mode : ''}${hover.index === index ? ' hovered' : ''}`}
                            onMouseOver={this.onRowOver(index)}>
                            <td>
                                <div style={{float: 'left'}}>
                                    <span className="title">{rh.title}</span><br/>
                                    <span className={styles.Role}>{rh.roles.map(role => {
                                        return (
                                            <span>
                                                <span
                                                    className={hover.role === role.code && hover.index === index ? 'hovered' : ''}
                                                    onMouseOverCapture={this.onRowOver(index, role.code)}
                                                >
                                                {role.title}
                                                </span>
                                                <span className="comma">,</span>
                                            </span>
                                        )
                                    })}</span>
                                </div>
                                <div style={{float: 'right'}}>
                                    <button className={styles.Button} style={{marginTop: 10}}><i
                                        className={'fa fa-times'}/></button>
                                </div>
                            </td>
                            <td className={`${styles.Field} ${this.state.editShareIndex === index ? 'edit' : ''} share`}>
                                <input/>
                                <div style={{float: 'left'}}>
                                    <span> {parseInt(rh.share)}%</span>
                                </div>
                                <div style={{float: 'right'}}>
                                    <button className={styles.Button} onClick={this.onEditShare(index)}><i
                                        className={'fa fa-edit'}/></button>
                                </div>
                            </td>
                        </tr>)
                })}
                </tbody>
            </table>
        );
        return (
            <div className={styles.DiffSectionMerged}>
                {blockTitle}
                {blockInfo}
                {blockTable}
            </div>
        )
    }
}

DiffMergedSection.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    data: PropTypes.object,
    hover: PropTypes.object,
    actions: PropTypes.object.isRequired,
};

DiffMergedSection.defaultProps = {
    title: 'Section',
};

export default DiffMergedSection;
