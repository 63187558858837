import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';

export default class ScrollingTables extends Component {

    // constructor() {
    //     super();
    //     this.wrapper = React.createRef();
    // }
    //
    // componentDidMount() {
    //     window.addEventListener('scroll', this.onScrollWindow);
    //     this.wrapper.current.addEventListener('scroll', this.onScrollWrapper);
    //     this.top = this.table.current.getBoundingClientRect().y
    // }
    //
    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.onScrollWindow);
    //     this.wrapper.current.removeEventListener('scroll', this.onScrollWrapper);
    // }
    //
    // onScrollWindow = () => {
    //     const offset = this.table.current.offsetTop;
    //     if (window.pageYOffset > offset) {
    //         this.setState({sticky: true})
    //     } else {
    //         this.setState({sticky: false})
    //     }
    // };
    //
    onScrollWrapper = (event) => {
        const tables = document.querySelectorAll(`.${styles.TableWrap}`);
        const wrapperOffset = event.currentTarget.scrollLeft;
        // this.wrapperCentral.current.style.transform = `translateX(-${wrapperOffset}px)`
        console.log('log', tables, wrapperOffset);
        tables.forEach((t) => {
            if (t !== event.currentTarget) {
                t.scrollTo(wrapperOffset, 0);
            }
        })
    };

    render() {
        const {data} = this.props;
        // let leftHeader = (
        //     <div className={styles.StickyHeader} style={{display: this.state.sticky? 'flex': 'none'}}>
        //         <table>
        //             {left.header}
        //         </table>
        //         <div style={{overflow: 'hidden'}}>
        //             <table ref={this.wrapperCentral}>
        //                 {central.header}
        //             </table>
        //         </div>
        //     </div>
        // );


        return data.map((d) => {
            const {left, central, right} = d;
            return (

                <div>
                    {left.data.info &&
                    <div className={styles.InfoRow}>
                        <div className={styles.InfoRowData}>
                            <div style={{display: 'flex'}}>
                                {data.info}
                                <div>
                                    <a href="/backend/claimer/itunes/tracks/shares/10561488"
                                       className="list-item-title">Stand By Me Now (feat. Kris
                                        Kid)</a><br/>Playmen
                                </div>
                                <div>Alex Nikolaos Leontaritis<br/>Christos Mastoras<br/>LEFTHERIS
                                    XENAKIS
                                </div>
                                <div>Pavlos Manolis</div>
                            </div>
                        </div>
                    </div>}
                    <div className={styles.TableScroll}>
                        <table style={{width: 30}}>
                            {left.header}
                            {left.data.body}
                            {left.footer}
                        </table>
                        {central && <div className={styles.TableWrap} onScroll={this.onScrollWrapper}>
                            <table ref={this.table}>
                                {central.header}
                                {central.data.body}
                                {central.footer}
                            </table>

                        </div>}
                        {right && <table>
                            {right.header}
                            {right.data.body}
                            {right.footer}
                        </table>}
                    </div>
                </div>
            )
        });
    }
}

ScrollingTables.propTypes = {

    left: PropTypes.shape({
        header: PropTypes.node,
        body: PropTypes.node,
        footer: PropTypes.node,
    }),
    right: PropTypes.shape({
        header: PropTypes.node,
        body: PropTypes.node,
        footer: PropTypes.node,
    }),

    central: PropTypes.shape({
        header: PropTypes.node,
        body: PropTypes.node,
        footer: PropTypes.node,
    }),
};

ScrollingTables.defaultProps = {};
