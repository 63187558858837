import 'core-js/es6/map';
import 'core-js/es6/set';
import 'url-polyfill';
import 'raf/polyfill';
import 'nodelist-foreach-polyfill';
import 'ie-array-find-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
// import {IntlProvider} from 'react-intl';
import './index.css';
import GlobalSearch from './modules/GlobalSearch';
import LanguageProvider from './modules/LanguageProvider';
import ClaimerLicensors from './modules/Diagrams/ClaimerLicensors';
import ClaimerLicensorsPeriod from './modules/Diagrams/ClaimerLicensorsPeriod';
import Splitsheet from './modules/Splitsheet';
import DiffViewer from './modules/DiffViewer';
import DistributionPendingTable from './modules/DistributionPendingTable';
import RightholdersSharesTable from './modules/RightholdersSharesTable';
import Modal from './modules/Modal';
import ReportTable from "./modules/ReportTable";
import ImportTable from "./modules/ImportTable";
// import registerServiceWorker from './registerServiceWorker';
// registerServiceWorker();

window.addEventListener('load', () => {
    window.H11App = {
        config: {
            bodyWrapper: document.querySelector('.wrapper'),
            globalSearchOpenButton: document.querySelector('#globalSearchIconToggle'),
            claimerLicensorsDiagram: document.querySelector('diagram[claimer-licensors]'),
            claimerLicensorsPeriodDiagram: document.querySelector('diagram[claimer-licensors-period]'),
            splitsheets: document.querySelectorAll('splitsheet'),
            diff: document.querySelector('diff'),
			reportTable: document.querySelector('report-table'),
			importTable: document.querySelector('import-table'),
            distributionPending: document.querySelector('distr-pending'),
            rightHoldersSharesTableRecording: document.querySelector('recording-rightholders-shares-table'),
            rightHoldersSharesTableComposition: document.querySelector('composition-rightholders-shares-table'),
        },
        root: {
            appRoot: document.getElementById('app-root'),
            modalRoot: document.getElementById('modal-root'),
        },
        components: {
            modal: {
                // draw: drawModal,
                // unmount:unmountModal,
                // open: undefined,
                // close: undefined,
            }
        },
    };
});

(function GlobalSearch() {
    function mountGlobalSearch() {
        ReactDOM.render(<Modal onClose={unmountGlobalSearch}><GlobalSearch/></Modal>, window.H11App.root.appRoot);
        window.H11App.config.bodyWrapper.classList.add('blur');
        window.H11App.root.modalRoot.querySelector('.Modal input').focus();
    }

    function unmountGlobalSearch() {
        ReactDOM.unmountComponentAtNode(window.H11App.root.appRoot);
        window.H11App.config.bodyWrapper.classList.remove('blur');
    }

    window.addEventListener('load', () => {
        //GlobalSearch
        if (window.H11App.config && window.H11App.config.globalSearchOpenButton) {
            window.H11App.config.globalSearchOpenButton.onclick = () => {
                mountGlobalSearch();
            }
        }
    });
})();


(function Diagrams() {
    window.addEventListener('load', () => {

        if (window.H11App.config && window.H11App.config.claimerLicensorsPeriodDiagram) {
            const data = JSON.parse(window.H11App.config.claimerLicensorsPeriodDiagram.dataset.diagram);
            const fields = JSON.parse(window.H11App.config.claimerLicensorsPeriodDiagram.dataset.fields);
            const messages = JSON.parse(window.H11App.config.claimerLicensorsPeriodDiagram.dataset.messages);
            const urls = JSON.parse(window.H11App.config.claimerLicensorsPeriodDiagram.dataset.urls);
            const byMonths = window.H11App.config.claimerLicensorsPeriodDiagram.dataset.bymonths === "true";
            ReactDOM.render(<ClaimerLicensorsPeriod
                fields={fields}
                messages={messages}
                urls={urls}
                data={data}
                byMonths={byMonths}
            />, window.H11App.config.claimerLicensorsPeriodDiagram);
        }

        if (window.H11App.config && window.H11App.config.claimerLicensorsDiagram) {
            const data = JSON.parse(window.H11App.config.claimerLicensorsDiagram.dataset.diagram);
            const fields = JSON.parse(window.H11App.config.claimerLicensorsDiagram.dataset.fields);
            const messages = JSON.parse(window.H11App.config.claimerLicensorsDiagram.dataset.messages);
            const urls = JSON.parse(window.H11App.config.claimerLicensorsDiagram.dataset.urls);

            ReactDOM.render(<ClaimerLicensors
                fields={fields}
                messages={messages}
                urls={urls}
                data={data}
            />, window.H11App.config.claimerLicensorsDiagram);
        }
    });
})();

(function Splitsheet() {
    window.addEventListener('load', () => {
        if (window.H11App.config && window.H11App.config.splitsheets.length > 0) {
            window.H11App.config.splitsheets.forEach((node) => {
                const accountId = node.dataset.account;
                ReactDOM.render(
                    <LanguageProvider locale="en">
                        <Splitsheet account={accountId}/>
                    </LanguageProvider>
                    , node);
                // ReactDOM.render(<div>{accountId}</div>, node);

            })

        }

    });
})();

(function Diff() {
    window.addEventListener('load', () => {
        if (window.H11App.config && window.H11App.config.diff) {
            const resourceId = window.H11App.config.diff.dataset.resource;
            const catalogId = window.H11App.config.diff.dataset.catalog;
            const catalogResourceId = window.H11App.config.diff.dataset.catalogresource;
            ReactDOM.render(
                <LanguageProvider locale="en">
                    <DiffViewer resourceId={resourceId} catalogId={catalogId} catalogResourceId={catalogResourceId}/>
                </LanguageProvider>
                , window.H11App.config.diff);
        }
    });
})();

(function Distribution() {
    window.addEventListener('load', () => {
        if (window.H11App.config && window.H11App.config.distributionPending) {
            ReactDOM.render(
                <LanguageProvider locale="en">
                    <DistributionPendingTable/>
                </LanguageProvider>
                , window.H11App.config.distributionPending);
        }
    });
})();
(function ReportTable() {
	window.addEventListener('load', () => {
		if (window.H11App.config && window.H11App.config.reportTable) {
			const id = window.H11App.config.reportTable.dataset.id;
			const type = window.H11App.config.reportTable.dataset.type;
			const locale = window.H11App.config.reportTable.dataset.locale || 'en';
			ReactDOM.render(
				<LanguageProvider locale={locale}>
					<ReportTable id={id} reportType={type}/>
				</LanguageProvider>
				, window.H11App.config.reportTable);
		}
	});
})();

(function ImportTable() {
	window.addEventListener('load', () => {
		if (window.H11App.config && window.H11App.config.importTable) {
			const id = window.H11App.config.importTable.dataset.id;
			const locale = window.H11App.config.importTable.dataset.locale || 'en';
			ReactDOM.render(
				<LanguageProvider locale={locale}>
					<ImportTable id={id}/>
				</LanguageProvider>
				, window.H11App.config.importTable);
		}
	});
})();

(function RightHoldersShares() {
    window.addEventListener('load', () => {
        if (window.H11App.config && window.H11App.config.rightHoldersSharesTableRecording) {
            const {dataset} = window.H11App.config.rightHoldersSharesTableRecording;
            const initData = dataset.init ? JSON.parse(dataset.init) : null;
            const locale = window.H11App.config.rightHoldersSharesTableRecording.dataset.locale || 'en';
            ReactDOM.render(
                <LanguageProvider locale={locale}>
                    <RightholdersSharesTable initialState={initData} type={'recording'}/>
                </LanguageProvider>
                , window.H11App.config.rightHoldersSharesTableRecording);
        }
        if (window.H11App.config && window.H11App.config.rightHoldersSharesTableComposition) {
            const {dataset} = window.H11App.config.rightHoldersSharesTableComposition;
            const initData = dataset.init ? JSON.parse(dataset.init) : null;
            const locale = window.H11App.config.rightHoldersSharesTableComposition.dataset.locale || 'en';
            ReactDOM.render(
                <LanguageProvider locale={locale}>
                    <RightholdersSharesTable initialState={initData} type={'composition'}/>
                </LanguageProvider>
                , window.H11App.config.rightHoldersSharesTableComposition);
        }
    });
})();
