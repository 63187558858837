import classnames from 'classnames';
import {LanguageContext} from 'modules/LanguageProvider';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Cell, Row, StickyTable} from 'react-sticky-table';
import 'react-sticky-table/dist/react-sticky-table.css';
import {mapActionCreators, rxConnect} from 'rx-connect';
import {Observable, Subject} from 'rxjs';
import Loading from '../../components/Loading';
import * as API from '../../services';
import {getMessage, default as messages} from './../../messages';
import styles from './ReportTable.module.css';

//todo

class ImportTable extends Component {
	static propTypes = {
		data: PropTypes.object,
		id: PropTypes.string,
		intl: PropTypes.object,
		getData: PropTypes.func,
		submit: PropTypes.func,
		back: PropTypes.func,
	};
	static defaultProps = {
		id: null,
	};

	constructor(props) {
		super(props);
		this.table = React.createRef();
		this.state = {
			data: null,
			selected: -1,
			bindings: [],
			deleted: [],
			selectedBinding: null,
			errField: null,
			trySubmit: false,
		};
	}

	onCellClick = (index) => () => {
		this.setState({
			selected: index,
			selectedBinding: this.state.data.cols[index].binding || null,
		});
	};
	deleteRow = (rowIndex) => () => {
		const data = Object.assign({}, this.state.data);
		data.rows[rowIndex].ignored = !this.state.data.rows[rowIndex].ignored;
		this.props.ignoreRows({
			id: this.props.id,
			rows: this.state.data.rows.filter((r) => r.ignored).map((r, i) => r.index)
		});
		this.setState({data});
	};

	setBinding = (id) => () => {
		const data = Object.assign({}, this.state.data);
		data.cols[this.state.selected].binding = data.fields.find((f) => f.id === id);
		data.cols[this.state.selected].bindingId = id;
		const bindings = data.cols.filter((c) => c.binding).map((c, index) => c.binding.keyword);
		Object.keys(data.groups).forEach((key) => {
			data.groups[key].items.forEach((f) => {
				if (data.cols.filter((c) => c.binding).find((b) => b.binding.keyword === f.keyword)) {
					f.binded = true;
				} else {
					f.binded = false;
				}
			});

		});
		this.setState({
			bindings,
			data,
			trySubmit: false,
			selectedBinding: data.cols[this.state.selected].binding
		});
	};

	clearBinding = () => {
		const data = Object.assign({}, this.state.data);
		Object.keys(data.groups).forEach((key) => {
			const field = data.groups[key].items.find((f) => f.id === data.cols[this.state.selected].binding.id);
			if (field) {
				field.binded = false;
			}
		});
		data.cols[this.state.selected].binding = null;
		data.cols[this.state.selected].bindingId = null;
		const bindings = data.cols.filter((c) => c.binding).map((c, index) => c.binding.keyword);
		this.setState({
			bindings,
			data,
			trySubmit: false,
			selectedBinding: null
		});

	};

	onSubmit = () => {
		const errField = this.validation();
		console.log(errField);
		if (!errField) {
			this.props.submit({
				data: this.getBindings(),
				id: this.props.id
			});
		}
		this.setState({
			errField,
			trySubmit: true
		});

	};

	onBack = () => {
		// eslint-disable-next-line
		if (confirm(this.props.intl.formatMessage({...messages['question_delete_catalogue']}))) {
			this.props.back({id: this.props.id});

		}
	};

	validation() {
		let res = null;

		Object.keys(this.state.data.groups).forEach((key) => {
			this.state.data.groups[key].items.forEach((f) => {
				if (f.required && !f.binded) {
					res = f;
				}
			});
		});
		return res;
	}

	componentDidMount() {
		this.props.context.getTranslations({
			modules: [
				'catalogue.scheme.*',
				'report.action.no-import',
				'action_continue',
				'action_cancel',
				'question_delete_catalogue',
			]
		});
		this.props.getData({id: this.props.id});
	}

	componentDidUpdate() {
		if (this.table.current) {
			const left = this.table.current.querySelector('.sticky-table-y-wrapper .sticky-table-column').getBoundingClientRect().width;
			const top = this.table.current.querySelector('.sticky-table-header-wrapper').getBoundingClientRect().height;
			const scroll = this.table.current.querySelector('.y-scrollbar').getBoundingClientRect().width;
			this.table.current.querySelector('.x-scrollbar').style.maxWidth = `calc(100% - ${left + scroll}px)`;
			this.table.current.querySelector('.x-scrollbar').style.left = `${left}px`;
			this.table.current.querySelector('.y-scrollbar').style.maxHeight = `calc(100% - ${top + scroll}px)`;
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({data: nextProps.data});
	}

	getBindings() {
		const fd = new FormData();
		this.state.data.cols.forEach((c, index) => {
			if (c.binding) {
				fd.append(`scheme[${c.binding.keyword}][type]`, c.type);
				fd.append(`scheme[${c.binding.keyword}][column]`, index);
			}
		});
		return fd;
	}

	render() {
		const {selected, selectedBinding, data, errField, trySubmit} = this.state;
		const {} = this.props;
		if (!data) {
			return <Loading/>;
		}

		const header = (
			<Row>
				<Cell/>
				{data.header.map((item, index) => (
					<Cell
						key={index}
						onClick={this.onCellClick(index)}
						className={classnames(styles.headerCell, {
							[styles.selected]: selected === index,
							[styles.binded]: data.cols[index].binding
						})}
					>
						{data.cols[index].binding ?
							<div className={styles.header}>{data.cols[index].binding.title}</div> :
							<div className={styles.headerNotLinked}>
								<FormattedMessage {...getMessage('catalogue.scheme.title.undefined_column')}/></div>
						}
					</Cell>
				))}
			</Row>
		);

		const subHeader = (
			<Row>
				<Cell className={styles.left}/>
				{data.subHeader.map((item, index) => (
					<Cell
						key={index}
						className={classnames(styles.subHeader, {[styles.subHeaderSelected]: selected === index})}
						onClick={this.onCellClick(index)}
					>
						<div className={styles.subHeaderContent}>
							<span>{item}</span>
						</div>
					</Cell>
				))}
			</Row>
		);

		const body = data.rows.map((row, rowIndex) => (
			<Row key={rowIndex}>
				<Cell className={classnames(styles.left, {[styles.deletedRow]: row.ignored})}>
					<div className={styles.leftColumn}>
						<a className={styles.removeButton} onClick={this.deleteRow(rowIndex)}>
							<i className="fa fa-ban" aria-hidden="true"/>
						</a>
						<div>{rowIndex + 1}</div>
					</div>
				</Cell>
				{row.items.map((item, index) => (
					<Cell
						key={index}
						onClick={this.onCellClick(index)}
						className={classnames(styles.cell, {
							[styles.selected]: selected === index,
							[styles.binded]: data.cols[index].binding,
							[styles.deletedRow]: row.ignored,
							[styles.right]: isFinite(item),
						})}
					>
						<div>{item}</div>
					</Cell>
				))}
			</Row>
		));

		return (
			<React.Fragment>
				<div ref={this.table} className={styles.container}>
					<div className={styles.scroll}>
						<StickyTable stickyColumnCount={1} stickyHeaderCount={2}>
							{header}
							{subHeader}
							{body}
						</StickyTable>
					</div>
					<div>
						{errField &&
						<div className={classnames(styles.errors, {show: trySubmit})}>
							<div className="icon">
								<i className="fa fa-exclamation-triangle" aria-hidden="true"/>
							</div>
							<div className="text">
								<FormattedMessage {...getMessage(`catalogue.scheme.msg.required_column_not_binded_${errField.keyword}`)}/>
							</div>

						</div>
						}
						<div className={styles.controls}>
							<div>
								<div className={styles.filter}>
									<div>
										<input type="radio"
											   id="dontimport"
											   disabled={!selectedBinding}
											   checked={!selectedBinding}
											   onChange={this.clearBinding}
										/>
									</div>
									<label htmlFor={'dontimport'}><FormattedMessage{...messages['report.action.no-import']}/></label>
								</div>
							</div>

							{Object.keys(data.groups).map((key) => (
								<React.Fragment key={key}>
									<h2>{data.groups[key].title}</h2>
									<div className="items">
										{data.groups[key].items.map((item) => (
											<div className={styles.filter} key={item.id}>
												<div>
													<input type="radio"
														   disabled={!!item.binded}
														   checked={selectedBinding && selectedBinding.id === item.id}
														   onChange={this.setBinding(item.id)}
														   id={`item_${item.id}`}
													/>
												</div>
												<label htmlFor={`item_${item.id}`}
													   className={classnames(styles.label, {[styles.disabled]: !!item.binded})}
												>{item.required ?
													<React.Fragment>{item.title} <span className="required">*</span>
													</React.Fragment> : item.title}</label>
											</div>
										))}
									</div>
								</React.Fragment>

							))}
						</div>
					</div>
					<input type="hidden" name="bindings" value={this.getBindings()}/>
				</div>
				<div className={styles['wizard-controls']}>
					<div><a href={`${window.location.href}/wizard/check_resource`}>
						<FormattedMessage {...messages['catalogue.scheme.link.go_wizard']}/>
					</a></div>
					<div>
						<input id="wizard-cancel-control" type="button" className="cancel ml"
							   onClick={this.onBack}
							   value={this.props.intl.formatMessage({...messages['action_cancel']})}
						/>
						<input id="wizard-forward-control"
							   type="button"
							   className="submit ml"
							   onClick={this.onSubmit}
							   value={this.props.intl.formatMessage({...messages['action_continue']})}
						/>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const getTableDataRequest = (params) => Observable.from(API.Import.getTableData(params));
const submit = (params) => Observable.from(API.Import.submit(params));
const back = (params) => Observable.from(API.Import.back(params));
const ignoreRows = (params) => Observable.from(API.Import.ignoreRows(params));

const contextProvider = props => (
	<LanguageContext.Consumer>
		{context => <ImportTable {...props} context={context}/>}
	</LanguageContext.Consumer>
);

export default rxConnect((props$) => {
	const actions = {
		getData$: new Subject(),
		submit$: new Subject(),
		back$: new Subject(),
		ignoreRows$: new Subject(),
	};

	const tableData$ = actions.getData$
		.debounceTime(500)
		.pluck(0)
		.switchMap((params) => getTableDataRequest(params));

	const ignoreResp$ = actions.ignoreRows$
		.debounceTime(500)
		.pluck(0)
		.switchMap((params) => ignoreRows(params));

	const submit$ = actions.submit$
		.debounceTime(500)
		.pluck(0)
		.switchMap((params) => submit(params));

	const back$ = actions.back$
		.debounceTime(500)
		.pluck(0)
		.switchMap((params) => back(params));

	return Observable.merge(
		mapActionCreators(actions),
		props$,
		tableData$.map(data => {
			return {data};
		}),
		submit$.map(resp => {
			return {resp};
		}),
		back$.map(resp => {
			return {resp};
		}),
		ignoreResp$.map(resp => {
			return {resp};
		}),
	);
})(injectIntl(contextProvider));


