import React from "react";
import ResourceFormValidations from '../../validation/ResourceFormValidations';
import style from './styles.css'
import PropTypes from "prop-types";
import TokenInput from "../../components/TokenInput/TokenInput";
import TokenInputAccount from "../../components/TokenInput/TokenInputAccount";
import ShareInput from "../../components/ShareInput/ShareInput";
import {Observable, Subject} from "rxjs/Rx";
import {mapActionCreators, rxConnect} from "rx-connect";
import messages from './../../messages';
import * as API from "../../services";
import {LanguageContext} from "../LanguageProvider";

class RightHoldersSharesTable extends React.Component {
    static propTypes = {
        contributors: PropTypes.array,
        roles: PropTypes.array,
        accounts: PropTypes.array,
        findAccount: PropTypes.func,
        getAccountRoles: PropTypes.func,
        getRecordingRoles: PropTypes.func,
        getCompositionRoles: PropTypes.func,
        type: PropTypes.oneOf(['recording', 'composition']),
        initialState: PropTypes.object,
    };

    static defaultProps = {
        type: 'recording',
    };

    static contextTypes = {
        intl: PropTypes.object,
    };
    filterRoles = (allRoles, usedRoles) => {
        if (allRoles) {
            return allRoles.filter((role) => {
                return !~usedRoles.findIndex((item) => item.id === role.id)
            })
        }
        return [];
    };
    shareChange = (index) => (value) => {
        const {rightHolders} = Object.assign({}, this.state);
        rightHolders[index].share = +value;

        // const shareSum = rightHolders.reduce((prev, current) => +prev.share + +current.share);

        let shareSum = 0;
        rightHolders.forEach((r) => shareSum += r.share);
        this.setState({
            rightHolders,
            shareSum,
        });

        this.validation();
    };
    onBlur = (index) => (event) => {
        if (event.target.getValueAsNumber() === 0) {
            if (this.isNeedRecalculate(false, index)) {
                this.recalculateNotZero();
            }
        }
    };
    isNeedRecalculate = (add, delIndex) => {
        let rightHolders = add ? this.state.rightHolders.slice(0, this.state.rightHolders.length - 1) : this.state.rightHolders;
        let count = rightHolders.length;

        if (count === 1) {
            return true;
        }

        const allShares = Math.round(10000 / count) / 100;
        const lastShare = Math.ceil(10000 / count) / 100;
        const lastShareSmall = Math.abs(((allShares * count) - 100 - allShares));

        let needRecalculate = true;
        rightHolders.forEach((rh, index) => {
            if (rh.share !== allShares && rh.share !== lastShare && rh.share !== lastShareSmall) {
                if (delIndex && delIndex === index) return;
                needRecalculate = false;
            }
        });
        return needRecalculate
    };
    getNextShare = () => {
        const count = 1 + this.state.rightHolders.filter(rh => rh.share > 0).length;
        if (count === 1) {
            return 100;
        }
        return 100 % count !== 0 ? Math.ceil(10000 / count) / 100 : Math.round(10000 / count) / 100;
    };
    recalculateNotZero = () => {
        const count = this.state.rightHolders.filter(rh => rh.share > 0).length;
        if (!count) {
            return;
        }

        let lastIndex = this.state.rightHolders.length - 1;
        const rightHolders = this.state.rightHolders.map((rh, index) => {
            if (rh.share === 0) {
                return rh
            } else {
                lastIndex = index;
                return {
                    ...rh,
                    share: Math.round(10000 / count) / 100
                }
            }
        });

        if (100 % count !== 0) {
            rightHolders[lastIndex].share = Math.ceil(10000 / count) / 100;
        }

        let shareSum = 0;
        rightHolders.forEach((r) => shareSum += r.share);

        if (shareSum > 100) {
            rightHolders[lastIndex].share -= shareSum - 100;
            shareSum = 0;
            rightHolders.forEach((r) => shareSum += r.share);
        }

        this.setState({
            rightHolders,
            shareSum,
            error: false,
            errorMessage: '',
        });
    };

    getShareSum = (rightHolders) => {
        let shareSum = 0;
        rightHolders.forEach((r) => shareSum += r.share);
        return shareSum;
    };

    recalculate = () => {
        const rh = this.state.rightHolders.filter(r => r.account);
        const count = rh.length;
        if (!count) {
            return;
        }

        const rightHolders = rh.map(rh => {
            // if (rh.share === 0) {
            //     return rh
            // } else {
            return {
                ...rh,
                share: Math.round(10000 / count) / 100
            }
            // }
        });

        if (100 % count !== 0) {
            rightHolders[rightHolders.length - 1].share = Math.ceil(10000 / count) / 100;
        }

        let shareSum = 0;
        rightHolders.forEach((r) => shareSum += r.share);

        if (shareSum > 100) {
            rightHolders[rightHolders.length - 1].share -= shareSum - 100;
            shareSum = 0;
            rightHolders.forEach((r) => shareSum += r.share);
        }

        this.setState({
            rightHolders,
            shareSum,
            error: false,
            errorMessage: '',
        });
    };
    addContributor = () => {
        const {rightHolders} = Object.assign({}, this.state);

        rightHolders.push({
            guid: Math.random() * 100000,
            account: null,
            roles: [],
            share: 0,
        });

        this.setState({
            rightHolders,
        });
        setTimeout(() => {
            if (this.accountInput.current) {
                this.accountInput.current.focus();
            }

        }, 300);
    };
    removeContributor = (index) => () => {
        const {rightHolders} = Object.assign({}, this.state);
        const isNeedRecalculate = this.isNeedRecalculate();
        rightHolders.splice(index, 1);
        this.setState({
            shareSum: this.getShareSum(rightHolders),
            rightHolders,
        });
        this.validation()
        if (isNeedRecalculate) {
            this.recalculate()
        }
    };
    addRole = (index) => (role) => {
        const {rightHolders} = Object.assign({}, this.state);
        rightHolders[index].roles.push(role);
        this.setState({
            rightHolders,
        });

    };
    removeRole = (index) => (role) => {
        const {rightHolders} = Object.assign({}, this.state);
        rightHolders[index].roles = rightHolders[index].roles.filter((r) => r.title !== role);
        this.setState({
            rightHolders,
        });
    };
    addName = (index) => (acc) => {
        const {rightHolders} = Object.assign({}, this.state);
        const isNeedRecalculate = this.isNeedRecalculate(true);
        rightHolders[index].account = acc;

        if (isNeedRecalculate) {
            rightHolders[index].share = this.getNextShare();
        } else {
            rightHolders[index].share = 0;
        }

        this.setState({
            rightHolders,
        });
        if (isNeedRecalculate) {
            this.recalculateNotZero()
        }
    };
    removeName = (index) => () => {
        const {rightHolders} = Object.assign({}, this.state);
        const isNeedRecalculate = this.isNeedRecalculate();
        rightHolders[index].account = null;
        rightHolders[index].share = 0;
        this.setState({
            rightHolders,
        });
        if (isNeedRecalculate) {
            this.recalculate()
        }
    };
    fetchAccounts = (searchText) => {
        const exceptIds = this.state.rightHolders
            .filter((rh) => rh.account)
            .map((rh) => rh.account.id)
            .join(',');
        this.props.findAccount({searchText, exceptIds});
    };
    fetchRoles = (index) => () => {
        const account = this.state.rightHolders[index].account;
        if (account) {
            this.props.getAccountRoles({account});
        } else if (this.props.type === 'recording') {
            this.props.getRecordingRoles();
        } else {
            this.props.getCompositionRoles();
        }

    };
    renderContributorRow = (item, index) => (
        <tr key={item.guid} className={style.ContributorRow}>
            <td className="">
                <button className={style.RemoveButton} onClick={this.removeContributor(index)}><i
                    className="fa fa-times"/></button>
            </td>
            <td>
                <TokenInputAccount ref={this.accountInput} tokens={item.account ? [item.account.title] : []}
                                   onDelete={this.removeName(index)}
                                   onAdd={this.addName(index)} fetchResult={this.props.accounts}
                                   onFetch={this.fetchAccounts} limitTokens={1} hideEmpty/>
            </td>
            <td>
                <TokenInput tokens={item.roles.map((role) => role.title)}
                            fetchResult={this.filterRoles(this.props.roles, item.roles)}
                            onDelete={this.removeRole(index)}
                            onAdd={this.addRole(index)} onFetch={this.fetchRoles(index)}/>
            </td>
            <td>
                <ShareInput value={item.share} onChange={this.shareChange(index)} invalid={this.state.error}
                            onBlur={this.onBlur(index)}/>
            </td>
        </tr>
    );

    constructor() {
        super();
        this.state = {
            rightHolders: [],
            shareSum: 100,
            error: false,
            errorMessage: '',
        };

        this.accountInput = React.createRef();
    }

    componentDidMount() {
        this.props.context.getTranslations({module: 'resources.contributors.*'});
        this.setState({
            ...this.state,
            ...this.fixSharesStrings(this.props.initialState),
        })

    }

    fixSharesStrings(initialState) {
        if (initialState && initialState.rightHolders) {
            return {
                ...initialState,
                rightHolders: initialState.rightHolders.map((rh) => {
                    return {...rh, share: +rh.share}
                })
            }
        }
    }

    render() {
        return (
            <div className={style.RightHoldersSharesTable}>
                {this.renderModuleHeader()}
                <table className={style.Table} width="100%">
                    <colgroup>
                        <col style={{width: 3}}/>
                        <col style={{width: '30%'}}/>
                        <col style={{width: '50%'}}/>
                        <col style={{width: 40}}/>
                    </colgroup>

                    {this.renderTableHead()}
                    {this.renderTableBody()}
                </table>
                {this.renderHiddenFields()}
            </div>
        )
    }

    renderModuleHeader() {
        const {formatMessage} = this.context.intl;
        const {type} = this.props;
        return (
            <div className={style.Header}>
                <h2>{formatMessage(messages[`resources.contributors.${type}.title`])}</h2>
                <button className={style.RecalculateButton} onClick={this.recalculate}>
                    <i className="fa fa-refresh"/>
                    <span>{formatMessage(messages['resources.contributors.action.recalculateShares'])}</span>
                </button>
            </div>
        );
    }

    renderTableHead() {
        const {formatMessage} = this.context.intl;
        return (
            <thead className={style.TableHeader}>
            <tr>
                <th colSpan="2" rowSpan="2">{formatMessage(messages['resources.contributors.field.rightholder'])}</th>
                <th>{formatMessage(messages['resources.contributors.field.role'])}</th>
                <th title={formatMessage(messages['resources.contributors.field.exploitation'])}>{formatMessage(messages['resources.contributors.field.exploitationShort'])}</th>
            </tr>
            </thead>
        );
    }

    renderTableBody() {
        const {rightHolders, shareSum, error, errorMessage} = this.state;
        const {formatMessage} = this.context.intl;

        return (
            <tbody className={style.TableBody} id="contributors">
            {rightHolders.map(this.renderContributorRow)}
            <tr className={style.LastRow}>
                <td colSpan={3}>
                    <button className={style.AddButton} onClick={this.addContributor}><i
                        className="fa fa-plus-circle"/> {formatMessage(messages['resources.contributors.action.addContributor'])}
                    </button>
                </td>
                <td className={style.ShareSum} align="center" valign="middle" data-invalid={error}
                    title={errorMessage}>
                    {rightHolders.length > 0 && <span>{shareSum.toFixed(2).replace('.', ',')} %</span>}
                </td>
            </tr>
            {error && <tr>
                <td colSpan={4} className={style.Errors}>
                    <ul>
                        <li>
                            <i className="fa fa-exclamation-triangle error"/> {errorMessage}
                        </li>
                    </ul>

                </td>
            </tr>}
            </tbody>
        );
    }

    renderHiddenFields() {
        const {rightHolders} = this.state;

        return (
            <div style={{display: 'none'}}>
                {rightHolders.map((rh, index) => (
                    <div key={index}>
                        {rh.account && <div>
                            <input name={`contributors[${index}][account_id]`} value={rh.account ? rh.account.id : ''}/>
                            <input name={`contributors[${index}][contributor_roles]`}
                                   value={rh.roles ? rh.roles.map((r) => r.id).join(',') : ''}/>
                            <input name={`contributors[${index}][share]`} value={rh.share}/>
                        </div>}
                    </div>
                ))}
            </div>
        );
    }

    validation() {
        const {error, message} = ResourceFormValidations.RightHoldersSharesTableValidation(this.state);
        this.setState({error, errorMessage: message});

    }
}

const fetchAccountsRequest = (params) => Observable.from(API.Accounts.findAccount({...params}, {ttl: 5 * 60 * 1000}));
const fetchAccountRolesRequest = (params) => Observable.from(API.Refs.getAccountTypes({...params}, {ttl: 5 * 60 * 1000}));
const fetchRecordingRolesRequest = (params) => Observable.from(API.Refs.getRecordingAccountTypes({...params}, {ttl: 5 * 60 * 1000}));
const fetchCompositionRolesRequest = (params) => Observable.from(API.Refs.getCompositionAccountTypes({...params}, {ttl: 5 * 60 * 1000}));

const contextProvider = props => (
    <LanguageContext.Consumer>
        {context => <RightHoldersSharesTable {...props} context={context}/>}
    </LanguageContext.Consumer>
);

export default rxConnect((props$) => {
    const actions = {
        findAccount$: new Subject(),
        getAccountRoles$: new Subject(),
        getCompositionRoles$: new Subject(),
        getRecordingRoles$: new Subject(),
    };

    const accounts$ = actions.findAccount$
        .debounceTime(500)
        .pluck(0)
        .switchMap((params) => fetchAccountsRequest(params));

    const accountRoles$ = actions.getAccountRoles$
        .pluck(0)
        .switchMap((params) => fetchAccountRolesRequest(params));

    const recordingRoles$ = actions.getRecordingRoles$
        .pluck(0)
        .switchMap((params) => fetchRecordingRolesRequest(params));

    const compositionRoles$ = actions.getCompositionRoles$
        .pluck(0)
        .switchMap((params) => fetchCompositionRolesRequest(params));

    return Observable.merge(
        mapActionCreators(actions),
        props$,
        accounts$.map(accounts => ({accounts})),
        accountRoles$.map(resp => ({roles: resp.roles})),
        recordingRoles$.map(resp => ({roles: resp})),
        compositionRoles$.map(resp => ({roles: resp})),
    )
})(contextProvider)
