import React from 'react';
import style from './styles.css';
import TokenInput from "../TokenInput/TokenInput";

export default class TokenInputAccount extends TokenInput {
    getWidth = () => {
        return 700;
    };
    renderItem = (item) => {
        return (
            <li key={item.id} className={`${style.ListItem} ${style.ListItemAccount}`} onClick={this.add(item)}>
                <div>
                    <div>{this.highlight(item.title, this.state.text)}</div>
                    {item.pseudonyms && item.pseudonyms.length > 0 &&
                    <div
                        className={style.Pseudonym}>({this.highlight(item.pseudonyms.map((p) => p.title).join(', '), this.state.text)})</div>}
                    {item.scopes && item.scopes.length > 0 &&
                    <div className={style.Scope}>{item.scopes.map((p) => p.title).join(', ')}</div>}
                </div>
                <div className={style.Alias}>
                    {item.aliases && item.aliases.length > 0 && this.highlight(item.aliases.map((p) => p.title).join(', '), this.state.text)}
                </div>
            </li>
        )
    };

    highlight(text, highlightText) {
        return <span
            dangerouslySetInnerHTML={{__html: text.toUpperCase().replace(highlightText.toUpperCase(), `<span class="${style.Highlighted}">${highlightText.toUpperCase()}</span>`)}}/>
    }
}
