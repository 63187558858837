export function getLicensors(accounts, account, init = []) {
    if (account.licensors) {
        if (account.licensors.length === 0) {
            return init;
        }
        return account.licensors.reduce((acc, id) => [...acc, ...getLicensors(accounts, accounts[id], [+accounts[id].accountId])], init);
    }
}

export function getLicensee(accounts, account, init = []) {
    if (account.contracts) {
        if (account.contracts.length === 0) {
            return init;
        }
        return account.contracts.reduce((acc, contract) => {
            return [...acc, ...getLicensee(accounts, accounts[contract.licensee.id], [+contract.licensee.id])];
        }, init);
    }
}

export function separateAccounts(accounts) {
    const licensees = accounts
        .filter((account) => account.licensors.length > 0)
        .reduce((acc, current) => {
            acc[current.accountId] = {...current, isLicensee: true};
            return acc
        }, {});

    const original = accounts
        .filter((account) => account.licensors.length === 0)
        .reduce((acc, current) => {
            acc[current.accountId] = current;
            return acc
        }, {});

    return {licensees, original};
}

// function toObject(array) {
// 	return array.reduce((acc, current) => {
// 		acc[current.id] = current;
// 		return acc
// 	}, {})
// }
