import React from 'react';
import PropTypes from 'prop-types';
import NumericInput from 'react-numeric-input';
import posed from 'react-pose';
import style from './styles.css';

const Container = posed.div({
    hidden: {backgroundColor: '#FFFFFF'},
    visible: {backgroundColor: '#1ab394'}
});

export default class ShareInput extends React.Component {
    static propTypes = {
        /**
         * @property {number}
         */
        value: PropTypes.number,

        /**
         * @property {bool}
         */
        invalid: PropTypes.bool,

        /**
         * @property {func}
         */
        onChange: PropTypes.func,

        /**
         * @property {func}
         */
        onBlur: PropTypes.func,

    };

    static defaultProps = {
        value: 0,
    };

    state = {highlight: false};

    onChange = (value) => {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(value)
        }
        // this.setState({
        //     value: (+this.input.current.value).toFixed(2)
        // })
    };

    onBlur = (event) => {
        if (typeof this.props.onBlur === 'function') {
            this.props.onBlur(event)
        }
    };


    constructor() {
        super();
        this.input = React.createRef();
        this.state = {
            value: '0.00',
        }
    }

    // componentDidMount() {
    //     this.setState({
    //         value: this.props.value.toFixed(2),
    //     });
    // }
    //
    // componentDidUpdate(prevProps) {
    //     if (prevProps.value !== this.props.value) {
    //         this.setState({
    //             value: this.props.value.toFixed(2),
    //         });
    //
    //     }
    // }

    shouldComponentUpdate(nextProps) {
        if (nextProps.value !== this.input.current.state.value) {
            this.setState({highlight: true});
            setTimeout(() => {
                this.setState({highlight: false});
            }, 200);
        }
        return true;
    }

    render() {
        const {className, invalid, value} = this.props;
        const {highlight} = this.state;
        return (
            <Container pose={highlight ? 'visible' : 'hidden'}>
                <div className={`${style.ShareInput} ${className ? className : ''}`} data-invalid={invalid}>
                    <NumericInput ref={this.input} className={style.Input} step={0.1} precision={2} max={100} min={0}
                                  value={value}
                                  onChange={this.onChange} onBlur={this.onBlur}
                                  format={format}
                                  noStyle
                                  strict
                    />
                    {/*<input className={style.Input} value={value} ref={this.input} onChange={this.onChange} type={'number'}*/}
                    {/*step={0.01} max={100} min={0}/>*/}
                    {/*<span className={style.Addon}>%</span>*/}
                </div>
            </Container>
        )
    }

}

function format(num) {
    return num + '%';
}
