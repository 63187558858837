import React from 'react';
import PropTypes from 'prop-types';
import {addLocaleData, IntlProvider} from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import ruLocaleData from 'react-intl/locale-data/ru';
import {ADDITIONAL_LOCALES, DEFAULT_LOCALE} from './i18n';
import * as Rx from 'rxjs';
import {mapActionCreators, rxConnect} from 'rx-connect';
import * as API from "../../services";

export const LanguageContext = React.createContext({});

export class LanguageProvider extends React.Component { // eslint-disable-line react/prefer-stateless-function
	getTranslations = ({locale, module, modules}) => {
        // if (locale) {
        //     this.state.locale = locale;
        // }

        //todo
        this.setState({});
		this.props.getLocale({
			locale: this.props.locale,
			module,
			modules
		});
    };

    constructor() {
        super();
        addLocaleData(enLocaleData);
        addLocaleData(ruLocaleData);

        this.state = {
            locale: DEFAULT_LOCALE,
            requestedLocale: DEFAULT_LOCALE,
            appLocales: [DEFAULT_LOCALE, ...ADDITIONAL_LOCALES],
            translations: {
                [DEFAULT_LOCALE]: {},
            },
        }

    }

    componentDidMount() {
        //todo save to local storage
        // this.getTranslations({locale: this.props.locale, module: '*'})
    }

    componentWillUpdate(nextProps) {
        // if (this.props.LanguageProvider.requestedLocale !== nextProps.LanguageProvider.requestedLocale) {
        //   this.props.dispatch(Actions.getTranslations(nextProps.LanguageProvider.requestedLocale));
        // }
    }

    render() {
        return (
            <IntlProvider
                locale={this.props.locale}
                messages={this.props.messages}
            >
                <LanguageContext.Provider value={{getTranslations: this.getTranslations}}>
                    {React.Children.only(this.props.children)}
                </LanguageContext.Provider>
            </IntlProvider>
        );
    }
}

LanguageProvider.propTypes = {
    locale: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    getLocale: PropTypes.func,
    messages: PropTypes.object,
};

export default rxConnect((props$) => {
    const actions = {
        getLocale$: new Rx.Subject(),
    };

    let messages = {};

    const localeData$ = actions.getLocale$
    // .debounceTime(500)
        .distinctUntilChanged()
        .pluck(0)
        .mergeMap((params) => Rx.Observable.from(API.Translations.getTranslations({...params})));

    return Rx.Observable.merge(
        props$,
        mapActionCreators(actions),
        localeData$.map(data => {
            messages = Object.assign(messages, data);
            return {messages}
        })
    )
})(LanguageProvider);
