import {BACKEND_URL} from "./Api";
import {camelizeKeys} from "humps";
import Api from "./Api";

export default class DiffViewer {
    static getDiffData(params) {
        const {catalogId, catalogResourceId, resourceId} = params;
        let req = `${BACKEND_URL}/import/catalogues/${catalogId}/updatings/${catalogResourceId}/compare/${resourceId}/json`;
        if (window.devMode) {
            req = 'http://demo5451894.mockable.io/diff/json';
        }
        return Api.get(req, {devMode: window.devMode})
            .then((json) => {
                return camelizeKeys(json)
            })
    }
}
