import PropTypes from 'prop-types';
import React, {PureComponent} from "react";
import {AnimatedCounter} from './../../AnimatedCounter';
import Flag from '../../components/Flag';
import {TableData, TableRow} from './Table';

export class LicenseesData extends PureComponent {

    onMouseEnter = (id) => () => {
        this.props.onSelect(id);
    };

    onMouseLeave = () => {
        this.props.onSelect(null);
    };

// todo вынести row в отдельный компонент
    render() {
        return (
            <TableData onMouseLeave={this.onMouseLeave}>
                {Object.keys(this.props.accounts)
                    .sort((a, b) => {
                        if (this.props.accounts[b].allLicensors.length === this.props.accounts[a].allLicensors.length) {
                            // original order
                            if (this.props.accounts[b].allLicensors.length === 0) {

                                // weight order
                                if (this.props.accounts[a].weight !== this.props.accounts[b].weight) {
                                    return this.props.accounts[b].weight - this.props.accounts[a].weight;
                                }
                                // alphabetical order
                                if (this.props.accounts[a].title < this.props.accounts[b].title) {
                                    return -1;
                                } else if (this.props.accounts[a].title > this.props.accounts[b].title) {
                                    return 1;
                                }
                                return 0;
                            }
                            return this.props.accounts[b].allLicensors.length - this.props.accounts[a].allLicensors.length;
                        }
                    })
                    .map((key) => {
                        const account = this.props.accounts[key];
                        let ownership = 0;
                        let revenue = 0;
                        let collection = 0;
                        let appliedShare;
                        let originalShare;
                        if (this.props.showShare) {
                            if (this.props.share) {
                                ownership = this.props.share.ownership ? this.props.share.ownership[key] || 0 : 0;
                                revenue = this.props.share.revenue ? this.props.share.revenue[key] || 0 : 0;
                                collection = this.props.share.collection ? this.props.share.collection[key] || 0 : 0;
                            }
                        } else {
                            ownership = account.ownership || 0;
                            revenue = account.revenue || 0;
                            collection = account.collection || 0;
                        }
                        appliedShare = +account.appliedShare || 0;
                        originalShare = +account.originalShare || 0;

                        return (
                            <TableRow
                                key={key}
                                className={`account ${this.props.highlight[key] ? this.props.highlight[key] : this.props.showShare ? 'mute' : ''}`}
                                onMouseEnter={this.onMouseEnter(account.accountId)}
                                onClick={this.onMouseEnter(account.accountId)}
                            >

                                <td>
                                    <a href={`/backend/accounts/${account.accountId}`}>{account.title}</a>
                                </td>
                                {this.props.tags && this.props.tags.accounts ? <td>
                                        {
                                            this.props.tags.accounts.items[key].scopes &&
                                            Object.values(this.props.tags.accounts.items[key].scopes)
                                                .map(i => i.title)
                                                .join(', ')}
                                    </td>
                                    :
                                    <td></td>}

                                {this.props.applied && <td align="center" className="focused share">
                                    {appliedShare !== 0 && <div>{appliedShare}%</div>}
                                </td>}
                                <td align="center" className="focused ownership">
                                    <AnimatedCounter value={+ownership}/>
                                </td>

                                <td align="center" className="focused collection">
                                    <AnimatedCounter value={+collection}/>
                                </td>

                                <td align="center" className="focused revenue">
                                    <AnimatedCounter value={+revenue}/>
                                </td>

                                {this.props.tags && this.props.tags.accounts ? <td>
                                    {/*{account.contracts && account.contracts.map((contract, index, array) => [*/}
                                    {/*<a key={`${contract.id}_a`} href={`/backend/contracts/${contract.id}`}>{contract.title}</a>,*/}
                                    {/*<br key={`${contract.id}_br1`} />,*/}
                                    {/*contract.licensee.title,*/}
                                    {/*<br key={`${contract.id}_br2`} />,*/}
                                    {/*contract.type.title,*/}
                                    {/*array.length - 1 > index ? <hr /> : '',*/}
                                    {/*])}*/}

                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div>{this.props.tags.accounts.items[key].ipiNameNumber}</div>
                                        <div>
                                            {/*<Flag code={this.props.tags.accounts.items[key].countryCode} width={17}/>*/}
                                            <Flag code={this.props.tags.accounts.items[key].countryCode}/>
                                        </div>
                                    </div>
                                </td> : <td/>}
                            </TableRow>
                        )
                    })}
            </TableData>
        );
    }
}

LicenseesData.contextTypes = {
    intl: PropTypes.object,
};

LicenseesData.defaultProps = {
    accounts: {},
    onSelect: () => {
    },
    showShare: false,
    tags: {},
};

LicenseesData.propTypes = {
    accounts: PropTypes.object,
    onSelect: PropTypes.func,
    share: PropTypes.object,
    highlight: PropTypes.object,
    tags: PropTypes.object,
    applied: PropTypes.object,
    showShare: PropTypes.bool,
};

export default LicenseesData;
