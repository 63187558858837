import React, {Component} from 'react';
import styles from './index.css';
import ScrollingTables from '../ScrollingTables';

export default class DistibutionPendingTable extends Component {
    render() {

        const rows = ['RU', 'UK', 'US', 'GR', 'JP', 'KR', 'FR', 'BL', 'CN', 'FP', 'CA'];
        const years = ['2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008'];
        const options = {

            data: [
                {
                    left: {
                        header: <thead className={styles.THead2}>
                        <tr>
                            <th>TERRITORY</th>
                        </tr>
                        </thead>,
                        data: {
                            body: <tbody>{rows.map((r) => (<tr>
                                <td>{r}</td>
                            </tr>))}</tbody>,
                            info: <div>dasdasd</div>,
                            hash: 1,
                        },
                    },
                    central: {
                        header: <thead className={styles.THead1}>
                        <tr>
                            {years.map((y) => (<th colSpan={12}>{y}</th>))}
                        </tr>
                        <tr>
                            {years.map((y) => Array.from(new Array(12)).map((m, i) => (<th>{i + 1}</th>)))}
                        </tr>
                        </thead>,
                        data: {
                            body: <tbody>
                            {rows.map(() => (
                                <tr>
                                    {years.map(() => Array.from(new Array(12)).map(() => (
                                        <td>{(Math.random() * 10000).toFixed(2)}</td>)))}
                                </tr>
                            ))}
                            </tbody>,
                            hash: 1,
                        },
                    },
                },
                {
                    left: {
                        header: <thead className={styles.THead2}>
                        <tr>
                            <th>TERRITORY</th>
                        </tr>
                        </thead>,
                        data:
                            {
                                body: <tbody>{rows.map((r) => (<tr>
                                    <td>{r}</td>
                                </tr>))}</tbody>,
                                info: <div>dasdasd</div>,
                                hash: 2,
                            },

                    },
                    central: {
                        header: <thead className={styles.THead1}>
                        <tr>
                            {years.map((y) => (<th colSpan={12}>{y}</th>))}
                        </tr>
                        <tr>
                            {years.map((y) => Array.from(new Array(12)).map((m, i) => (<th>{i + 1}</th>)))}
                        </tr>
                        </thead>,
                        data: {
                            body: <tbody>
                            {rows.map(() => (
                                <tr>
                                    {years.map(() => Array.from(new Array(12)).map(() => (
                                        <td>{(Math.random() * 10000).toFixed(2)}</td>)))}
                                </tr>
                            ))}
                            </tbody>,
                            hash: 2,
                        },

                    },
                },
                {
                    left: {
                        header: <thead className={styles.THead2}>
                        <tr>
                            <th>TERRITORY</th>
                        </tr>
                        </thead>,
                        data: {
                            body: <tbody>{rows.map((r) => (<tr>
                                <td>{r}</td>
                            </tr>))}</tbody>,
                            info: <div>dasdasd</div>,
                            hash: 3,
                        }
                    },
                    central: {
                        header: <thead className={styles.THead1}>
                        <tr>
                            {years.map((y) => (<th colSpan={12}>{y}</th>))}
                        </tr>
                        <tr>
                            {years.map((y) => Array.from(new Array(12)).map((m, i) => (<th>{i + 1}</th>)))}
                        </tr>
                        </thead>,
                        data: {
                            body: <tbody>
                            {rows.map(() => (
                                <tr>
                                    {years.map(() => Array.from(new Array(12)).map(() => (
                                        <td>{(Math.random() * 10000).toFixed(2)}</td>)))}
                                </tr>
                            ))}
                            </tbody>,
                            hash: 3,
                        },
                    },
                }

            ],

            central: {
                header: <thead className={styles.THead1}>
                <tr>
                    {years.map((y) => (<th colSpan={12}>{y}</th>))}
                </tr>
                <tr>
                    {years.map((y) => Array.from(new Array(12)).map((m, i) => (<th>{i + 1}</th>)))}
                </tr>
                </thead>,
                data: [
                    {
                        body: <tbody>
                        {rows.map(() => (
                            <tr>
                                {years.map(() => Array.from(new Array(12)).map(() => (
                                    <td>{(Math.random() * 10000).toFixed(2)}</td>)))}
                            </tr>
                        ))}
                        </tbody>,
                        hash: 1,
                    },
                    {
                        body: <tbody>
                        {rows.map(() => (
                            <tr>
                                {years.map(() => Array.from(new Array(12)).map(() => (
                                    <td>{(Math.random() * 10000).toFixed(2)}</td>)))}
                            </tr>
                        ))}
                        </tbody>,
                        hash: 2,
                    },
                    {
                        body: <tbody>
                        {rows.map(() => (
                            <tr>
                                {years.map(() => Array.from(new Array(12)).map(() => (
                                    <td>{(Math.random() * 10000).toFixed(2)}</td>)))}
                            </tr>
                        ))}
                        </tbody>,
                        hash: 3,
                    },
                ],

            },
            // right: {
            //     header: <thead><tr><th>TERRITORY</th></tr></thead>,
            //     body: <tbody><tr><td>RU</td></tr><tr><td>US</td></tr><tr><td>UK</td></tr></tbody>,
            // }
        };

        return (
            <ScrollingTables {...options}/>
        );
    }
}

DistibutionPendingTable.propTypes = {};

DistibutionPendingTable.defaultProps = {};
