import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';

class GlobalSearchForm extends PureComponent {
    state = {
        query: '',
    };
    submit = (event) => {
        event.preventDefault();
        // this.props.onChange(this.state.query);
    };
    clear = (event) => {
        event.preventDefault();
        this.setState({query: ''});
        this.props.onClear();
        this.refs.query.focus();
    };
    change = () => {
        const query = this.refs.query.value;
        this.props.onChange(query);
        this.setState({query});
    };

    componentDidMount() {
        this.refs.query.focus();
    }

    render() {
        const {query} = this.state;
        return (
            <div className={styles['search-form']}>
                <i className="fa fa-search"/>
                <input ref="query" className={styles['form-input']} onChange={this.change} value={query}/>
                {query !== '' &&
                <button onClick={this.clear}><i className={`${styles['search-input-clear']} fa fa-times`}/></button>}
            </div>
        );
    }
}

GlobalSearchForm.propTypes = {
    onChange: PropTypes.func,
    onClear: PropTypes.func,
};
GlobalSearchForm.defaultProps = {
    onChange: (value) => console.warn('Default onChange event handler', value),
    onClear: () => console.warn('Default onClear event handler'),
};


export default GlobalSearchForm;
