import moment from 'moment';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import DatePicker from 'react-datepicker';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const Div = styled.div`
		position: relative;
		margin-bottom: 10px;
    
    &>.title {
   		color: #bcbcbc;
    	text-transform: uppercase;
    	margin-bottom: 2px;
    	user-select: none;
    }
    
    & .value {
    	.value_data {
    		float: left;
    		user-select: none;
    		max-width: calc(100% - 25px);
    		.react-datepicker {
    			border: none;
    			transform-origin: top right;
    			//transform: scale(0.8);
    			border-radius: 0;
    		}
    		.react-datepicker__input-container {
    			input {
    				padding: 0;
    				border: 0;
    				font-size: 11px;
    			}
    		}
    	}
    	
    	.value_edit {
    		float: right;
    		border: 0;
    		cursor: pointer;
    		margin-top: -1px;
    		&.delete{
    			color:red;
    		}
    	}
    	
    }
    
`;
Div.displayName = 'CountryField.Div';

const Popup = styled.div`
    position: absolute;
    z-index: 2;
    right: -50px;
    top: 100%;
    //width: 192px;
    //height: 186px;
    margin-top: 10px;
    background: #fff;
    box-shadow: 0 0 30px 0 rgba(0,0,0, .25);
    &:before {
    	content: '';
    	position: absolute;
    	bottom: 100%;
    	left: 15%;
    	width: 0;
			height: 0;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 8px solid #fff;
    }
    
    & > .content {
    	overflow: auto;
    	max-height: 200px;
    	padding-top: 5px;
    	
    	ul {
    		list-style: none;
    		margin: 0;
    		padding: 0;
    	}
    	li {
    		cursor: pointer;
    		padding: 5px 10px;
    		//transition: background-color .1s;
    		&:hover,
    		&:focus,
    		&._focus {
    			background-color: #9DCEF7;
    			transition: none;
    		}
    	}
    }
    & button.show-more {
    		transition: background-color .1s;
        border: 0;
    		width: 100%;
    		background-color: #F5F5F5;
    		color: #0069c4;
    		border-radius: 0;
    		height: 25px;
    		cursor: pointer;
    		text-align: left;
    		padding-left: 10px;
    		&>i{
    			color: #bbb;
    			font-size: 10px;
    		}
    		
    		&:hover{
    		background-color: #E5E5E5;
    		transition: none;
    		} 
    }
`;

export class CountryField extends PureComponent {
    openPopup = () => {
        this.open();
    };
    onShowAll = () => {
        this.setState({isShowAll: !this.state.isShowAll});
    };
    open = () => {
        this.setState({isPopupOpen: true, isShowAll: false});
        window.addEventListener('keydown', this.onWindowKeyPress);
        window.addEventListener('click', this.onWindowClick);
    };
    close = () => {
        this.setState({isPopupOpen: false, isShowAll: false});
        window.removeEventListener('keydown', this.onWindowKeyPress);
        window.removeEventListener('click', this.onWindowClick);
    };
    clearValue = () => {
        this.close();
        this.props.onChange('');
    };
    onWindowKeyPress = (event) => {
        event.stopPropagation();
        switch (event.keyCode) {
            case 27:
                return this.close();
            case 37: {
                this.props.onChange(this.props.value.subtract(1, 'd'));
                break;
            }
            case 38: {
                this.props.onChange(this.props.value.subtract(1, 'w'));
                break;
            }
            case 39: {
                this.props.onChange(this.props.value.add(1, 'd'));
                break;
            }
            case 40: {
                this.props.onChange(this.props.value.add(1, 'w'));
                break;
            }
            case 13:
                this.onChange(this.state.selectedIndex);
                this.close();
                break;

            default:
                return;
        }
    };
    onWindowClick = (event) => {
        if (!ReactDOM.findDOMNode(this).contains(event.target)) {
            this.close();
        }
    };
    onChange = (value) => {
        this.props.onChange(value);
        this.close();
    };

    constructor() {
        super();
        this.items = [];
        this.recommended = [];
        this.state = {
            isPopupOpen: false,
            isShowAll: false,
            filter: '',
            selectedIndex: 5,
            selectedId: 0,
        }
    }

    componentDidMount() {
        let items = this.props.items;
        this.recommended = this.props.recommended.map((r) => ({...items.find((i) => +i.id === +r.id), ...r}));
        this.items = items.sort((a, b) => {
            const titleA = a.title.toUpperCase();
            const titleB = b.title.toUpperCase();
            if (titleA < titleB) {
                return -1;
            }
            if (titleA > titleB) {
                return 1;
            }

            // names must be equal
            return 0;
        });
    }

    componentWillUnmount() {
        this.close();
    }

    render() {
        const {title, value} = this.props;
        const {isPopupOpen} = this.state;
        const date = value.isValid() ? value.format('L') : '-';

        return (
            <Div
                onClick={(event) => {
                    if (isPopupOpen) {
                        event.stopPropagation();
                    }
                }}
            >
                <div className="title">{title}:</div>
                <div className="value">
					<span className="value_data">
						{date}
                        {isPopupOpen && <Popup>
                            <DatePicker
                                inline
                                selected={value.isValid() ? value : moment()}
                                onChange={this.onChange}
                            />
                        </Popup>}
					</span>
                    {isPopupOpen ? <button className="value_edit delete" onClick={this.clearValue}>
                            <i className="fa fa-times" aria-hidden="true"/>
                        </button>
                        :
                        <button className="value_edit" onClick={this.openPopup}>
                            <i className="fa fa-ellipsis-h" aria-hidden="true"/>
                        </button>
                    }
                </div>
            </Div>

        );
    }
}

CountryField.defaultProps = {
    title: "CountryField",
    items: [],
    recommended: [],
    onChange: () => {
    },
};

CountryField.propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
    items: PropTypes.array,
    recommended: PropTypes.array,
    onChange: PropTypes.func,
};

export default CountryField;
