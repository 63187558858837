import React, {Component} from "react";

export class InputField extends Component {
    render() {
        const {value, placeholder} = this.props;
        return (
            <div>
                <input value={value} placeholder={placeholder}/>
            </div>
        );
    }
}

InputField.defaultProps = {
    value: "Value",
    placeholder: 'Placeholder...'
};

InputField.propTypes = {};

export default InputField;
