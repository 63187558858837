import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tbody`
		& td {
			padding: 25px 8px 8px 8px;
			font-size: 16px;
			text-transform: uppercase;
		}
`;
Wrapper.displayName = 'Styled.TableSubHeader';

const TableSubHeader = (props) => (
    <Wrapper>
        <tr>
            <td colSpan={props.colspan}>{props.title}</td>
        </tr>
    </Wrapper>

);

TableSubHeader.defaultProps = {
    title: '',
    colspan: 5,
};

TableSubHeader.propTypes = {
    title: PropTypes.string,
    colspan: PropTypes.number,
};

export default TableSubHeader;
