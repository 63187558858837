import * as d3 from 'd3';
import moment from 'moment';

function getParams(fields) {
    var _fields = {
        tracks: [],
        compositions: []
    };
    var url_string = window.location.href;
    var url = new URL(url_string);

    function _update(arr) {
        return arr.map(function (item) {
            var val = url.searchParams.get(item.value);
            if (val) {
                return Object.assign(item, {show: !(val === 'false')})
            } else {
                return Object.assign(item)
            }
        });
    }

    _fields.tracks = _update(fields.tracks);
    _fields.compositions = _update(fields.compositions);

    var mode = url.searchParams.get('mode');
    return {
        fields: _fields,
        mode: mode === 'tracks' ? 'tracks' : 'compositions',
    };
}

function setParams(fields, mode) {
    var _fields = [].concat(fields.tracks, fields.compositions);
    var url = new URL(document.location.href);

    _fields.forEach(function (item) {
        url.searchParams.set(item.value, item.show);
    });

    url.searchParams.set('mode', mode);
    window.history.replaceState('Diagram', 'Heaven 11', url)
}

export default function drawClaimerLicensorsPeriodDiagram(selector, periodsData, _fields, messages, urls, byMonths) {
    moment.locale(navigator.language);
    var fields = {};
    fields.tracks = _fields.tracks.map(function (field) {
        return Object.assign(field, {className: field.value.replace(/compositions_|tracks_|_count/g, '')});
    });

    fields.compositions = _fields.compositions.map(function (field) {
        return Object.assign(field, {className: field.value.replace(/compositions_|tracks_|_count/g, '')});
    });

    var parseTime = d3.timeParse("%Y-%m-%d");
    var data = periodsData.periods.map(function (period) {
        return Object.assign(period, {date: parseTime(period.date)})
    });

    var params = getParams(fields);
    fields = params.fields;
    var config = {
        width: 580,
        height: 360,
        mode: params.mode || 'compositions',
    };

    var margin = {top: 25, right: 0, bottom: 25, left: 40};
    var width = config.width - margin.left - margin.right;
    var height = config.height - margin.top - margin.bottom;
    var maxValue = 0;
    var lastRenderData;
    var diagram;
    var xScale = d3.scaleTime().rangeRound([0, width]).interpolate(d3.interpolateRound);
    var yScale = d3.scaleLinear().rangeRound([height, 0]).interpolate(d3.interpolateRound);

    var tracksSwitch;
    var compositionsSwitch;

    var quarter = function (date, i) {
        var date2 = new Date();
        date2.setMonth(date.getMonth() - 1);
        var q = Math.ceil((date2.getMonth()) / 3);
        return "Q" + q;
    };

    var shortThousands = function (val) {
        if (val >= 1000000) {
            return (val % 1000000 > 0 ? (val / 1000000).toFixed(1) : (val / 1000000).toFixed(0)) + 'M'
        }

        if (val >= 1000) {
            return (val % 1000 > 0 ? (val / 1000).toFixed(1) : (val / 1000).toFixed(0)) + 'K'
        }
        return val.toFixed(0);
    };

    var dontShow = function () {
        return ''
    };

    var yearformat = d3.timeFormat("%Y");

    function getMax(data, fields) {
        return d3.max(data, function (datum) {
            var arr = fields
                .filter(function (item) {
                    return item.show
                })
                .map(function (item) {
                    return datum[item.value] || 0;
                });
            return d3.max(arr)
        })
    };

    function parseData(periods, fields) {
        var _periods = periods.sort(function (a, b) {
            return a.date.getTime() - b.date.getTime();
        });

        maxValue = getMax(_periods, fields);

        var dates = _periods.concat({date: moment(_periods[_periods.length - 1].date).add(3, 'months').toDate()}).map(function (d) {
            return d.date;
        });

        xScale.domain(d3.extent(dates));
        yScale.domain([0, maxValue + (maxValue * 0.1)]);
        updateAxis();

        return _periods;
    }

    function handleTracksClick() {
        config.mode = 'tracks';
        updateControls();
        update();
        setParams(fields, config.mode);
    }

    function handleCompositionsClick() {
        config.mode = 'compositions';
        updateControls();
        update();
        setParams(fields, config.mode);
    }

    function handleLegendClick(d) {
        if (config.mode === 'compositions') {
            d.show = !d.show;
            updateControls();
            update();
        } else {
            d.show = !d.show;
            updateControls();
            update();
        }
        setParams(fields, config.mode);

    }

    function updateControls() {
        tracksSwitch.property('checked', config.mode !== 'compositions');
        compositionsSwitch.property('checked', config.mode === 'compositions');
    }

    function drawLegend(fields) {
        var data = fields.filter(function (field) {
            return true //field.sum > 0
        });

        var groups = [];
        data.forEach(function (item) {
            if (!groups[item.group]) {
                groups[item.group] = [item];
            } else {
                groups[item.group].push(item);
            }
        });

        groups = groups.filter(function (item) {
            return item !== undefined;
        });

        d3.select(selector).selectAll('.claimer-licensors__legend__group').remove();

        var offset = 4;
        var legend = d3.select(selector).select('.claimer-diagram__legend');
        var group = legend.selectAll('.claimer-licensors__legend__group')
            .data(groups)
            .enter()
            .append('g')
            .attr('class', 'claimer-licensors__legend__group')
            .attr("transform", function (d, i) {
                var y = offset;
                offset += groups[i].length * 20 + 10;
                return 'translate(0, ' + y + ')'
            });

        group.append('line')
            .attr('class', 'claimer-licensors__legend__group__line')
            .attr('x1', 2)
            .attr('x2', function (d, i) {
                return i === 0 ? 0 : 30
            })
            .attr('y1', -10)
            .attr('y2', -10)
            .attr('stroke', '#e6e6e6');

        var item = group.selectAll('.claimer-licensors__legend__group')
            .data(function (d) {
                return d
            })
            .enter()
            .append('g')
            .attr('class', function (d) {
                return 'claimer-licensors__legend__item ' + (d.show ? 'claimer-licensors__item_visible' : 'claimer-licensors__item_hided');
            })
            .on('click', handleLegendClick);


        item.append('rect')
            .attr("x", 2.5)
            .attr("y", function (d, i) {
                return 20 * i
            })
            .attr("width", 10)
            .attr("height", 10)
            .attr('class', function (d) {
                return 'claimer-licensors__legend__rect claimer-licensors__bar__' + d.className;
            })
            .style('fill-opacity', function (d) {
                return d.show ? 1 : 0;
            })
            .style("cursor", 'pointer')
            .style("stroke", function (d) {
                return d.show ? 'none' : 'black';
            });

        item.append('text')
            .attr("x", 20)
            .attr("y", function (d, i) {
                return (20 * i) - 1;
            })
            .attr("dy", "10px")
            .attr("class", "claimer-licensors__legend__text")
            .style("text-anchor", "start")
            .style("cursor", 'pointer')
            .text(function (d) {
                return messages['licensors.diagram.legend.' + d.value]
            });

        group.exit().remove();

        config.legendHeight = offset;
        legend.attr('height', config.legendHeight);
    }


    var lineFunction = d3.line()
        .x(function (d) {
            return xScale(moment(d.x).add(byMonths ? 2 : 6, 'weeks').toDate()) + 1;
        })
        .y(function (d) {
            return yScale(d.y);
        });

    var highLightField = 'tracks_ignored_count';
    var alwaysShow = {};

    function draw(dataToDraw, fields) {
        alwaysShow = {};
        var data = fields.map(function (field) {
            return dataToDraw.map(function (d, i, arr) {
                    if (d[field.value] > 0) {
                        alwaysShow[field.value] = alwaysShow[field.value] ? alwaysShow[field.value] + 1 : 1
                    }

                    var alwaysCircleShow = false;
                    if (arr.length > 1) {
                        if (i === 0) {
                            const next = arr[1];
                            if (d[field.value] !== 0 && next[field.value] === 0) {
                                alwaysCircleShow = true;
                            }
                        } else if (i >= 0 && i < arr.length - 1) {
                            const prev = arr[i - 1];
                            const next = arr[i + 1];
                            if (d[field.value] !== 0 && prev[field.value] === 0 && next[field.value] === 0) {
                                alwaysCircleShow = true;
                            }

                        } else if (i === arr.length - 1) {
                            const prev = arr[i - 1];
                            if (d[field.value] !== 0 && prev[field.value] === 0) {
                                alwaysCircleShow = true;
                            }
                        }
                    }

                    return {
                        show: field.show,
                        keyword: field.value,
                        className: field.className,
                        showPikes: true,
                        alwaysCircleShow: alwaysCircleShow,
                        x: d.date,
                        y: d[field.value]
                    }
                }
            )
        });

        var canvas = diagram.select('.canvas');

        // var circlesFields = fields.map(function (field) {
        //     return circles.map(function (d) {
        //         return {
        //             show: field.show,
        //             keyword: field.value,
        //             className: field.className,
        //             showPikes: true,
        //             x: d.date,
        //             y: d[field.value]
        //         }
        //     })
        // });
        // console.log(circles)

        canvas.call(drawLine, data);
        canvas.call(drawHiddenLine, data);
        canvas.call(drawCircle, data);
        //canvas.call(drawAloneCircle, circles);

    }

    function getHref(d) {
        var begins;
        var ends;

        if (byMonths) {
            begins = moment(d.x).date(1);
            ends = moment(begins).add(1, 'M').subtract(1, 'd');
        } else {
            begins = moment(d.x).date(1).month(0).quarter(moment(d.x).quarter());
            ends = moment(begins).add(3, 'M').subtract(1, 'd');
        }

        switch (d.keyword) {
            case 'compositions_count':
                return urls[d.keyword]
                    .replace("[BEGINS]", begins.format('YYYY-MM-DD'))
                    .replace("[ENDS]", ends.format('YYYY-MM-DD'));

            // return appendToURL(urls[d.keyword].replace("[ACCOUNT_ID]", id), {
            //     begins: begins.format('YYYY-MM-DD'),
            //     ends: ends.format('YYYY-MM-DD'),
            // });

            default:
                return urls[d.keyword]
                    .replace("[BEGINS]", begins.format('YYYY-MM-DD'))
                    .replace("[ENDS]", ends.format('YYYY-MM-DD'));

            // return appendToURL(urls[d.keyword], {
            //     account_id: id,
            //     begins: begins.format('YYYY-MM-DD'),
            //     ends: ends.format('YYYY-MM-DD'),
            // });
        }
    }

    function appendToURL(origin, params) {
        var url = new URL(new URL(window.location.href).origin + origin);
        Object.keys(params).forEach(function (key) {
            url.searchParams.append(key, params[key]);
        });
        return url.toString();
    }

    function drawLine(selection, data) {
        var line = selection.selectAll('path.period-line').data(data);
        line.exit().transition().duration(5000)
            .attr('translate', 'transform(' + (height + 100) + ',0)')
            .remove();
        line.enter().append("path")
            .attr("fill", "none")
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round")
            .attr("stroke-width", 1.5)
            .merge(line)
            .transition().duration(300)
            .attr("d", path)
            .transition().duration(500)
            .style("opacity", function (d) {
                return d[0] && d[0].show ? 1 : 0
            })
            .attr("class", function (d) {
                return 'period-line ' + (d[0] && d[0].className)
            })
        ;
        return line;
    }

    function path(d) {
        var path = lineFunction(d);
        var pathArr = path.split('L');
        var result = pathArr[0];
        for (var i = 1; i < pathArr.length; i++) {
            var point = pathArr[i];
            if (d[i].y === 0) {
                result += 'M' + point;
            } else {
                result += 'L' + point;
            }

        }
        return result;
    }

    function drawCircle(selection, data) {
        var g = selection.selectAll('g.period-line-circle').data(data);

        g.exit().attr('translate', 'transform(' + (height + 100) + ',0)').remove();

        var gEnter = g.enter().append("g")
            .merge(g)
            .attr("class", function (d) {
                // if(alwaysShow[d[0].keyword]===1) {
                //     return 'period-line-circle ' + (d[0] && d[0].className)
                // }
                return 'period-line-circle ' + (d[0] && d[0].className)
            });

        gEnter.call(pikeCircle);
        return g;
    }


    function pikeCircle(sel) {
        var pike = sel.selectAll('a').data(function (d) {
            if (d[0].showPikes && d[0].show) {
                return d
            }
            return []
        });

        pike.exit().remove();

        pike.enter().append('a')
            .on('mouseover', function (d) {
                d3.selectAll('.period-line-circle.' + d.className + ' circle._dim').transition().duration(300).style('opacity', 1)
            })
            .on('mouseleave', function (d) {
                if (alwaysShow[d.keyword] !== 1) {
                    d3.selectAll('.period-line-circle.' + d.className + ' circle._dim').transition().duration(300).style('opacity', 0)
                }
            })
            .merge(pike)
            .attr('xlink:href', function (d) {
                return getHref(d)
            })
            .call(circle);

        return pike;
    }

    function circle(sel) {
        sel.selectAll('circle').remove();
        return sel.append('circle')
            .attr('r', function (d) {
                return d.y === 0 ? 0 : 3;
            })
            .style('opacity', function (d) {
                return alwaysShow[d.keyword] === 1 ? 1 : d.alwaysCircleShow ? 1 : 0;

            })
            .attr('class', function (d) {
                return d.alwaysCircleShow ? d.className + ' _undim' : d.className + ' _dim'
            })
            .attr('cx', function (d) {
                return xScale(moment(d.x).add(byMonths ? 2 : 6, 'weeks').toDate()) + 1
            })
            .attr('cy', function (d) {
                return yScale(d.y)
            })
            .call(text);
    }

    function text(sel) {
        sel.selectAll('title').remove();
        return sel.append('title').text(function (d) {
            return messages['licensors.diagram.focus.field'] + ': ' + messages['licensors.diagram.legend.' + d.keyword] + '\n' +
                messages['licensors.diagram.date.field'] + ': ' + (byMonths ? moment(d.x).format('YYYY MMMM') : moment(d.x).format('YYYY [Q]Q')) + '\n' +
                messages['licensors.diagram.value.field'] + ': ' + d.y
        })
    }

    function drawHiddenLine(selection, data) {
        var line = selection.selectAll('path.hidden-line').data(data);
        line.exit().attr('translate', 'transform(' + (height + 100) + ',0)').remove();
        line.enter().append("path")
            .attr("fill", "none")
            .attr('stroke', 'transparent')
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round")
            .on('mouseover', function (d) {
                d3.selectAll('.period-line-circle.' + d[0].className + ' circle._dim').transition().duration(300).style('opacity', 1)
            })
            .on('mouseleave', function (d) {
                d3.selectAll('.period-line-circle.' + d[0].className + ' circle._dim').transition().duration(300).style('opacity', 0)
            })
            .merge(line)
            .attr("stroke-width", function (d) {
                return d[0] && d[0].show ? 20 : 0
            })
            .attr("class", function (d) {
                return 'hidden-line ' + d[0].className + '-hidden'
            })
            .attr("d", path);
        return line;
    }

    function updateAxis() {
        var yAxis = d3.axisLeft(yScale).ticks(maxValue < 6 ? maxValue : 6).tickSize(width).tickFormat(shortThousands);
        diagram.select('.y')
            .attr('transform', 'translate(' + (width + margin.left - 10) + ', ' + margin.top + ')')
            .transition().delay(300).duration(300)
            .call(yAxis);

        var xAxisYears = d3.axisBottom(xScale).ticks(d3.timeYear, 1)
            .tickSize(height + 10).tickPadding(8)
            .tickSizeOuter(height + 5).tickFormat(yearformat);
        diagram.select('.years')
            .attr('transform', 'translate(' + margin.left + ', ' + (20) + ')')
            .call(xAxisYears)
            .selectAll('.tick text')
            .style('text-anchor', 'middle')
            .attr('x', function (d, i, e) {
                return width / (e.length * 2)
            });

        var xAxisQuarters = d3.axisTop(xScale)
            .ticks(d3.timeMonth, 3).tickSize(height).tickPadding(10)
            .tickFormat(quarter);
        diagram.select('.quarters')
            .attr('transform', 'translate(' + margin.left + ', ' + (height + margin.top) + ')')
            .call(xAxisQuarters)
            .selectAll('.tick text')
            .style('text-anchor', 'middle')
            .attr('x', function (d, i, e) {
                return -width / (e.length * 2)
            });

        if (byMonths) {
            var xAxisMonths = d3.axisTop(xScale).ticks(d3.timeMonth).tickSize(height).tickFormat(dontShow);
            diagram.select('.months')
                .attr('transform', 'translate(' + margin.left + ', ' + (height + margin.top) + ')')
                .call(xAxisMonths);
        }
        d3.selectAll('.y').selectAll('.domain').remove();
        d3.selectAll('.quarters').selectAll('.domain').remove();
        d3.selectAll('.months').selectAll('.domain').remove();
        d3.selectAll('.years').selectAll('.domain').remove();

    }

    function init() {
        /* Container */
        var container = d3.select(selector)
            .append('div')
            .attr('class', 'claimer-diagram__container');
        container.append('div')
            .attr('class', 'claimer-diagram__container_diagram');
        container.append('div')
            .attr('class', 'claimer-diagram__container_controls');


        /* Controls */
        var controls = d3.select('.claimer-diagram__container_controls').append('div').attr('class', 'claimer-diagram__container_controls-group');

        controls.append('div')
            .attr('class', 'claimer-licensors__controls__header')
            .html(messages['licensors.diagram.legend.mode.header']);

        var compositionsSection = controls
            .append('label')
            .attr('class', 'claimer-licensors__controls-field');

        compositionsSwitch = compositionsSection.append('input')
            .attr('class', 'claimer-licensors__controls-radio-compositions')
            .attr('type', 'radio')
            .property('checked', config.mode === 'compositions')
            .on('change', function () {
                handleCompositionsClick()

            });
        compositionsSection.append('span').html(messages['licensors.diagram.legend.compositions']);

        var tracksSection = controls
            .append('label')
            .attr('class', 'claimer-licensors__controls-field');

        tracksSwitch = tracksSection.append('input')
            .attr('class', 'claimer-licensors__controls-radio-tracks')
            .attr('type', 'radio')
            .property('checked', config.mode !== 'compositions')
            .on('change', function () {
                handleTracksClick()
            });
        tracksSection.append('span').html(messages['licensors.diagram.legend.tracks']);

        var legendContainer = d3.select('.claimer-diagram__container_controls')
            .append('div').attr('class', 'claimer-diagram__container_controls-group');

        legendContainer.append('div')
            .attr('class', 'claimer-licensors__controls__header')
            .html(messages['licensors.diagram.legend.focus.header']);

        legendContainer.append('svg')
            .attr('class', 'claimer-diagram__legend');

        diagram = d3.select('.claimer-diagram__container_diagram')
            .append('svg')
            .attr('width', config.width)
            .attr('height', config.height);

        diagram.append('g').attr('class', 'y axis');
        diagram.append('g').attr('class', 'months axis');
        diagram.append('g').attr('class', 'quarters axis')
            .append('line')
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', width)
            .attr('y2', 0).attr('transform', 'translate(0, ' + -height + ')');
        diagram.append('g').attr('class', 'years axis');

        diagram.append('g').attr('class', 'gray-line')
            .append('line')
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', width)
            .attr('y2', 0)
            .attr('transform', 'translate(' + margin.left + ', ' + (height + margin.top) + ')');

        diagram.append('g').attr('class', 'canvas').attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')');
    }

    init();
    update();

    function update() {
        var fieldsToDraw = config.mode === 'compositions' ? fields.compositions : fields.tracks;
        drawLegend(fieldsToDraw);
        lastRenderData = parseData(data, fieldsToDraw);
        draw(lastRenderData, fieldsToDraw);
    }

    window.addEventListener('popstate', function (event) {
        params = getParams(fields);
        fields = params.fields;
        config.mode = params.mode || 'compositions';
        update();
    }, false);
}