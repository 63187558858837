import React, {Component} from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';

const InputDate = styled.input`
    width: calc(100% - 8px);
    padding: 2px;
    margin: 2px 0;
`;
InputDate.displayName = 'Styled.Input';

export class InputDateField extends Component {
    render() {
        const {value} = this.props;
        return (
            <div>
                <InputDate type="date" value={value} onChange={this.props.onChange}/>
            </div>
        );
    }
}

InputDateField.defaultProps = {
    value: '',
    onChange: () => {
    },
};

InputDateField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default InputDateField;
