import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import CountryFlag from './CountryFlag';
import Search from './Search';

const Div = styled.div`
		position: relative;
		margin-bottom: 10px;
    
    &>.title {
   		color: #bcbcbc;
    	text-transform: uppercase;
    	margin-bottom: 2px;
    	user-select: none;
    }
    
    & .value {
    	.value_data {
    		float: left;
    		user-select: none;
    		max-width: calc(100% - 25px);
    	}
    	
    	.value_edit {
    		float: right;
    		border: 0;
    		cursor: pointer;
    		margin-top: -1px;
    	}
    	
    }
    
`;
Div.displayName = 'CountryField.Div';

const Popup = styled.div`
    position: absolute;
    z-index: 2;
    left: 0;
    top: 100%;
    width: 100%;
    margin-top: 10px;
    background: #fff;
    box-shadow: 0 0 30px 0 rgba(0,0,0, .25);
    &:before {
    	content: '';
    	position: absolute;
    	bottom: 100%;
    	left: 15%;
    	width: 0;
			height: 0;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 8px solid #fff;
    }
    
    & > .content {
    	overflow: auto;
    	max-height: 200px;
    	padding-top: 5px;
    	
    	ul {
    		list-style: none;
    		margin: 0;
    		padding: 0;
    	}
    	li {
    		cursor: pointer;
    		padding: 5px 10px;
    		//transition: background-color .1s;
    		&:hover,
    		&:focus,
    		&._focus {
    			background-color: #9DCEF7;
    			transition: none;
    		}
    	}
    }
    & button.show-more {
    		transition: background-color .1s;
        border: 0;
    		width: 100%;
    		background-color: #F5F5F5;
    		color: #0069c4;
    		border-radius: 0;
    		height: 25px;
    		cursor: pointer;
    		text-align: left;
    		padding-left: 10px;
    		&>i{
    			color: #bbb;
    			font-size: 10px;
    		}
    		
    		&:hover{
    		background-color: #E5E5E5;
    		transition: none;
    		} 
    }
`;

export class CountryField extends Component {
    togglePopup = () => {
        if (this.state.isPopupOpen) {
            this.close();
        } else {
            this.open();
        }
    };
    onShowAll = () => {
        this.setState({isShowAll: !this.state.isShowAll});
    };
    open = () => {
        const items = this.state.isShowAll ? this.items : this.recommended;
        const selectedIndex = items.findIndex((item) => +item.id === +this.props.value);
        this.setState({isPopupOpen: true, selectedIndex, isShowAll: this.props.recommended.length === 0});
        window.addEventListener('keydown', this.onWindowKeyPress);
        window.addEventListener('click', this.onWindowClick);
    };
    close = () => {
        this.setState({isPopupOpen: false, filter: ''});
        window.removeEventListener('keydown', this.onWindowKeyPress);
        window.removeEventListener('click', this.onWindowClick);
    };
    onWindowKeyPress = (event) => {
        const items = this.state.isShowAll ? this.items : this.recommended;

        switch (event.keyCode) {
            case 27:
                return this.close();
            case 38: {
                const selectedIndex = Math.min(Math.max(0, this.state.selectedIndex - 1), items.length - 1);
                this.setState({selectedIndex});
                event.preventDefault();
                break;
            }
            case 40: {
                const selectedIndex = Math.min(Math.max(0, this.state.selectedIndex + 1), items.length - 1);
                this.setState({selectedIndex});
                event.preventDefault();
                break;
            }
            case 13:
                this.onChange(this.state.selectedIndex);
                this.close();
                event.preventDefault();
                break;

            default:
                return;
        }
    };
    onWindowClick = (event) => {
        if (!ReactDOM.findDOMNode(this).contains(event.target)) {
            this.close();
        }
    };
    onChange = (index) => {
        const items = this.state.isShowAll ? this.items : this.recommended;
        const item = items[index];
        this.props.onChange(item.id)
    };
    onSelect = (id, index) => () => {
        this.setState({selectedIndex: index});
        this.onChange(index);
        this.close();
    };
    onSearchChange = (value) => {
        const index = this.props.items.findIndex((item) => {
            return item.title.toLowerCase().search(value.toLowerCase()) === 0;
        });

        this.setState({
            filter: value,
            selectedIndex: index !== -1 ? index : this.state.selectedIndex,
            isShowAll: value !== '' || this.state.isShowAll
        });
    };

    constructor() {
        super();
        this.items = [];
        this.recommended = [];
        this.state = {
            isPopupOpen: false,
            isShowAll: false,
            filter: '',
            selectedIndex: 5,
            selectedId: 0,
        }
    }

    componentDidMount() {
        this.update(this.props);
    }


    update() {
        let items = this.props.items;
        this.recommended = this.props.recommended.map((r) => ({...items.find((i) => +i.id === +r.id), ...r}));
        this.items = items.sort((a, b) => {
            const titleA = a.title.toUpperCase();
            const titleB = b.title.toUpperCase();
            if (titleA < titleB) {
                return -1;
            }
            if (titleA > titleB) {
                return 1;
            }

            // names must be equal
            return 0;
        });
        this.countries = items.map((item) => <CountryFlag title={item.title} code={item.code}/>)
    }

    componentWillUnmount() {
        this.close();
    }

    render() {
        const {title} = this.props;
        const {isPopupOpen, isShowAll, filter, selectedIndex} = this.state;
        if (this.items.length === 0) {
            this.update()
        }
        const items = isShowAll ? this.items : this.recommended;
        const value = this.items.find((i) => +i.id === +this.props.value);
        return (
            <Div
                onClick={(event) => {
                    if (isPopupOpen) {
                        event.stopPropagation();
                    }
                }}
            >
                <div className="title">{title}:</div>
                <div className="value">
					<span className="value_data">
						{value ? <CountryFlag title={value.title} code={value.code}/> : '-'}
					</span>
                    <button className="value_edit" onClick={this.togglePopup}>
                        <i
                            className={`${isPopupOpen ? 'fa fa-times' : 'fa fa-ellipsis-h'}`} aria-hidden="true"
                            style={{color: isPopupOpen ? 'red' : '#000'}}
                        />
                    </button>
                </div>
                {isPopupOpen && <Popup>
                    <Search onChange={this.onSearchChange} value={filter}/>
                    <div className="content">
                        <ul>
                            {items.map((item, index) => (
                                <li
                                    key={item.id}
                                    ref={node => node && index === selectedIndex ? node.scrollIntoViewIfNeeded() : null}
                                    className={`${index === selectedIndex ? '_focus' : ''}`}
                                    onClick={this.onSelect(item.id, index)}
                                >
                                    {isShowAll ? this.countries[index] :
                                        <CountryFlag title={item.title} code={item.code}/>}

                                </li>))}
                        </ul>
                        {!isShowAll &&
                        <button className="show-more" onClick={this.onShowAll}>Show All <i
                            className="fa fa-chevron-right"/>
                        </button>}
                    </div>
                </Popup>}
            </Div>

        );
    }
}

CountryField.defaultProps = {
    title: "CountryField",
    items: [],
    recommended: [],
    onChange: () => {
    },
};

CountryField.propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
    items: PropTypes.array,
    recommended: PropTypes.array,
    onChange: PropTypes.func,
};

export default CountryField;
