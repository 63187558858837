import PropTypes from 'prop-types';
import React, {Component} from "react";
import styled from 'styled-components';

const Dl = styled.dl`
    font-size: 11px;
    font-family: sans-serif;
    margin-bottom: 15px;
    margin-top: 0;

    & dd {
    		font-size: 15px;
        text-transform: capitalize;
        margin: 0;
    }
    
    & dt {
        text-transform: uppercase;
    		color: #bcbcbc;	
    }
`;
Dl.displayName = 'InfoField.Dl';

export class InfoField extends Component {
    render() {
        const {title, value} = this.props;
        return (
            <Dl>
                <dt>{title}</dt>
                <dd>{value}</dd>
            </Dl>

        );
    }
}

InfoField.defaultProps = {
    title: "Field",
    value: '-',
};

InfoField.propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
};

export default InfoField;
