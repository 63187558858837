import {getQueryString, getQueryStringParams} from "./Api";
import {camelizeKeys} from "humps";
import Api from "./Api";

export default class Splitsheet {
    static fetchTags = ({id, params}, options) =>
        Api.get(`/split_sheet/${id}/params/?${getQueryStringParams(params)}`, options)
            .then((json) => camelizeKeys(json));


    static fetchData = ({id, params}, options) =>
        Api.get(`/ownerships/splitsheet/${id}/?${getQueryString(params)}`, options)
            .then((json) => camelizeKeys(json));

    static fetchRevenue = ({id, licensor, params = {}}, options) =>
        Api.get(`/ownerships/revenue/${id}/${licensor}/?${getQueryString(params)}`, options)
            .then((json) => camelizeKeys({...json, id, licensor, params}));

}
