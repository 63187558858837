import styled from 'styled-components';

const TableRow = styled.tr`
	&:first-child td {
			border-top: 2px solid #dadada;
	}
	&:last-child td {
			border-bottom: 2px solid #dadada;
	}
	& td:last-child {
			border-right: 0;
	}
	&.blue {
			td {
					background-color: #cdeaff;
					border-right-color: #bfdaee;
					border-bottom-color: #a5bbcc;
					&.focused {
							background-color: #adc8e0;
					}
			}
			&:first-child td {
					border-top-color: #a5bbcc;
			}					
	}
	&.green{
			td {
					background-color: #d7ffc2;
					border-right-color: #ccf2b9;
					border-bottom-color: #badca8;
					&.focused {
							background-color: #bde5aa;
							color: #000;
					}
					&:first-child {
							padding-left: 20px;
							&:before {
									content: "»";
									position: absolute;
	    						margin:    -3px 0 0 -12px;
   								font-size: 14px;
   								color:     #28bd01;
							}
					}
			}
			&:first-child td {
					border-top-color: #badca8;
			}
	}
	&.mute td{
			opacity: 0.4;
	}	
`;
TableRow.displayName = 'Styled.TableRow';

export default TableRow;
