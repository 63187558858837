export default class ResourceFormValidations {
    static RightHoldersSharesTableValidation(state) {
        // const shareSum = state.rightHolders.reduce((prev, current) => +prev.share + +current.share);

        let shareSum = 0;
        state.rightHolders.forEach((r) => shareSum += r.share);

        if (shareSum === 100) {
            return {error: false, message: ''};
        } else {
            return {error: true, message: 'Сумма долей включаемых объектов не равна 100%'}
        }

    }
}
