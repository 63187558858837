import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tbody`
		& th {
			font-weight: bold;
			font-size: 11px;
			text-transform: uppercase;
			padding: 0 8px 0;
			border-top: 8px solid #d9d9d9;
			border-bottom: 8px solid #d9d9d9;
			border-right: 1px solid #a6a6a6;
			background-color: #d9d9d9;
		}
		
		& th:last-child {
			border-right: 0;
		}
		
		& .share {
		  font-size: 9px;
		  min-width: 25px;
		}
`;
Wrapper.displayName = 'Styled.TableHeader';

const TableHeader = (props) => (
    <Wrapper>
        <tr>
            {props.cols.map(col => (
                <th key={col.name} className={col.className}>{col.name}</th>
            ))}
        </tr>
    </Wrapper>
);

TableHeader.defaultProps = {
    cols: [],
};

TableHeader.propTypes = {
    cols: PropTypes.array,
};

export default TableHeader;

