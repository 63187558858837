import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GlobalSearchGroupItem from '../GlobalSearchGroupItem';
import {AnimatedCounter} from '../../../AnimatedCounter';
import styles from './index.css';


class GlobalSearchGroup extends Component {
    render() {
        const {title, items = [], moreUrl, total, onCategoryClick, onMouseOver, onMouseOut, keyword} = this.props;
        if (items.length === 0) return <div/>;
        return (
            <div className={styles['search-result']}>
                <div className={styles['search-result-group-items']}>
                    <ul>
                        <div className={`_selectable ${styles['search-result-group']}`} data-category={keyword}
                             onClick={onCategoryClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                            <span className={styles['search-result-category']}>{title}</span>
                            <span className={styles['search-count']}><AnimatedCounter value={total}/></span>
                        </div>

                        {items.map((item, index) => <GlobalSearchGroupItem
                            onMouseOver={onMouseOver} onMouseOut={onMouseOut}
                            key={index} title={item.title} subtitle={item.subtitle}
                            link={item.url}/>)}

                        {total > items.length &&
                        <li className={'_selectable'} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                            <div className={styles['search-result-more']}>
                                <a href={moreUrl}>...
                                    more {total - items.length}</a>
                            </div>
                        </li>}
                    </ul>
                </div>
            </div>
        );
    }
}

GlobalSearchGroup.propTypes = {
    onCategoryClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    items: PropTypes.array,
    moreUrl: PropTypes.string,
    total: PropTypes.number,
    keyword: PropTypes.string,
};
GlobalSearchGroup.defaultProps = {
    onCategoryClick: (value) => {
    },
    onMouseOver: (value) => {
    },
    onMouseOut: (value) => {
    },
    items: [],
    moreUrl: '',
    total: 0,
};


export default GlobalSearchGroup;
